
import { Injectable } from '@angular/core';
import {  HttpClient  } from '@angular/common/http'
import {  URL } from '../../../config/conf';
@Injectable({
  providedIn: 'root'
})
export class IdentificacionService {


  public url = URL;

  constructor(  private _http: HttpClient ) { }


  agregarConsulta( body ){

    // console.log(body);
    

    let url = `${this.url}/consultas/general/identificacion`;
    return this._http.post(url, body);
  }


  verConsultaIdentificacion(){

    let url = `${this.url}/ver/consultas/identificacion`;

    return this._http.get(  url );

  }



}
