<!-- <h1 class="centerxd">BITÁCORA DE RESULTADOS DE ULTRASONIDO</h1>

<div class="col-md-8">
    <input type="text" placeholder="Buscar" class="form-control" id="buscador" style="border-radius: 20px;">
</div>

<br>

<div class="card" style="border-radius: 30px;">

    <div class="row">
        <div class="col-md-12">


            <table class="table table-hover colors izq" id="bitacora">

                <thead class="center" style="background-color: #03318C;">

                    <tr style="color: white;">

                        <th scope="col"> No.</th>
                        <th scope="col"> Fecha </th>
                        <th scope="col"> Nombre </th>
                        <th scope="col"> Edad </th>
                        <th scope="col"> Sexo </th>
                        <th scope="col"> Sede </th>
                        <th scope="col ">Diagnóstico</th>
                        <th scope="col ">Estudio</th>
                    </tr>




                </thead>

                <tbody class="center" style="background-color: white;color: black;">

                    <tr *ngFor="let item of consultas; let i = index">



                        <td> {{ i +1 }} </td>
                        <th>{{item.fechaDePedidoDeLosExamenes | date}}</th>
                        <th [routerLink]="['/lista/entrega/ultrasonidos', item._id]">{{item.idPaciente.nombrePaciente}} {{item.idPaciente.apellidoPaterno}} {{item.idPaciente.apellidoMaterno}}</th>
                        <th>{{item.idPaciente.edad}}</th>
                        <th> {{item.idPaciente.genero | generos:true }}</th>
                        <th>{{item.sede}}</th>
                        <th></th>
                        <th>{{item.estudios[0].nombreEstudio}}</th>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<br> -->


<h1 class="centerxd">BITÁCORA DE RESULTADOS DE ULTRASONIDO</h1>

<!-- <div class="col-md-8">
    <input type="text" placeholder="Buscar" class="form-control" id="buscador" style="border-radius: 20px;">
</div> -->

<br>

<div class="center">
    <section style="padding-left: 3.5rem; padding-right: 3.5rem;">
        <div class="card" name="card" id="card" style="border-radius: 30px;">
            <div class="card-header header">
                <div class="row">
                    <div class="col text_header">
                        <p> No. </p>
                    </div>
                    <div class="col text_header">
                        <p>Fecha</p>
                    </div>
                    <div class="col text_header">
                        <p>Nombre</p>
                    </div>
                    <div class="col text_header">
                        <p>Edad</p>
                    </div>
                    <div class="col text_header">
                        <p>Sexo</p>
                    </div>
                    <div class="col text_header">
                        <p>Sede</p>
                    </div>
                    <div class="col text_header">
                        <p>Estudio</p>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div *ngFor="let item of consultas| paginate:{id:'listaAmbulancia',
                    itemsPerPage: 3 ,
                    currentPage:pagina,
                    totalItems:totalAmbulancia} ; let i = index">
                    <div class="row" [routerLink]="['/lista/entrega/ultrasonidos', item._id]">

                        <div class="col color">
                            <p> {{ i +1 }} </p>
                        </div>

                        <div class="col color">
                            <p> {{item.fechaDePedidoDeLosExamenes | date}} </p>
                        </div>

                        <div class="col color">
                            <p> {{item.idPaciente.nombrePaciente |uppercase}} {{item.idPaciente.apellidoPaterno|uppercase}} {{item.idPaciente.apellidoMaterno|uppercase}} </p>
                        </div>

                        <div class="col color">
                            <p> {{item.idPaciente.edad}} </p>
                        </div>


                        <div class="col color">
                            <p> {{item.idPaciente.genero | generos:true }} </p>
                        </div>



                        <div class="col color">
                            <p> {{item.sede}} </p>
                        </div>

                        <div class="col color">
                            <p> {{item.estudios[0].nombreEstudio}} </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="offset-md-1">
                <pagination-controls class="paginador" id="listaAmbulancia" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
            </div>
        </div>
    </section>
</div>