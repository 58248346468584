<section class="container-fluid">
    <div class="container">
        <div class="card-columns">
            <a [routerLink]="servicios.url" *ngFor="let servicios of servicio| paginate:{
            id:'listapacientes',
            itemsPerPage: 6,
            currentPage:pagina,
            totalItems:totalservicios}">
                <div class="" id="card">
                    <div class="card-body text-center">
                        <img class="img-fluid cursor" id="img2" [src]="servicios.img" alt="card image">
                        <h2 class="card-title" id="h2">{{servicios.nombre}}</h2>
                    </div>
                </div>
            </a>
        </div>
    </div>
</section>

<div class="offset-md-4">
    <pagination-controls id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
</div>