<div class="container-fluid">
    <app-titulos [title]="'LISTADO DE RECEPTORES'" [rol]="'pacientes'"></app-titulos>
    <div class="row">
        <div class="col-md-8">
            <div class="form-group">
                <input type="text" name="filterPost" placeholder="Buscar" [(ngModel)]="filtropacientes" class="form-control" id="busquedaPaciente" (keyup)="typeSearchPatients()" style="margin-left: 25px; max-width:50em">
            </div>
        </div>
        <div class="col-md-2">
            <!-- <a type="button" class="btn boton_contra3">INTERCAMBIO</a> -->
        </div>
        <div class="col-md-2">
            <a type="button" class="btn btn-secondary boton_contra2" [routerLink]="['/registro', 'pacientes']">NUEVO</a>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card" style="border-radius: 30px; margin-left: 25px;">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-md-4 text_header">
                            <p>Nombre</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>Género</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>Edad</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>Teléfono</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>Servicios</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngFor="let paciente of pacientes2 | paginate:{id:'listapacientes',
                                                              itemsPerPage: 5,
                                                              currentPage:pagina,
                                                              totalItems:totalpaciente}">
                        <div class="row pointer" [routerLink]="['/paciente', paciente._id]">
                            <div class="col-md-4" style="text-align: center;">
                                <p>
                                    {{ paciente.nombrePaciente | uppercase }} {{ paciente.apellidoPaterno | uppercase }} {{ paciente.apellidoMaterno | uppercase }} <br>
                                </p>
                            </div>
                            <div class="col-md-2" style="text-align: center;">
                                <p>
                                    {{paciente.genero | uppercase}}
                                    <br></p>
                            </div>
                            <div class="col-md-2" style="text-align: center;">
                                <p>
                                    {{ paciente.edad }}<br>
                                </p>
                            </div>
                            <div class="col-md-2" style="text-align: center;">
                                <p>
                                    {{ paciente.telefono }} <br>
                                </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <div class="row">
                                    <div class="col">
                                        <p>
                                            <a [routerLink]="['/paciente', paciente._id]">
                                                <i class="fa fa-eye eyeS puntero" id="ver"></i>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="pacientes2 == []">
                        <p>
                            Aún no hay pacientes registrados
                        </p>
                    </div>
                </div>
                <div class="offset-md-4">
                    <pagination-controls class="listapacientes" autoHide="true" nextLabel="Siguiente" previousLabel="Anterior" id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#c02137" type = "ball-scale-ripple-multiple" [fullScreen] = "true">
    <p style="color: white" > Cargando... </p>
</ngx-spinner>