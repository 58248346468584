<!-- MAQUETACIÓN DEL SERVICIO DE PAGOS ... SE REALIZO DE IGUAL MANERA UN COMPONENTE
PARA LA CONEXIÓN EN PAGOS  -->

<section class="row">
    <div class="col">
        <h1 class="centerxd" id="centerxd">Revisión de la orden de compra</h1>
    </div>
    <div class="col">
        <h1 class="fecha" id="fecha">{{fecha}}</h1>
    </div>
</section>

<section class="row">
    <div class="col">
        <div class="card" id="card">
            <div class="card-header" id="cardheader">
                <div class="row">
                    <div class="col">Datos del paciente</div>
                </div>
            </div>
            <div class="card-body">

                <div class="row">
                    <div class="col"> Nombre: </div>
                    <div class="col"> {{ paciente.nombrePaciente | uppercase }} {{ paciente.apellidoPaterno | uppercase }} {{ paciente.apellidoMaterno | uppercase }}</div>
                </div>
                <div class="row">
                    <div class="col"> Edad: </div>
                    <div class="col">{{ paciente.edad }}</div>
                </div>
                <div class="row">
                    <div class="col"> Sexo: </div>
                    <div class="col">{{ paciente.genero | uppercase }}</div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label for="solicito">Doctor que solicito:</label>
                            <input type="text" name="solicito" id="solicito" [(ngModel)]="infoVenta.doctorQueSolicito" class="form-control" placeholder="Doctor que solicito...">
                        </div>

                    </div>
                </div>
                <div class="col">
                    <label for="fechaNacimiento">  FECHA DE CITA </label>
                </div>
                <div class="col">
                    <input type="date" #edadForm [(ngModel)]="infoVenta.fecha" name="fechaNacimiento" id="fechaNacimiento" class="form-control">
                </div> 
                <div class="row" style="background-color: #001672; color:white">
                    <div class="col">Método de pago</div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <input type="radio" id="efectivo" name="efectivo" value="true" [(ngModel)]="infoVenta.efectivo" (click)="calcularNuevoTotalEfectivo()">
                        <label for="efectivo">Pago en efectivo</label>

                        <div *ngIf="infoVenta.efectivo">
                            <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" name="montoEfectivo" id="montoEfectivo" [(ngModel)]="infoVenta.montoEfectivo" (blur)="calcularNuevoTotalEfectivo()">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <input type="radio" id="anticipo" name="anticipo" value="true" [(ngModel)]="infoVenta.anticipo" (click)="calcularMontoAnticipo()">
                        <label for="anticipo">Anticipo</label>

                        <div *ngIf="infoVenta.anticipo">
                            <input type="number" min="0" [disabled]="true" class="form-control" placeholder="Ingresa el monto" name="montoAnticipo" id="montoAnticipo" [(ngModel)]="infoVenta.montoAnticipo">
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col">
                        <input type="radio" (change)="agregarIva()" id="tarjetaDeCredito" name="tarjetaDeCredito" value="true" [(ngModel)]="infoVenta.tarjetCredito">

                        <label for="tarjetaDeDebito"> Tarjeta de Crédito  </label>
                        <br>
                        <div *ngIf="infoVenta.tarjetCredito">

                            <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" name="montoTranferencia" id="montoTranferencia" [(ngModel)]="infoVenta.montoTarjteCredito" (blur)="calcularMontoTarjetaCredito()">
                        </div>
                    </div>
                </div> -->
                <!-- <div class="row">
                    <div class="col">
                        <input type="radio" (change)="agregarIva()" id="tarjetaDeDebito" name="tarjetaDeDebito" value="true" [(ngModel)]="infoVenta.tarjetaDebito">

                        <label for="tarjetaDeDebito"> Tarjeta de Débito  </label>
                        <br>
                        <div *ngIf="infoVenta.tarjetaDebito">

                            <input type="number" min="0" class="form-control" placeholder="Ingresa el monto" name="montoTarjetaDebito" id="montoTarjetaDebito" [(ngModel)]="infoVenta.montoTarjetaDebito" (blur)="calcuarMontoTarjetaDebito()">
                        </div>
                    </div>
                </div> -->
                <div class="row mt-2">
                    <div class="col">

                        <button class="btn btn-primary btn-block btn-tarjeta btn-orden mr-2" (click)="enviarPedido()" [disabled]="btnValidacion">
                          ORDENAR
                      </button>
                    </div>
                    <div class="col">
                        <!-- <button class="btn btn-block btn-info btn-tarjeta ml-2" (click)="imprimirCotizacion()">
                          IMPRIMIR
                      </button> -->

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col">

        <div class="card" id="card">
            <div class="card-header" id="cardheader">
                <div class="row">
                    <div class="col-3"> Producto</div>

                    <div class="col-3" *ngIf="!paciente.membresiaActiva"> Precio público </div>
                    <div class="col-3" *ngIf="paciente.membresiaActiva"> Precio membresía</div>
                    <div class="col-3"></div>
                </div>
            </div>

            <div class="card-body">
                <div *ngFor="let item of carrito.items; let i = index">
                    <div class="row">
                        <div class="col-3">
                            {{ item.nombreEstudio }}
                        </div>
                        <div class="col-3" *ngIf="!paciente.membresiaActiva">
                            {{ item.precioSin }}
                        </div>
                        <div class="col-3" *ngIf="paciente.membresiaActiva">
                            {{ item.precioCon }}
                        </div>
                        <div class="text-danger col-3" (click)="eliminar( i  )">
                            Eliminar
                        </div>
                    </div>
                    <hr>

                </div>
            </div>
            <div class="card-footer" id="cardfooter">
                <div class="row">
                    <div class="col">Total membresía: </div>
                    <div class="col white"> {{ totalCarritoMembresia | currency }}</div>
                </div>

                <div class="row" *ngIf="!paciente.membresiaActiva">
                    <div class="col totalApagar">Total publico: </div>
                    <div class="col center totalApagar"> {{ totalSinMembresia | currency }}</div>
                </div>
                <div class="row" *ngIf="!paciente.membresiaActiva">
                    <div class="col">Ahorro: </div>
                    <div class="col center"> {{ carrito.totalSin - carrito.totalCon | currency }}</div>
                </div>

            </div>
        </div>
    </div>
</section>
