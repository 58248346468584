import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URL } from '../../config/conf';

@Injectable({
  providedIn: 'root'
})
export class BancodesangreService {

  public url = URL;

  constructor(private _http: HttpClient) { }

  //servicio para generar el id de censur y se agrega el id del receptor
  agregarReceptor(body){
    return this._http.post( this.url +'/censur', body );
  }

  // Servicio para agregar los documentos al receptor y se agrega el id de los documentos a censur
  agregarDocumentosReceptor(body) {
    return this._http.post(this.url+'/receptor/documentos', body);
  }

  // Servicio para generar la orden de pedido al receptor y se agreg el id de la orden a censur 
  generarOrdenReceptor(body) {
    return this._http.post(this.url+'/ordenarpedido/censur', body);
  }

  //Servicio pra generar tipe y cruce
  generarTypeCruce(body) {
    return this._http.post(this.url+'/ordenar/tipeycruce', body);
  }

  //Servicio para obtener los productos del banco de sangre
  obtenerProductosBS(){
    return this._http.get(this.url+'/productos/censur');
  }

  envioDocumentosServidor(body){
    return this._http.post(this.url+'/cargar/documentos/censur', body);
  }

  //Servicio pra generar tipe y cruce
  productosAlmacen(body) {
    return this._http.post(this.url+'/productos/almacen/bancoco', body);
  }

  allproductosAlmacen() {
    return this._http.get(this.url+'/productos/materiales/servicios/censur')
  }

  getOrdenPedido(){
    const url = `${this.url}/orden/receptores`;
    return this._http.get(  url );
  }
  getOrdenPedidoReceptor(id:any){
    const url = `${this.url}/orden/receptor/${id}`;
    return this._http.get(  url );
  }
  bitacoraReaccionesTransfucionales(){
    return this._http.get(this.url+'/reacciones/transfucionales');
  }

  agregarDocumetosReaccionesTransfucionales(id:any, body:any){
    return this._http.put(this.url+'/agregar/documentos/banco/sangres/socios/'+id, body) ;
  }

  cambioEstadoPedido(id:any, body:any){
    return this._http.put(this.url+'/actulizar/estado/pedido/censur/'+id, body) ;
  }

  cambioEstadoRecpetor(id:any, body:any){
    return this._http.put(this.url+'/actulizar/estado/recpetor/censur/'+id, body) ;
  }
  /* envioDocumentosServidor(body){
    return this._http.post('http://initbox.com.mx/uploads/uploadcensur.php', body);
  }

  postFile(fileToUpload: File){
    const endpoint = 'https://s3.amazonaws.com/initbox.com.mx/uploads/uploadcensur.php';
    const formData: FormData = new FormData();
    formData.append('name', fileToUpload);
    return this._http.post(endpoint, formData)
  } */
  
}
