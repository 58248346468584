<!-- <section class="row">
    <div class="col-md-12">
        <ficha-info-user [pacienteInfo]="paciente"></ficha-info-user>

    </div>
</section>

<div style="overflow-x: scroll; height: 13rem;">



    <div class="row">
        <div class="col-md-12">

            <table class="table table-hover colors izq" id="bitacora">

                <thead class="center" style="background-color: #03318C;">

                    <tr style="color: white;">

                        <th scope="col"> #.</th>
                        <th scope="col"> Nombre de Estudio </th>
                        <th scope="col"> Estado </th>
                        <th scope="col"> Sede</th>

                    </tr>


                </thead>

                <tbody class="center" style="background-color: white;color: black;">

                    <tr *ngFor="let ultras of estudios.estudios; let i = index">


                        <th> {{i +1}} </th>
                        <th [routerLink]="['/resultado/servicio/rayosx/', ultras.idEstudio]"> {{ultras.nombreEstudio}} </th>
                        <th> {{estudios.prioridad}} </th>
                        <th> {{estudios.sede}} </th>
                    </tr>



                </tbody>

            </table>
        </div>







    </div>



</div> -->



<h1 class="centerxd">HOJA SERVICIOS</h1>

<!-- <div class="col-md-8">
    <input type="text" placeholder="Buscar" class="form-control" id="buscador" style="border-radius: 20px;">
</div> -->

<section class="row">
    <div class="col-md-12">
        <ficha-info-user [pacienteInfo]="paciente"></ficha-info-user>

    </div>
</section>

<br>

<div class="center">
    <section style="padding-left: 3.5rem; padding-right: 3.5rem;">
        <div class="card" name="card" id="card" style="border-radius: 30px;">
            <div class="card-header header">
                <div class="row">
                    <div class="col text_header">
                        <p> # </p>
                    </div>
                    <div class="col text_header">
                        <p>Nombre de Estudio </p>
                    </div>
                    <div class="col text_header">
                        <p>Estado</p>
                    </div>
                    <div class="col text_header">
                        <p>Sede</p>
                    </div>

                </div>
            </div>

            <div class="card-body">
                <div *ngFor="let ultras of estudios.estudios| paginate:{id:'listaAmbulancia',
                    itemsPerPage: 3 ,
                    currentPage:pagina,
                    totalItems:totalAmbulancia} ; let i = index">
                    <div class="row" [routerLink]="['/resultado/servicio/rayosx/', ultras.idEstudio]">

                        <div class="col color">
                            <p> {{ i +1 }} </p>
                        </div>

                        <div class="col color">
                            <p> {{ultras.nombreEstudio}} </p>
                        </div>

                        <div class="col color">
                            <p> {{estudios.prioridad}}</p>
                        </div>

                        <div class="col color">
                            <p>{{estudios.sede}} </p>
                        </div>



                    </div>
                </div>
            </div>
            <div class="offset-md-1">
                <pagination-controls class="paginador" id="listaAmbulancia" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
            </div>
        </div>
    </section>
</div>