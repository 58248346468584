import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { URL } from 'src/app/config/conf';



@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

  public url: string;
  // public ambulancia: [];
  public slash: string;

  private servicios:Scv[]=[
      {
        nombre:"AMBULANCIA",
        img:"../../../assets/assets/icons/serviciosIconos/car.svg",
        url:"ambulancia"
      },

      {
        nombre:"CONSULTA",
        img:"../../../assets/assets/icons/serviciosIconos/doctor.svg",
        url:"consulta"
      },
      {
        nombre:"ENDOSCOPIA",
        img:"../../../assets/assets/icons/serviciosIconos/endoscopy.svg",
        url:"endoscopia"
      },
      {
        nombre:"HOSPITALIZACIÓN",
        img:"../../../assets/assets/icons/serviciosIconos/hospital-bed.svg",
        url:"hospitalizacion"
      },
      {
        nombre:"PATOLOGÍA",
        img:"../../../assets/assets/icons/serviciosIconos/microscope.svg",
        url:"patologia"
      },
      {
        nombre:"RAYOS-X",
        img:"../../../assets/assets/icons/serviciosIconos/skeleton.svg",
        url:"xray"
      },
      {
        nombre:"LABORATORIO",
        img:"../../../assets/assets/icons/serviciosIconos/chemistry.svg",
        url:"laboratorio"
      },
      {
        nombre:"QUIROFANO",
        img:"../../../assets/assets/icons/serviciosIconos/surgery-room.svg",
        url:"quirofano"
      },
      {
        nombre:"RESONANCIA",
        img:"../../../assets/assets/icons/serviciosIconos/mri.svg",
        url:"resonancia"
      },
      {
        nombre:"ULTRASONIDO",
        img:"../../../assets/assets/icons/serviciosIconos/womb.svg",
        url:"ultrasonido"
      },
      {
        nombre:"TOMOGRAFIA",
        img:"../../../assets/assets/icons/serviciosIconos/x-ray.svg",
        url:"tomografia"
      },
      {
        nombre:"SERVICIOS EN GENRAL",
        img:"../../../assets/assets/icons/serviciosIconos/options.svg",
        url:"otros"
      }
  ];

    constructor(private _http:HttpClient){

      this.url = URL;
    }

    getServicios(){
      return this.servicios;
    }

    getServicio(idx: string){
      return this.servicios[idx];
    }

    // getObtener(slash){

    //   return this._http.get(this.url +'/ver/categoria/servicios/'+slash);
    // }


    obtenerServiciosSedeNombre( sede: String ,name  ) {
      // el que recide es el de la sede
      const url = `${this.url}/ver/utilidades/sede/${sede}/${name}`;
      return this._http.get(url);
    }

    obtenerAmbulancia( name: String, idSede: String ){
      const url = `${this.url}/ver/utilidad/ambulancia/${idSede}/${name}`;
      return this._http.get(url);
    }

    getDestino(slash:string): Observable<any>{
      return this._http.get(this.url +'/'+slash)
    }

    getAmbulanciaById(ambulanciaId): Observable<any> {
      return this._http.get(this.url+'/ambulancia/'+ambulanciaId);
    }

    updateAmbulancia(id, ambulancia): Observable<any> {
      let params = JSON.stringify(ambulancia);
      let headers = new HttpHeaders().set('Content-Type', 'application/json');
      return this._http.put(this.url+'/ambulancia/'+id, params, {headers: headers})
    }

    getServicioById(id): Observable<any> {
      return this._http.get(this.url+'/servicio/uno/'+id);
    }

    updateServicio(id, servicio): Observable<any> {
      let params = JSON.stringify(servicio);
      let headers = new HttpHeaders().set('Content-Type', 'application/json');
      return this._http.put(this.url+'/editar/servicio/'+id, params, {headers: headers})
    }

    create(ambulancia,slash:string): Observable<any> {
      let params = JSON.stringify(ambulancia);
      let headers = new HttpHeaders().set('Content-Type', 'application/json');
      return this._http.post(this.url+'/'+slash, params, {headers: headers});
    }

    delete(id): Observable<any> {
      let headers = new HttpHeaders().set('Content-Type', 'application/json');
      return this._http.delete(this.url+'/ambulancia/'+id, {headers: headers});
    }

    deleteService(id): Observable<any> {
      /* let headers = new HttpHeaders().set('Content-Type', 'application/json'); */
      return this._http.delete(this.url+'/servicio/'+id);
    }

    setANewPatologia(body){
      return this._http.post( this.url +'/nuevo/servicio/', body );
    }

    getAllDepartments(buscar) {
      //console.log(buscar)
      return this._http.post(this.url+'/buscar/servicio', buscar);
    }

    getPreciosAmbulanciaMembresia(){

      let url =   `${this.url}/obetener/ambulancia/membresia`;
      return this._http.get( url );
    }


    getPreciosMembresiaOtros( servicio ){
      let url = `${this.url}/obtener/servicios/membresia/${servicio}`
     return this._http.get(  url  );
    }

    getAllServicesWithMembreship( servicio  ){
      let url = `${this.url}/obtener/servicios/membresia`;
      return this._http.post(  url, servicio );
    }

    actualizarElemtos(  id, body  ){
      let url = `${this.url}/actualizar/elementos/${id}`;
      return this._http.put(  url, body );
    }

    buscarServicio(id){
      let url = `${this.url}/servicio/uno/${id}`;
      return this._http.get(  url );
    }

    getServiciossoloMembresia(  servicio){
      let url = `${this.url}/ver/precios/membresia/receta`;

      return this._http.post( url, servicio );

    }





}

export interface Scv{
  nombre: string;
  img: string;
  url: string;
}
