import { Component, OnInit,} from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';
import { BancodesangreService } from '../../services/bancodeSangre/bancodesangre.service'
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-docspaciente',
  templateUrl: './docspaciente.component.html',
  styleUrls: ['./docspaciente.component.css']
})
export class DocspacienteComponent implements OnInit {
  // @Input() pacienteInfo: FichaInfo;
  public pacienteInfo={
    nombrePaciente: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    curp: "",
    edad: 0,
    genero: "",
    callePaciente: "",
    fechaNacimientoPaciente:"",
    estadoPaciente: "",
    paisPaciente: "",
    telefono: "",
    receptor: "",
    _id:""
  };
  public id = "";
  public fecha = "";
  public bancodeSangre = {
    idpaciente:'',
    tipo_producto: ''
  }

  public dataDonante= {
    idcensur: '',
    documentos: [],
  }

  public idCensur;
  imagenes = [] 
  /* public idPaciente=""; */
  constructor(private _route: ActivatedRoute,
              private _router: Router,
              private spinner: NgxSpinnerService,
              private _pacienteService: PacientesService,
              private _bancoSangre:BancodesangreService,){}

  ngOnInit(){
    this.fecha = moment().format('L');
    this.id = this._route.snapshot.paramMap.get('id');
    this.obtenerPacientePorId();
    this.spinner.show()
  }

  setSeleccionado(valor) {
    this.bancodeSangre.tipo_producto = valor.valores
  }

  //Obtener paciente por id
  obtenerPacientePorId(){
    this._pacienteService.getPacienteBtID(  this.id )
    .subscribe(
      (resp:any) => {
        this.setpacienteInfo(resp.paciente);
      });
  }

  //set paciente info
  setpacienteInfo(paciente){
    this.pacienteInfo.nombrePaciente = paciente.nombrePaciente;
    this.pacienteInfo.apellidoPaterno = paciente.apellidoPaterno;
    this.pacienteInfo.apellidoMaterno = paciente.apellidoMaterno;
    this.pacienteInfo.curp = paciente.curp;
    this.pacienteInfo.edad = paciente.edad;
    this.pacienteInfo.genero = paciente.genero;
    this.pacienteInfo._id = paciente._id;
    this.pacienteInfo.callePaciente = paciente.callePaciente;
    this.pacienteInfo.paisPaciente = paciente.paisPaciente;
    this.pacienteInfo.estadoPaciente = paciente.estadoPaciente;
    this.pacienteInfo.fechaNacimientoPaciente = paciente.fechaNacimientoPaciente;
    this.pacienteInfo.telefono = paciente.telefono;
    this.pacienteInfo.receptor = paciente.receptor;
    //banco de sangre
    this.bancodeSangre.idpaciente = paciente._id;
    /* this.generaridCensur(); */
    this.spinner.hide();
  }

  //Generar id de censur y se agrega el id del receptor
  generaridCensur(){
    this._bancoSangre.agregarReceptor(this.bancodeSangre).subscribe((resp:any)=>{
        this.setIdCensur(resp['data']); 
    })
  }

  //Se guarda el id de censur en localStorage
  setIdCensur(resp){
    resp.reverse();
    localStorage.setItem('idCensur',resp[0]._id)
    this.enviarDoc(resp[0]._id);
  }

  /* agregarDocumentos(id){
    this.files.forEach(element => {  
      this.documentos.documentosreceptor.push(element.name)
    })    
    const formData = new FormData();
    for (var i = 0; i < this.files.length; i++) { 
      formData.append("file", this.files[i]);
    }
    
    this._bancoSangre.envioDocumentosServidor(formData).subscribe((resp:any)=>{
       this.enviarDoc(id);
    })

    
  } */

  enviarDoc(id){
    this.spinner.show();
    this.dataDonante.idcensur = id; 
    this.dataDonante.documentos =this.imagenes
    this._bancoSangre.agregarDocumentosReceptor(this.dataDonante).subscribe((resp:any)=>{
      this._router.navigateByUrl('/productos')
      swal("Se agregaron los documentos al receptor correctamente",{icon:"success"})
      this.spinner.hide();
    }) 
  }
  //Agregar documentos al receptor
  agregarDocumentosReceptor(){
    if(this.files.length >= 4){
      this.generaridCensur();
      
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Es necesario agregar los siguientes documentos:',
        html: '<li style="text-align: left;">Consentimiento informado de transfusion</li> <li style="text-align: left;">Resumen medico</li> <li style="text-align: left;">Ultimos laboratorios</li> <li style="text-align: left;">Solicitud de componentes sanguineos</li>',
      })
    }
  }

  // DROPZONE
  files: File[] = [];

  onSelect(event) {
    this.files.push(...event.addedFiles);
    this.imagenes = []
    if (this.files && this.files[0]) {
      for (let i = 0; i < this.files.length; i++) {
       this.fileToBase64(this.files[i])
        .then(result=>{
          const base64String = result
          this.imagenes.push( {name:this.files[i].name, base: base64String} );
        });         
      }
    }
  }

  fileToBase64 = (file:File):Promise<string> => {
    
    return new Promise<string> ((resolve,reject)=> {
          
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => resolve(reader.result.toString());
         reader.onerror = error => reject(error);
     })
  }
  
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.imagenes.splice(this.imagenes.indexOf(event),1)
  }
}
