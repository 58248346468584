import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jsPDF from 'jspdf';
import { ConsultaService } from 'src/app/services/consultas/consulta.service';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';


@Component({
  selector: 'app-regresos',
  templateUrl: './regresos.component.html',
  styleUrls: ['./regresos.component.css']
})
export class RegresosComponent implements OnInit {
  @Input() estudiosPendientes: string;

  public consultas: any = []

  public sexo = "";
  public id: string;
  public fecha: string;

  public totalAmbulancia: string;
  public pagina = 0;

  public search = "";

  /* public paciente  = {
    nombrePaciente: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    estadoPaciente: '',
    fechaNacimiento: '',
    telefono: '',
    edad: 0,
    genero: '',
    curp:'',
    callePaciente:'',
    cpPaciente:'',
    paisPaciente:'',
    idMedicinaPreventiva: '',
    idAntecedentesHeredoFam: '',
    idPaciente: "",
    sexo:''
  }; */

  constructor(private _routeFind: ActivatedRoute,
    public _consultaService: ConsultaService,
    public _consultaPaciente: PacientesService,
    private _HistoriaClinicaService: HistoriaClinicaService,

  ) { }

  ngOnInit(): void {

    // this.id = this._routeFind.snapshot.paramMap.get('id');
    this.obtenerCosnultaLab();


  }



  obtenerCosnultaLab() {
    this._consultaService.verListaLaboratorio()
      .subscribe((data) => {

        this.consultas = data['data'].reverse();
        this.totalAmbulancia = this.consultas.results;
      });

  }

  public indexTable = 0;
  receivedIndex(i: number) {
    this.indexTable = i;
  }

  printTable() {
    const doc: any = new jsPDF('l', 'cm', 'letter');
    let contador = 1;
    //doc.autoTable({ orientation: 'p', html: '#printTableThis' });
    //doc.fromHTML('#printTableThis');
    var columns = ["No.", "Fecha", "Nombre", "Edad", "Sexo", "Sede", "Estudio", "Prioridad"];
    var rows = [];

    this.consultas.forEach(element => {
      var x = [contador, element.fecha,
        element.idPaciente.nombrePaciente + element.idPaciente.apellidoPaterno + element.idPaciente.apellidoMaterno,
        element.idPaciente.edad, element.idPaciente.genero, element.sede, element.estudios[0].nombreEstudio,
        element.prioridad];

        rows.push(x);
        contador = contador + 1;
    });
    
    doc.autoTable(columns, rows, {startY: 1});
    doc.save(`Bitacora Consultas Externas.pdf`);

  }



  /* setData( consulta  ) {
    this.consultas = consulta;
    console.log( this.consultas[0].idPaciente  );
  } */

  // public obtenerConsultaPorId(){

  //   this._HistoriaClinicaService.obtenerConsultaPorElId( this.id )
  //   .subscribe(  (data:any) => {      
  //     this.paciente.nombre = data['data']['paciente']['nombrePaciente'];
  //     this.paciente.apellidoMaterno = data['data']['paciente']['apellidoMaterno'];
  //     this.paciente.apellidoPaterno = data['data']['paciente']['apellidoPaterno'];
  //     this.paciente.genero = data['data']['paciente']['genero'];
  //     this.paciente.edad = data['data']['paciente']['edad'];
  //     this.paciente.fechaNacimiento = data['data']['paciente']['fechaNacimientoPaciente'];
  //     this.paciente.paisPaciente = data['data']['paciente']['paisPaciente'];
  //     // this.paciente.estadoPaciente = data['data']['paciente']['estadoPaciente'];
  //     this.paciente.callePaciente = data['data']['paciente']['callePaciente'];
  //     this.paciente.curp = data['data']['paciente']['curp'];
  //     this.paciente.telefono = data['data']['paciente']['telefono'];
  //     this.paciente.cpPaciente = data['data']['paciente']['cpPaciente'];
  //     this.paciente.idPaciente = data['data']['paciente']['_id'];
  //     this.paciente.idMedicinaPreventiva = data['data']['paciente']['medicinaPreventiva'];
  //     this.paciente.idAntecedentesHeredoFam = data['data']['paciente']['antecedentesHeredoFamiliares'];


  //   });
  // }


}
