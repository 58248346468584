import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ServiciosService } from '../../../../services/serviciosInt/servicios.service';
import { ActivatedRoute} from '@angular/router';
import { getDataStorage  } from '../../../../functions/storage/storage.functions';
import  Carrito  from '../../../../clases/carrito/carrito.class';
import swal from 'sweetalert';
import { Subject } from 'rxjs';
import Tickets from 'src/app/clases/tickets/tickets.class';
import { eliminarStorage } from 'src/app/functions/storage/pacienteIntegrados';

@Component({
  selector: 'app-ambulancia',
  templateUrl: './ambulancia.component.html',
  styleUrls: ['./ambulancia.component.css']
})
export class AmbulanciaComponent implements OnInit {

  // data de los servicios
  @Input() ambulanciaSI: any [] = [];
  @Input() showAmbulanacia2=true;

  public totalAmbulancia: string;
  public termino: string;
  public pagina = 0;
  public showTableAmbulanacia = true;
  public showOtrosService= false;
  public servicios:string;
  public serviceSi: any [] = [];
  public membresia = false;
  public preciosMembresia=[];
  public roleUser = "";
  public searching = false;
  public sede: string;
  public btnCotizacion = false;

  public btnPago = true;

  private unsubscribe$ = new Subject<void>();

  public carrito = {
    totalSin: 0,
    totalCon: 0,
    items: []
  };

  public buscar = {
    estudio:''
  }

  constructor(private _service: ServiciosService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.servicios = this.activatedRoute.snapshot.paramMap.get('servicio');
    this.obtenerRoleUser();

    // this.verDatos();
    this.obtenerPacienteYMembresia();
    this.obtenerCarritoStorage();
    this.getSede();
    this.verDatos();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getSede (){
    this.sede = JSON.parse(localStorage.getItem('IdSede'));
  }

  obtenerCarritoStorage(){
    const storageCarrito = new Carrito();
    this.carrito = storageCarrito.obtenerSotorageCarrito();
  }

  obtenerRoleUser(){
    this.roleUser = getDataStorage().role;
  }


  obtenerPacienteYMembresia(){
    // se obtienen los precios con membresia
    let usuario = JSON.parse( localStorage.getItem('paciente'));
    if( usuario == null ){
      this.btnPago = false;
      this.btnCotizacion = true;
      this.verDatos();
    }else {
        this.membresia = usuario.membresiaActiva;
        this.verDatos();
    }

  }

  cotizacion(carrito){
    this.generarTicket(carrito);
  }
  generarTicket(carrito){
    const tickets = new Tickets();
    tickets.imprimirCotizacion(carrito);
    eliminarStorage();
    this.obtenerCarritoStorage();
  }



  busquedaGeneral(){
    if(this.buscar.estudio.length > 3){
      this._service.getAllDepartments(this.buscar)
      .subscribe((res:any) => {
        //console.log( res );
        if(res.data[0]!=0){
          this.ambulanciaSI = res.data[0];
        }else{
          this.showTableAmbulanacia = false;
          this.showOtrosService= true;
          this.serviceSi = res.data[1];
          this.searching = true;
        }
      });
    }else if(this.buscar.estudio == ''){
      this.searching = false;
      this.showOtrosService= false;
      this.showTableAmbulanacia = true;
      this.obtenerCarritoStorage();
      this.verDatos();
    }
  }


  agregarCarrito( event, item: any ) {
    let carrito = new Carrito();
    this.carrito = carrito.agregarItem(  event, item, this.membresia);
    this.validarCarrito();
  }


  eliminar(index){
     //console.log('Pedido', pedido);
    let carrito = new Carrito();
    carrito.eliminar( index );

    this.obtenerCarritoStorage();
    this.validarCarrito();

  }

  // le sumamos los precios al carrito con membresia

  verDatos(){
    // console.log( this.servicios );
    // this.getSede();
      this._service.obtenerAmbulancia(this.sede, this.servicios).subscribe(

        (res: any) => {
            this.setDatos( res['data']  );
        },
      err => {
          console.log(<any>err);
      });
  }

  setDatos( datos  ){
    this.ambulanciaSI = datos;
    this.totalAmbulancia = datos;
  }

  alertcomparasion( ev, precioPublico, precioMembresia, item2:any ){
    let precioSinTrim  =  precioPublico.replace('$', '');
    let precioSinComaPublico = precioSinTrim.replace(',', '');
    let precioMemTrim  =  precioMembresia.replace('$', '');
    let precioMemComaMembresia = precioMemTrim.replace(',', '');
    this.agregarCarrito(ev, item2)
    swal("Con membrecia ahorras:"+(precioSinComaPublico - precioMemComaMembresia),{icon:"success"});
  }

  validarCarrito(){
    this.obtenerCarritoStorage();
    if(this.carrito.items.length == 0){
      return true;
    }else{
      return false;
    }
  }
}
