import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { USGService } from 'src/app/services/usg/usg.service';


@Component({
  selector: 'app-entrega-ultrasonido',
  templateUrl: './entrega-ultrasonido.component.html',
  styleUrls: ['./entrega-ultrasonido.component.css']
})
export class EntregaUltrasonidoComponent implements OnInit {


    
  public pagina = 0;
  public totalAmbulancia: string;
  
  public paciente  = {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    estadoPaciente: '',
    fechaNacimiento: '',
    telefono: '',
    edad: 0,
    genero: '',
    curp:'',
    callePaciente:'',
    cpPaciente:'',
    paisPaciente:'',
    idMedicinaPreventiva: '',
    idAntecedentesHeredoFam: '',
    idPaciente:''
  };

  public id;

  public estudios={
    estudios: [{}],
    fechaDePedidoDeLosExamenes: "",
    idPaciente: "",
    prioridad: "",
    sede: "",
    _id :""
  }

  constructor( private activatedRoute: ActivatedRoute,  private _usgService: USGService) { }

  ngOnInit(): void {

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.obtenerUSG();
  }

  grabaridPaciente(){
    localStorage.setItem('idPaciente',JSON.stringify(this.estudios.idPaciente));
    localStorage.setItem('idPedidoUltra',JSON.stringify(this.estudios._id));
  }

  datosPaciente(paciente ){
    this.paciente=paciente
  }
  obtenerUSG(){
    this._usgService.verUltrsonidoUSG(this.id)
    .subscribe((data) => {
      
      this.estudios = data['data']
      this.datosPaciente(data['data']['idPaciente'])

       this.grabaridPaciente()
    })  

  }

}
