import { Component, OnInit, Input } from '@angular/core';
import { getDataStorage } from 'src/app/functions/storage/storage.functions';
import { ConsultaService } from 'src/app/services/consultas/consulta.service';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';
import { WsLoginService } from 'src/app/services/sockets/ws-login.service';

import { Router } from '@angular/router';
import * as moment from 'moment';
import  swal from 'SweetAlert';

@Component({
  selector: 'app-ficha-identificacion',
  templateUrl: './ficha-identificacion.component.html',
  styleUrls: ['./ficha-identificacion.component.css']
})
export class FichaIdentificacionComponent implements OnInit {

  @Input() idConsulta : string;
  @Input()  paciente  = {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    estadoPaciente: '',
    fechaNacimiento: '',
    telefono: '',
    edad: 0,
    genero: '',
    curp:'',
    callePaciente:'',
    cpPaciente:'',
    paisPaciente:'',
    idMedicinaPreventiva: '',
    idAntecedentesHeredoFam: '',
    idPaciente:''
  };

  public imc: number = 0;
  public btnSignosVitales = true;
  public talla: number =0;
  public peso: number=0;

  public fecha: string;


  

  // public fr: string;
  // public fc: string;




  public infPaciente = {
    esquemaVacunacion:""
  }
// estos signos vitales se le agregan a la tabla de la consulta y se le envia la data de enfermería

  public signosVitales = {
    talla:  0,
    peso:  0 ,
    imc: 0,
    fc: "",
    fr: "",
    temp: "",
    sistolica: "",
    diastolica: "",
    pc:    "",
    pa:"",
    pt:"",
    apgar: "",
    SaO: "",
    pao:"",
    glucosa:"",
    riesgoCaida: "",
    enfermeraAtendio:"",
    diagnosticoActual:"",
    observaciones: ""
}

  
  constructor(
    private _HistoriaClinicaService: HistoriaClinicaService,
    private _consultaService: ConsultaService,
    private _WsLoginService : WsLoginService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.fecha = moment().format('l');
    // obtenemos los datos de la consulta como lo es nombre, apellidos, etc
    this.signosVitales.enfermeraAtendio = getDataStorage().nombre;
  }


  setPeso(){
    this.signosVitales.peso = this.peso;
    this.signosVitales.talla = this.talla;
    this.signosVitales.imc = this.imc;
  }
  
  caida($event){
      this.signosVitales.riesgoCaida = $event;
}  
obtenerIMC(){

  this.imc = ((this.peso)/(this.talla * this.talla));
  let imcL = this.imc.toString();

  imcL.split(',', 2);

  let imcLn;
  imcLn = parseFloat(imcL).toFixed(2);

  this.imc = imcLn;
 
}

  validarSignos( validar: number  ){

    if( validar <= 0 ){
      return true;
    }
  }

  messageAlert(message){
    alert(message);
  }


  // esta funcion se ejecuta con el blur en el ultimo input 
  // no tocar son las validaciones


  validarStringsForm(validar: string){

    if(  validar == "" || validar == undefined ){
      return true;
    }
  }

  validacionFormSignos(){
    
    if( this.validarSignos( this.talla ) ){
      this.messageAlert('Completa la talla');
      return false;
    }else if(this.peso == 0){
      this.messageAlert('Completa el peso');
      return false;
    }else if( this.validarStringsForm( this.signosVitales.temp )  ){
      this.messageAlert('Completa la tempetura')
      return false;
    }/* else if(  this.validarStringsForm(this.signosVitales.fc) ){
      this.messageAlert('Completa la frecuencia cardiaca');
      return false;
    }else if(  this.validarStringsForm( this.signosVitales.fr  )  ){
      this.messageAlert('Completa la frecuencia respiratoria');
      return false;
    } */
    // } else if( this.validarStringsForm( this.signosVitales.pao ) ){
    //   this.messageAlert('Completa la PaO');
    //   return false;
    // }
    this.btnSignosVitales =  false;
    return true;
  }

  
  ageragrSignos(){

    this.setPeso();


        this._HistoriaClinicaService.agregarSignosVitales( this.idConsulta,  this.signosVitales )
        .subscribe(  (data:any) => {
          if(  data['ok']){
              // console.log( data );
              // alert('Signos vitales guardados');
              swal('Signos vitales guardados', '' , 'success');
          
              this.cambioDeEstadoCosnulta();
              this._WsLoginService.notificarDoctor(data);
          }
         });

  }

  cambioDeEstadoCosnulta(){

    let consultaestado = {
      status: 'Medico'
    }
  
    this._consultaService.cambiarEstadoConsulta( this.idConsulta, consultaestado   )
    .subscribe( (data) => {
      if(data['ok']){

        this._router.navigateByUrl('/')

      }
    } )
    
  } 
 

}
