<section class="container">
    <div class="row">
        <div class="col-9">
            <h1 class="centerxd">Agregar Consultas Generales</h1>
        </div>

        <div class="col-3">
            <div class="btnNuevo">
                <button style="margin-top: 10%;" type="button" class="btn btn-secondary boton_contra2" data-toggle="modal" data-target="#exampleModal">Nuevo</button>
            </div>
        </div>
    </div>
</section>

<br>

<section class="container">
    <div class="row center" style="justify-content: center;">
        <div class="col-md-4">
            <div class="card consultorioxd" style="max-width: 20rem;">
                <div class="card-body">
                    <h5 class="card-text">CONSULTORIO</h5>
                    <h2 class="card-title">1</h2>
                    <!-- <h5 class="card-text">Ficha: </h5> -->
                    <p class="card-text">MÉDICO: KARINA JIMÉNEZ VALDEPEÑA</p>
                    <p class="card-text">MEDICINA GENERAL</p>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="card consultorioxd" style="max-width: 20rem;">
                <div class="card-body">
                    <h5 class="card-text">CONSULTORIO</h5>
                    <h2 class="card-title">2</h2>
                    <!-- <h5 class="card-text">Ficha: </h5> -->
                    <p class="card-text">LAURA LIZETH MORALES SANTOS</p>
                    <p class="card-text">MÉDICO GENERAL</p>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="card consultorioxd">
                <div class="card-body">
                    <h5 class="card-text">CONSULTORIO</h5>
                    <h2 class="card-title">3</h2>
                    <!-- <h5 class="card-text">Ficha: </h5> -->
                    <p class="card-text">ESPECIALIDADES</p>
                </div>
            </div>
        </div>
        <div class="cardxd">
            <h2 class="center tabla-header">SALA DE ESPERA</h2>
        </div>
    </div>
</section>

<section class="container">
    <div class="row center" style="justify-content: center;">
        <div class="col-md-4">
            <div class="card consultorioxd" style="max-width: 20rem;">
                <div class="card-body">
                    <h5 class="card-text">En espera:</h5>
                    <div *ngFor="let listaEsperaP of listaEspera;">
                        <h5 class="card-text" *ngIf="listaEsperaP.consultorio === 'consultorioUno'">{{ listaEsperaP.paciente.nombrePaciente }} {{ listaEsperaP.paciente.apellidoMaterno }} {{ listaEsperaP.paciente.apellidoPaterno }} </h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card consultorioxd" style="max-width: 20rem;">
                <div class="card-body">
                    <h5 class="card-text">En espera:</h5>
                    <div *ngFor="let listaEsperaP of listaEspera;">
                        <h5 class="card-text" *ngIf="listaEsperaP.consultorio === 'consultorioDos'">{{ listaEsperaP.paciente.nombrePaciente }} {{ listaEsperaP.paciente.apellidoMaterno }} {{ listaEsperaP.paciente.apellidoPaterno }} </h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card consultorioxd" style="max-width: 20rem;">
                <div class="card-body">
                    <h5 class="card-text">En espera:</h5>
                    <div *ngFor="let listaEsperaP of listaEspera;">
                        <h5 class="card-text" *ngIf="listaEsperaP.consultorio === 'consultorioTres'">{{ listaEsperaP.paciente.nombrePaciente }} {{ listaEsperaP.paciente.apellidoMaterno }} {{ listaEsperaP.paciente.apellidoPaterno }} </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container">
    <div class="modal fade nombrePendejoParaEcontrarEsteDivDeMierdaxD" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <section class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <h5 class="modal-title centerxd" id="exampleModalLabel">Nueva Cita</h5>
                            </div>
                        </div>
                    </section>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-header">
                    <div class="row">
                        <div class="col-md-12">
                            <input #nombre (keyup)="buscarPaciente(nombre.value)" type="text" name="busquedaPaciente" class="form-control" id="busquedaPaciente" placeholder="Buscar paciente..." style="width: 28rem;">
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <section class="modal-header">
                        <div class="row">
                            <div class="col-md-12">
                                <table class="table-search">
                                    <tr *ngFor="let item of PACIENTES_SERVICES">
                                        <td (click)="seleccionarPaciente( item )"> {{ item.nombrePaciente }} {{ item.apellidoPaterno }} {{ item.apellidoMaterno }} <span>  {{  item.curp }} </span> </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </section>
                    <form>
                        <div class="form-group">
                            <label for="nombrePacienteIdentificado" class="col-form-label">Paciente:   </label> {{ paciente.nombrePaciente }} {{ paciente.apellidoPaterno }} {{ paciente.apellidoMaterno }}
                            <br>
                            <div class="containerFI">
                                <label for="curp"> CURP: {{ paciente.curp }}  </label>

                            </div>

                        </div>
                        <div class="form-group">
                            <label for="consultorio" class="col-form-label">Consultorio:</label>
                            <select class="selectStyle form-control" [(ngModel)]="paciente.consultorio" name="consultorio" id="consultorio">
                              <option value="seleccionarConsultorio"   selected  >-- Seleccione Consultorio --</option>
                              <option value="consultorioUno">Consultorio 1</option>
                              <option value="consultorioDos">Consultorio 2</option>
                              <option value="consultorioTres">Consultorio 3</option>
                              <option value="consultorioCuatro">Consultorio 4</option>
                              <option value="consultorioCinco ">Consultorio 5</option>
                          </select>
                        </div>
                        <div class="form-group">
                            <label for="servicio" class="col-form-label">Servicio:</label>
                            <select class="selectStyle form-control" [(ngModel)]="paciente.servicio" name="servicio" id="servicio">
                         <option selected value="seleccionar Servicio">-- Seleccione Servicio --</option>
                              <option value="Ambulacia">Ambulacia</option>
                              <option value="Endoscopia">Endoscopía</option>
                              <option value="Pediatría">  Pediatría </option>
                              <option value="ConsultaGeneral">Consulta General</option>
                          </select>
                        </div>
                        <div class="form-group">
                            <label for="doctorAPasar"> Doctor </label>
                            <select name="doctorAPasar" class="selectStyle form-control" [(ngModel)]="paciente.doctorAPasar" name="doctorAPasar" id="doctorAPasar">
                              <option value="" selected >  Selecciona el doctor </option>
                              <!-- <option value="Critian Vidal Valdez" > Cristian Vidal Valdez  </option>
                              <option value="Arquimides Rueda Cabrera"> Arquimides Rueda Cabrera</option>
                              <option value="Jesús Olguin"> Jesus Olguin Perez </option>
                              <option value="David Reyes">  David Reyes </option>
                              <option value="Susana del Villar"> Susana del Villar </option>
                              <option value="Felipe Maciel"> Felipe Maciel </option>
                              <option value="Erick Alarcón"> Erick Alarcón </option>
                              <option value="Yuri Segura"> Yuri Segura </option>
                              <option value="Arturo Enríquez"> Arturo Enríquez </option>
                              <option value="Víctor Guerrero"> Víctor Guerrero </option>
                              <option value="Karina Jiménez"> Karina Jiménez </option>
                              <option value="Laura Morales"> Laura Morales </option>
                              <option value="Ricardo"> Ricardo </option> -->
                              <option value="Samoel Vidal"> Samoel Vidal </option>
                          </select>
                        </div>
                        <div class="form-group">
                            <label for="motivoIngreso" class="col-form-label">Motivo de ingreso: </label>
                            <input type="text" class="form-control" id="motivoIngreso" [(ngModel)]="paciente.motivoIngreso" name="motivoIngreso">
                        </div>

                        <div class="row dupla center">
                            <div class="col-md">
                                <label for="">Fecha  de ingreso {{ fechaIngreso }}   </label>
                                <label for="hora">Hora: {{ horaIngreso}}  </label>
                            </div>
                            <!-- <div class="col-md">
                              <label for="">Fecha de egreso</label>

                          </div> -->
                        </div>


                        <div class="form-group">
                            <label for="message-text" class="col-form-label">Nota:</label>
                            <textarea class="form-control" id="message-text" name="notaRecepcion" [(ngModel)]="paciente.notaRecepcion"></textarea>
                        </div>

                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    <button type="button" class="btn btn-primary" (click)="enviar()" data-dismiss="modal">   Enviar </button>
                </div>
            </div>
        </div>
    </div>

</div>