<div class="container">
    <div class="row">
        <div class="col-md-8">
            <h1 class="text-principal centerxd">
                AGREGAR RECEPTOR
            </h1>
        </div>
    </div>
    <app-stepper></app-stepper>

</div>