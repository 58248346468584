import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { USGService } from 'src/app/services/usg/usg.service';


@Component({
  selector: 'app-resultados-ultrasonido',
  templateUrl: './resultados-ultrasonido.component.html',
  styleUrls: ['./resultados-ultrasonido.component.css']
})
export class ResultadosUltrasonidoComponent implements OnInit {

  
 
  public paciente = {
    apellidoMaterno: '',
    apellidoPaterno: '',
    callePaciente: '',
    consultas: '',
    contactoEmergencia1ApellidoMaterno: '',
    contactoEmergencia1ApellidoPaterno: '',
    contactoEmergencia1Curp: "",
    contactoEmergencia1Edad: "",
    contactoEmergencia1Genero: "",
    contactoEmergencia1Nombre: "",
    contactoEmergencia1Telefono: "",
    contactoEmergencia2ApellidoMaterno: "",
    contactoEmergencia2ApellidoPaterno: "",
    contactoEmergencia2Curp: "",
    contactoEmergencia2Edad: "",
    contactoEmergencia2Genero: "",
    contactoEmergencia2Nombre: "",
    contactoEmergencia2Telefono: "",
    correoPaciente: "",
    correoRazonSocial2: "",
    cpPaciente: '',
    cpRazonSocial: "",
    cpRazonSocial2: "",
    curp: "",
    edad: '',
    estadoPaciente: "",
    familia: [],
    fechaNacimientoPaciente: "",
    fechaRegistro: "",
    genero: "",
    membresiaActiva: '',
    nombrePaciente: "",
    nomenclaturaRegistro: "",
    paisNacimiento: "",
    paisPaciente: "",
    paquetes: [],
    paquetesPacientes: [],
    razonSocial1: "",
    razonSocial1Calle: "",
    razonSocial1Estado: "",
    razonSocial1Municipio: "",
    razonSocial2: "",
    razonSocial2Calle: "",
    razonSocial2Estado: "",
    razonSocial2Municipio: "",
    razoncocial1RFC: "",
    razoncocial2RFC: "",
    status: "",
    telefono: '',
    _id: ''
  }

  public id;
  public ultrasonidos = {
    imageUrl: [],
    usuario: "",
    idEstudio: {
      ELEMENTOS: [{
        machote: ""
      }],
      ESTUDIO: ""
    },
    diagnostico: "",
    machoteEdit: "",
    idPaciente: [],
    _id:"",
    idPedido:"",
    observaciones:""
  }

  public titulo;
  public machoteEditado;
  public diagnostico;
  public diag;
  public observa;
  public imagenes= [];
  public p:[{_id:""}];
  public userUSG
 public searchEstudio;

  constructor(private _usgService: USGService, private _route: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');
    this.paciente = JSON.parse(localStorage.getItem('idPaciente'))
    this.searchEstudio = JSON.parse(localStorage.getItem('idPedidoUltra'))
    this.obtenerUsg();
  }



  setPeidoUltra(pedido) {

    // this.ultrasonidos = pedido;  
     this.ultrasonidos = pedido.find(element => element.idPedido == this.searchEstudio)
    
    
      this.imagenes = this.ultrasonidos.imageUrl
    
  
    this.diag = this.ultrasonidos.diagnostico;
    this.titulo = this.ultrasonidos.idEstudio.ESTUDIO;
    this.machoteEditado = this.ultrasonidos.machoteEdit;
    this.observa = this.ultrasonidos.observaciones;
    

    this.userUSG = this.ultrasonidos.usuario;

  }
 
  
  
  obtenerUsg() {
    // this._usgService.obtenerUsgRecepcionHC(this.searchEstudio)
    this._usgService.obtenerUsgHC(this.paciente._id)
      .subscribe((data) => {
        this.setPeidoUltra(data['data'])
      })
;

  }

  USGpdf() {
    let showElement = document.querySelectorAll(".hide-display");
    showElement.forEach(elemento =>{
      elemento.classList.remove("hide-display");
    });
    window.print();
    window.location.reload();
  }

}
