import {  Routes, RouterModule } from '@angular/router';
import { BitacoraComponent } from './consultas/bitacora/bitacora.component';
import { ConsultasComponent } from './consultas/consultas/consultas.component';
import { HojaEvolucionComponent } from './consultas/hoja-evolucion/hoja-evolucion.component';
import { EnfermeriaComponent } from './enfermeria/enfermeria.component';
import { PagesComponent } from './pages/pages.component';
import { ServicioIndividualComponent } from './servicios/servicio-individual/servicio-individual.component';
import { ServiciosComponent } from './servicios/servicios/servicios.component';
import { DetailsComponent } from './ver-pacientes/detail/details/details.component';
import { VerPacientesComponent } from './ver-pacientes/ver-pacientes.component';
import { PagoComponent } from './servicios/pago/pago.component'
import { ReportesComponent } from './admin/reportes/reportes.component';
import { DetalleReportesComponent } from './admin/detalle-reportes/detalle-reportes.component';
import { BitacoraConsultasComponent } from './consultas/doctores/bitacora-consultas/bitacora-consultas.component';
import { FichaEnfermeriaComponent } from './consultas/ficha-enfermeria/ficha-enfermeria.component';
import { RecetaComponent } from './consultas/receta/receta/receta.component';
import { HevolucionCgComponent } from './consultas/hevolucion-cg/hevolucion-cg.component';
import { RegresosLabComponent } from './servicios/laboratorio/regresos-lab/regresos-lab.component';
import { DashboardResultadosComponent } from './servicios/recepcion-regresos/dashboard-resultados/dashboard-resultados.component';
import { ResultadosFinalesComponent } from './servicios/recepcion-regresos/resultados-finales/resultados-finales.component';
import { BitacoraRayosXComponent } from './servicios/recepcion-regresos/bitacora-rayos-x/bitacora-rayos-x.component';
import { EntregaRayosxComponent } from './servicios/recepcion-regresos/entrega-rayosx/entrega-rayosx.component';
import { ResultadosRayosxComponent } from './servicios/recepcion-regresos/resultados-rayosx/resultados-rayosx.component';
import { BitacoraUltrasonidoComponent } from './servicios/recepcion-regresos/bitacora-ultrasonido/bitacora-ultrasonido.component';
import { EntregaUltrasonidoComponent } from './servicios/recepcion-regresos/entrega-ultrasonido/entrega-ultrasonido.component';
import { ResultadosUltrasonidoComponent } from './servicios/recepcion-regresos/resultados-ultrasonido/resultados-ultrasonido.component';
import { DashResultadosComponent } from './servicios/recepcion-regresos/dash-resultados/dash-resultados.component';
import { RegresosComponent } from './servicios/laboratorio/regresos/regresos.component';
import { ServiciosRecepcionComponent } from './servicios/recepcion-regresos/servicios-recepcion/servicios-recepcion.component';
import { RegistroComponent } from './ver-pacientes/registro/registro.component';
import { PacientesComponent } from './pacientes/pacientes/pacientes.component';
import { VerPacienteComponent } from './pacientes/ver-paciente/ver-paciente.component';
import { ReceptoresComponent } from './receptores/receptores.component';
import { DocspacienteComponent } from './docspaciente/docspaciente.component';
import { ProductosComponent } from './censur-productos/productos/productos.component';
import { ReaccionesAdversasComponent } from './reacciones-adversas/reacciones-adversas.component';
import { BitacoraReaccionesAdversasComponent } from './bitacora-reacciones-adversas/bitacora-reacciones-adversas.component';
import { ReaccionesTransfucionalesComponent } from './admin/reacciones-transfucionales/reacciones-transfucionales.component';


const pagesRoutes : Routes = [
  { path:'',
  component:PagesComponent,
  children:[

    // rutas hija de pages

    // pacientes
    { path: 'paciente', component: PacientesComponent  },
    { path: 'paciente/:id', component: VerPacienteComponent },
    { path: 'registro/pacientes', component: RegistroComponent },

    // servicios

    { path: 'serviciosInt', component: ServiciosComponent },
    // en este componente se va a crear el dash de los servicios
    // arriba

    { path: 'pago/servicios', component: PagoComponent },
    // no tocar aun

    { path: 'serviciosInt/:servicio', component: ServicioIndividualComponent },
    // en este se van a visualizar los estudios por categoria

    // consultas
    { path: 'consulta', component: ConsultasComponent },
    { path: 'historia/clinica/:id', component: HevolucionCgComponent },
    { path: 'hoja/evolucion/:id', component: HojaEvolucionComponent },
    { path: 'receta/medica/:id', component: RecetaComponent },

    // hoja diaria de enfermería
    { path: 'hoja/diaria/enfermeria', component: EnfermeriaComponent },
    { path: 'ficha-enfermeria-01/:id', component: FichaEnfermeriaComponent },


    { path: 'ver/reportes', component: ReportesComponent  },
    { path: 'detalle/reporte', component: DetalleReportesComponent },

    // doctores
    { path: 'bitacora/medicina/general', component:BitacoraComponent },
    { path:'bitacora/consultas',component:BitacoraConsultasComponent},

    // config don´t touch
    { path: '', redirectTo:'/dashboard', pathMatch: 'full' },

    // ############################################### recepcion estudios resultados ################################################################
    //  %%%% laboratorio
    { path: 'entrega/resultados', component: RegresosLabComponent },
    { path: 'resultado-servicios/:id', component: DashboardResultadosComponent },
    { path: 'final/obtenidos/:id', component: ResultadosFinalesComponent },
    // %%%%% rayos x
    {path:'recepcion/bitacora/rayosx',component:BitacoraRayosXComponent},
    {path:'recepcion/lista/entrega/rayosx/:id',component: EntregaRayosxComponent},
    {path:'resultado/servicio/rayosx/:id',component:ResultadosRayosxComponent},
    //%%%%%%%%%%%%%%%%%% ultrasonido
    { path: 'bitacora/recepcion/ultrasonidos', component: BitacoraUltrasonidoComponent },
    { path: 'lista/entrega/ultrasonidos/:id', component: EntregaUltrasonidoComponent },
      { path: 'resultados/finales/ultrasonido/:id', component: ResultadosUltrasonidoComponent },


      {path:'dashboard/recepcion/resultados',component:DashResultadosComponent},
      { path: 'bitacora/rayosX', component: BitacoraRayosXComponent },
      { path: 'bitacora/laboratorios', component: RegresosComponent },




      // {path:'dashboard/recepcion/resultados',component:DashResultadosComponent},
      {path:'dashboard/recepcion/resultados/:servicio',component:ServiciosRecepcionComponent},
    // ########################################################################################################

    // BANCO DE SANGRE //
    { path: 'banco/sangre', component: ReceptoresComponent },
    { path: 'documentos/paciente/:id', component: DocspacienteComponent },
    { path: 'productos', component: ProductosComponent },
    { path: 'reacciones/adversas/:id', component: ReaccionesAdversasComponent },
    { path: 'reacciones/transfucionales', component: ReaccionesTransfucionalesComponent },
    // { path: 'documentos/paciente/:id', component: DocspacienteComponent },


   
    
  ]
 }
]



export const PAGES_ROTES = RouterModule.forChild( pagesRoutes );
