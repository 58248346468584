import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CardComponent } from './card/card.component';
import { BrowserModule } from '@angular/platform-browser';
import { ChatComponent } from './chat/chat/chat.component';
import { NavBarComponent } from './nav-bar/nav-bar/nav-bar.component';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AmbulanciaComponent } from './servicios/serv/ambulancia/ambulancia.component';

import { TablaServiceComponent } from './servicios/serv/tabla-service/tabla-service.component';
import { TodosServComponent } from './servicios/serv/todos-serv/todos-serv.component';
import { PagoServiciosComponent } from './servicios/pago-servicios/pago-servicios.component';
import { FichaIdentificacionComponent } from './ficha-identificacion/ficha-identificacion.component';
// import { HistoriaClinicaComponent } from './historiaClinica/historia-clinica/historia-clinica.component';
import { ChartsModule } from 'ng2-charts';
import { HistoriaClinicaComponent } from './historiaClinica/historia-clinica/historia-clinica.component';
import { GraficasNinosComponent } from './graficas-ninos/graficas-ninos.component';
import { GraficasPediatriaComponent } from './graficas-pediatria/graficas-pediatria.component';
import { HeaderRecetaComponent } from './header-receta/header-receta.component';
import { FichaInfoUserComponent } from './ficha-identificacion/ficha-info-user/ficha-info-user.component';
import { StepperComponent } from './registro/stepper/stepper.component';
import { MatStepperModule, MatStepper } from '@angular/material/stepper';
import { PedidosComponent } from './censur-pedido/pedidos/pedidos.component';
import { BitacorasComponent } from './censur-bitacoras/bitacoras/bitacoras.component';
import { TitulosComponent } from './ficha-identificacion/titulos/titulos.component';
import { NavBarCensurComponent } from './nav-bar-censur/nav-bar-censur.component';
import { NgxSpinnerModule } from "ngx-spinner";


// import { PagosServiciosComponent } from './pago-pacientes/pagos-servicios/pagos-servicios.component';

@NgModule({
  declarations: [
    DashboardComponent,
     CardComponent,
     ChatComponent,
     NavBarComponent,
     AmbulanciaComponent,
     TablaServiceComponent,
     TodosServComponent,
     PagoServiciosComponent,
     FichaIdentificacionComponent,
     HistoriaClinicaComponent,
     GraficasNinosComponent,
     GraficasPediatriaComponent,
     HeaderRecetaComponent,
     FichaInfoUserComponent,
     StepperComponent,
     PedidosComponent,
     BitacorasComponent,
     TitulosComponent,
     NavBarCensurComponent
    //  PagosServiciosComponent
    ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    ChartsModule,
    MatStepperModule,
    ReactiveFormsModule,
    NgxSpinnerModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
   ],
  exports:[
    CardComponent,
    DashboardComponent,
    ChatComponent,
    NavBarComponent,
    AmbulanciaComponent,
    TodosServComponent,
    PagoServiciosComponent,
    FichaIdentificacionComponent,
    HistoriaClinicaComponent,
    GraficasNinosComponent,
    GraficasPediatriaComponent,
    HeaderRecetaComponent,
    FichaInfoUserComponent,
    StepperComponent,
    PedidosComponent,
    BitacorasComponent,
    TitulosComponent,
    NavBarCensurComponent
    // MatStepperModule
    
    
  ]
})
export class ComponentsModule { }
