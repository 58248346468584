import { Component, OnInit } from '@angular/core';
import {  LoginService  } from '../services/login.service';

import {  Personal } from '../interfaces/personal'
import { Router  } from '@angular/router';

import {  NgForm  } from '@angular/forms';
import swal from 'sweetAlert';
import { idSede, SEDE } from '../config/conf';
import { WsLoginService } from '../services/sockets/ws-login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public cede = "";

  constructor(
    private wsloginService: WsLoginService,
    public _loginService: LoginService,
    private router: Router
  ) { }


  ngOnInit(): void {

    if( this.cede === null || this.cede == "" ){
      this.setCede();
    }
    this.verSede();

  }


  // validaciones de las sedes en localstorage
  setCede(){
    this.wsloginService.adios();
    localStorage.setItem('cede',  SEDE );
    localStorage.setItem('IdSede', JSON.stringify( idSede) );

  }

  verSede(){
    this.wsloginService.adios();
    this.cede =  localStorage.getItem('cede') ;
    // console.log(  this.cede);
  }


  ingresar( forma: NgForm ) {

    this._loginService.logout();
    this.wsloginService.adios();
    if( forma.invalid ){
      swal('Llena todos los campos','','error');
      return false;
    }

   let nombrePersonal = forma.value.nombre.trim();
   let passwordPersonal = forma.value.password.trim();

    let personal = new Personal( nombrePersonal, passwordPersonal );
    this._loginService.login( personal, this.cede )
    .subscribe( (correcto: any)=> {

      if( correcto ){

        this.router.navigate(['/dashboard'])
      }
    },
      error => {
          if( error['ok']  == false){
            forma.reset();
            swal('El nombre o la contraseña son incorrectos', 'Ingresalos de nuevo', 'error');
          }
      }
     )
  }
}
