import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-titulos',
  templateUrl: './titulos.component.html',
  styleUrls: ['./titulos.component.css']
})
export class TitulosComponent implements OnInit {
  @Input() rol='';
  @Input() title=''

  constructor() { }

  ngOnInit(): void {
  }

}
