import { Component, OnInit } from '@angular/core';
import {ServiciosService, Scv } from '../../../services/serviciosInt/servicios.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {
  public pagina : number = 0;
  public totalservicios:number;

  servicio:Scv[]=[];

  constructor( private _serviciosService: ServiciosService, private _router:Router) {}

  ngOnInit(): void {
    this.servicio= this._serviciosService.getServicios();
    this.totalservicios = this.servicio.length;
    this.pagina = 0;
  }



}
