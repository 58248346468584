<h1 class="centerxd" *ngIf="showAmbulanacia2">LISTADO DE TRASLADO</h1>

<div class="container-fluid">
    <div class="container-fluid">
        <div class="row" *ngIf="showAmbulanacia2">
            <div class="col-md-9">
                <div class="form-group">
                    <input type="text" style="margin-top: 15px;" name="filterPost" placeholder="Buscar" class="form-control" id="buscador" (keyup)="busquedaGeneral()" [(ngModel)]="buscar.estudio">
                </div>
            </div>

            <!-- <div class="col-2">
                <button type=" button " style="margin-left: 35px;" class="btn btn-primary separar" id="boton_contra1" [routerLink]="['/hoja-fram']" routerLinkActive="router-link-active">COTIZAR</button>
            </div> -->

            <!-- <div class="col-1">
                <button type="button" style="margin-right: 25px;" class="btn btn-secondary" id="boton_contra2" [routerLink]="['/formulario', servicios]" routerLinkActive="router-link-active">NUEVO</button>
            </div> -->

        </div>

        <div style="margin-top: 15px;" class="row">
            <div class="col-md-9" *ngIf="showTableAmbulanacia">
                <div class="card" style="border-radius: 30px;">
                    <div class="card-heder header">
                        <div class="row">
                            <div class="col-md-2 text_header">
                                <p>Destino</p>
                            </div>
                            <div class="col-md-2 text_header">
                                <p *ngIf="membresia != true">Traslado de día</p>
                                <p *ngIf="membresia">Traslado de día membresia</p>
                            </div>
                            <div class="col-md-2 text_header">
                                <p *ngIf="membresia != true">Traslado redondo de día</p>
                                <p *ngIf="membresia">Traslado redondo de día membresia</p>
                            </div>
                            <div class="col-md-2 text_header">
                                <p *ngIf="membresia != true">Traslado de noche</p>
                                <p *ngIf="membresia">Traslado de noche membresia</p>
                            </div>
                            <div class="col-md-2 text_header">
                                <p *ngIf="membresia != true">Traslado redondo de noche</p>
                                <p *ngIf="membresia">Traslado redondo de noche membresia</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="card-body" style="background-color: #ffffff;">
                    <div *ngFor="let ambulanciaSiService of ambulanciaSI">
                        <div class="row">
                            <div class="col-md-2">
                                <label style="margin-right: 50px; margin-top: 18px;">{{ ambulanciaSiService.idServicio.DESTINO }}</label>
                            </div>

                            <div class="col-md-2">
                                <div (click)="alertcomparasion( $event, ambulanciaSiService.idServicio.PRECIO_PUBLICO_DIA, ambulanciaSiService.idServicio.PRECIO_MEMBRESIA_DIA, ambulanciaSiService )" class="cursor  btn precioPublico" style="margin-top: 3px; margin-left:-14px; width: 9.5em; height: 4em; 
                                            background-color: #ffffff;
                                            border: none;" *ngIf="membresia != true">
                                    AGREGAR
                                    <!-- <p  (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia != true"> 
                                           AGREGAR
                                        </p> 
                                        <p  (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia"> 
                                           AGREGAR
                                        </p>  -->
                                </div>
                                <div (click)="alertcomparasion( $event, ambulanciaSiService.PRECIO_PUBLICO_DIA, ambulanciaSiService.PRECIO_MEMBRESIA_DIA, ambulanciaSiService )" class="cursor  btn btn-light precioPublico" style="margin-top: 3px; margin-left:-14px; width: 9.5em; height: 4em; 
                                            background-color: white;
                                            border: none;" *ngIf="membresia">
                                    AGREGAR
                                    <!-- <p   *ngIf="membresia != true"> 
                                            {{ ambulanciaSiService.PRECIO_PUBLICO_DIA }}
                                        </p> 
                                        <p   *ngIf="membresia"> 
                                            {{ ambulanciaSiService.PRECIO_MEMBRESIA_DIA }}
                                        </p>  -->
                                </div>
                            </div>

                            <div class="col-md-2">
                                <div (click)="alertcomparasion( $event, ambulanciaSiService.idServicio.PRECIO_PUBLICO_REDONDO_DIA, ambulanciaSiService.idServicio.PRECIO_MEMBRESIA_REDONDO_DIA, ambulanciaSiService)" class="cursor btn btn-light precioRedondoDia" style="margin-top: 3px; margin-left:-14px; width: 9.5em;
                                              height: 4em; background-color: #ffffff;
                                              border: none;">
                                    AGREGAR
                                    <!-- <h6 (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia != true">
                                        AGREGAR
                                    </h6>
                                    <h6 (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia">
                                        AGREGAR
                                    </h6> -->

                                </div>
                            </div>

                            <div class="col-md-2">
                                <div (click)="alertcomparasion( $event, ambulanciaSiService.idServicio.PRECIO_PUBLICO_NOCHE, ambulanciaSiService.idServicio.PRECIO_MEMBRESIA_NOCHE, ambulanciaSiService  )" class="cursor btn btn-light precioNoche" style="margin-top: 3px; margin-left:-14px; width: 9.5em;
                                              height: 4em; background-color:  #ffffff;
                                              border: none;">
                                    AGREGAR
                                    <!-- <h6 (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia != true">
                                        AGREGAR
                                    </h6>
                                    <h6 (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia">
                                        AGREGAR
                                    </h6> -->

                                </div>
                            </div>

                            <div class="col-md-2">
                                <div (click)="alertcomparasion( $event, ambulanciaSiService.idServicio.PRECIO_PUBLICO_REDONDO_NOCHE, ambulanciaSiService.idServicio.PRECIO_MEMBRESIA_REDONDO_NOCHE, ambulanciaSiService )" class="cursor btn btn-light precioRedondoNoche" style="margin-top: 3px; margin-left:-14px; width: 9.5em;
                                              height: 4em; background-color:  #ffffff;
                                              border: none;">
                                    AGREGAR
                                    <!-- <h6 (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia != true">
                                        AGREGAR
                                    </h6>
                                    <h6 (click)="agregarCarrito( $event, ambulanciaSiService )" *ngIf="membresia">
                                        AGREGAR
                                    </h6> -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
            </div>

            <div class="col-md-3" *ngIf="showTableAmbulanacia">
                <div class="card" style="margin: 10px; height: 33em; border-radius: 24px;">
                    <div class="card-title" style="border-bottom-style: solid;">
                        <h4 style="text-align: center;">COTIZACIÓN</h4>
                    </div>

                    <div class="card-body" style="overflow: auto;">
                        <div class="row" *ngFor="let pedido of carrito.items; let i=index">
                            <div class="col-md-8">
                                <p style="font-size: 11px; font-weight: 600; margin-top: 10px; text-align: center;" *ngIf="membresia != true">
                                    {{ pedido.nombreEstudio }} : {{ pedido.precioSin }}
                                </p>
                                <p style="font-size: 11px; font-weight: 600; margin-top: 10px; text-align: center;" *ngIf="membresia">
                                    {{ pedido.nombreEstudio }} : {{ pedido.precioCon }}
                                </p>
                            </div>

                            <div class="col-md-2">
                                <button type="button" (click)="eliminar(i)" class="btn btn-link" style="color: red;">
                                    <!-- <i class="fas fa-trash-alt"></i> -->
                                    Eliminar
                                </button>
                            </div>
                        </div>
                        <div *ngIf="validarCarrito()">
                            <p>No cuentas con servicios en el carrito</p>
                        </div>
                    </div>

                    <div class="card-footer" style="border-top-style: solid;">
                        <div class="row">
                            <div class="col-md-10">
                                <h5 style="font-size: 15px; font-weight: 800;" *ngIf="membresia != true">Total: &#36; {{carrito.totalSin}}</h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-10">
                                <h5 style="font-size: 15px; font-weight: 800;">Total membresía: &#36; {{ carrito.totalCon}}</h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-10">
                                <h5 style="font-size: 1.2rem; font-weight: 800;" class="ahorro">Ahorro: &#36; {{carrito.totalSin - carrito.totalCon}}</h5>
                            </div>
                        </div>

                        <button type="button" class="btn btn-success btn-block btn-tarjeta" *ngIf="btnCotizacion" (click)="cotizacion(carrito)">
                            <i class="fas fa-hand-holding-usd"></i>
                            Imprimir Cotización
                        </button>

                        <button type="button" class="btn btn-success btn-block btn-tarjeta" [routerLink]="['/pago/servicios']" *ngIf="btnPago">
                                <i class="fas fa-hand-holding-usd"></i>
                                Pagar
                        </button>

                        <button class="btn btn-dark btn-tarjeta btn-block" [routerLink]="['/paciente']" routerLinkActive="router-link-active">
                                <i class="fas fa-user-plus"></i>
                                Nuevo
                        </button>

                        <button class="btn btn-block btn-info  btn-tarjeta">
                                <i class="fas fa-share-alt"></i>
                                Enviar
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- <ambulancia-con [membresia]="membresia" *ngIf="membresia" [showTableAmbulanacia]="showTableAmbulanacia"></ambulancia-con> -->

        <!-- ESTA ES LA TABLA DE LOS DEMAS SERVICIOS  -->
        <!-- TERMINA LA TABLA DE LOS DEMAS SERVICIOS  -->
        <app-todos-serv *ngIf="showOtrosService" [showVista]="false" [serviceSi]="serviceSi"></app-todos-serv>

    </div>
</div>