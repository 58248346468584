<section class="row">
    <div class="col-md-9">
        <h1 class="title" *ngIf="title" id="centerxd">Ficha de identificación</h1>
    </div>
    <div class="col-md-3">
        <p class="fecha center" id="fecha"></p>

    </div>
</section>

<section class="container-fluid">

    <div class="card" name="card" id="card">
        <br>
        <div class="row">
            <div class="col margin-info">
                <p> <span>  Nombre Completo: {{ pacienteInfo.nombrePaciente | uppercase  }} {{ pacienteInfo.apellidoPaterno | uppercase  }} {{ pacienteInfo.apellidoMaterno | uppercase  }} </span> </p>
            </div>
            <div class="col">
                <p> <span> Edad: {{ pacienteInfo.edad }} </span> </p>
            </div>
            <div class="col">
                <p> <span> Género: {{pacienteInfo.genero | uppercase  }} </span> </p>
            </div>
        </div>
        <div class="row">
            <div class="col" style="margin-left: 40px;">
                <p> <span>Domicilio:</span> {{ pacienteInfo.callePaciente | uppercase }} {{pacienteInfo.estadoPaciente | uppercase }} {{ pacienteInfo.paisPaciente | uppercase }} </p>
            </div>
            <div class="col">
                <p> <span>Fecha de Nacimiento:</span> {{pacienteInfo.fechaNacimientoPaciente | date:'dd/MM/yyyy':'UTC'}} </p>
            </div>
            <div class="col">
                <p> <span>Lugar de Nacimiento:</span> {{ pacienteInfo.estadoPaciente | uppercase }} {{ pacienteInfo.paisPaciente | uppercase }} </p>
            </div>
        </div>
        <div class="row">
            <div class="col" style="margin-left: 40px;">
                <p> <span>Teléfono:</span> {{ pacienteInfo.telefono }} </p>
            </div>
            <div class="col">
                <p> <span>CURP:</span> {{ pacienteInfo.curp | uppercase }} </p>
            </div>
            <div class="col">
                <p> <span>ID:</span> {{ pacienteInfo._id }} </p>
            </div>
        </div>
        <div class="row">
            <div class="col" style="margin-left: 40px;">
                <p> <span>Sede:</span> {{ pacienteInfo.sede }} </p>
            </div>
        </div>
    </div>
</section>