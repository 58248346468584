import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-servicios-recepcion',
  templateUrl: './servicios-recepcion.component.html',
  styleUrls: ['./servicios-recepcion.component.css']
})
export class ServiciosRecepcionComponent implements OnInit {

  public servicios:string;
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.servicios = this.activatedRoute.snapshot.paramMap.get('servicio');
  }

}
