import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConsultaService } from 'src/app/services/consultas/consulta.service';
import { WsLoginService } from 'src/app/services/sockets/ws-login.service';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'app-enfermeria',
  templateUrl: './enfermeria.component.html',
  styleUrls: ['./enfermeria.component.css']
})
export class EnfermeriaComponent implements OnInit {

  public listaEspera = [];
  public getPacienteSotageX = this.listaEspera;
  public fechatl;

  constructor(
    public _consultasService: ConsultaService,
    private _router: Router,
    public _WsloginService: WsLoginService
  ) { }

  ngOnInit(): void {

    this.obtenerConsultas();

    this._WsloginService.escucharConsulta()
      .subscribe(arg => {
        if(arg != ""){
          this.obtenerConsultas();
        }
      });


  }

  cambioDeEstadoConsulta(  id: string ){
    let estado = {
      status : 'Enfermeria'
    }
    this._consultasService.cambiarEstadoConsulta( id, estado  )
    .subscribe( (data) => data);
  }


  obtenerConsultas(){
    this._consultasService.verConsultasPendientesEnfermeria()
    .subscribe( (data) => {
      this.listaEspera = data['data']
    });

  }

  imprimirBitacora(){

    const doc:any = new jsPDF();

    doc.autoTable({ html: "#bitacora" });

    doc.save('Bitácora_Hoja_Diaria_Enf_Gral_'+this.fechatl+'_.pdf');
  }


}
