import  {  HttpClient  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  Router } from '@angular/router';
import { map } from 'rxjs/operators';
import {  eliminarTodoPedido  } from '../functions/storage/storage.functions';

import { URL } from '../config/conf';
import { WsLoginService } from './sockets/ws-login.service';


@Injectable({
  providedIn: 'root'
})
export class LoginService {



  public  token: string;
  public usuario: any;
  public  cargarStrage: any;

  public url = URL;

  constructor(
    private wsLoginService: WsLoginService,
    public http: HttpClient,
    public router: Router
  ) { }


  login( personal: any, sede: String ) {

    if( this.cargarStorage  ){
      this.logout();
    }

    let url = this.url + `/login/${sede}` ;

      return this.http.post( url, personal )
      .pipe( map( (resp: any)=> {
          console.log(resp);
          localStorage.setItem('token', resp.token);
          localStorage.setItem('usuario', JSON.stringify(resp.personal) )
          this.token= localStorage.getItem('token');
          this.usuario = JSON.parse( localStorage.getItem('usuario') );
          return true;
      }) );

  }

  // borramos el token y el usuario del storage
  logout(){

    this.token = null;
    this.usuario = null;

    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    this.wsLoginService.adios();
    eliminarTodoPedido();
    this.router.navigate(['/login']);
  }

  cargarStorage(){

    if( localStorage.getItem('token') ){

      this.token = localStorage.getItem('token');
      this.usuario = JSON.parse( localStorage.getItem('usuario') )

    }else {
      this.token = '';
      this.usuario = null;
      this.wsLoginService.adios();
      this.router.navigate(['/login']);
    }
  }


  estaLogeado(){

      if(this.token.length<3  || this.token == null || this.token == undefined ){
          return true;
      }else {
        return false;
      }

  }

}
