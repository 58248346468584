import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';
import * as moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ConsultaService } from 'src/app/services/consultas/consulta.service';
import { getDataStorage } from 'src/app/functions/storage/storage.functions';
import { CEDE } from 'src/app/clases/cedes/cedes.class';
import { MedicamentosService } from 'src/app/services/farmacia/medicamentos.service';
import { IntegradosService } from 'src/app/services/servicios/integrados.service';



@Component({
  selector: 'app-receta',
  templateUrl: './receta.component.html',
  styleUrls: ['./receta.component.css']
})
export class RecetaComponent implements OnInit {

  public fecha: string;
  public hora: string;
  public id: string;

  public nombresMedicamentosDB:[];
  public salOSustanciaDB:[];


  public medico = {
      idMedico:'',
      nombre: '',
      cedulaProfesional:''
  }

  public estudios:[] =[];

  public buscarEstudiotxt = {
    estudio:""
  }


  public medicamentoServiceDoctor = {
    nombreComercial:"",
    nombreDeSalOsustanciaActiva:"",
    presentacio:"",
    contenidoFrasco:"",
    viaDeAdministracion:""
  }

  public recetaPDF= {
      height:3.7,
      width:1.5,
      whithEspacio: 3.4
  }

  public paciente = {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    idPaciente: '',
    edad: '',
    curp:'',
    registro: '',
    sexo: '',
    medico: '',
    calle: '',
    municipio:'',
    talla: '',
    peso: '',
    imc: '',
    fc: '',
    fr: '',
    temp: '',
    pc: '',
    pa: '',
    pt: '',
    apgar: '',
    sao: '',
    alergias: '',
    membresiaActica:false
  }

  public busuqedaMedicamento ="";

  public medicamentos = [];


  public indicaciones = {
    medicmanento: '',
    indcacionesMedicamento: ''    
  }

  public recetMedica = {
    
    medicamentos: [],
    estudios:[],
    otrasIndicaciones: "",
    idPaciente:"",
    idConsulta:"",
    fechaReceta:'',
    horareceta:'',
    medicoQueAtendio:'',
    idMedico:'',
    cedula:'',
    firma:'',
    origen:'TLYPN01',
    horaEgreso:'',
    prioridad: 'Programado'
  }


  constructor(
    private _route: ActivatedRoute,
    private _historiaClinicaService: HistoriaClinicaService,
    private _medicamentosService: MedicamentosService,
    public _consultaService: ConsultaService,
    public _router: Router,
    public _integradosService: IntegradosService
  ) { }

  ngOnInit(): void {
    this.fecha = moment().format('L');
    this.hora = moment().format('LT');
    // este id es de la tabla de consulta
    this.id = this._route.snapshot.paramMap.get('id');
    this.obetenerConsulta();
    // sacamos la informacion de la sesion 
    this.getMedicoData();
  }


  getMedicoData() {
    
    this.medico.nombre = getDataStorage().nombre
    this.medico.idMedico = getDataStorage()._id;
    this.medico.cedulaProfesional = getDataStorage().cedulaProfesional;
  }


  setRecetaDataMedico(){
    this.recetMedica.medicoQueAtendio = this.medico.nombre;
    this.recetMedica.idMedico = this.medico.idMedico;
    this.recetMedica.cedula = this.medico.cedulaProfesional;
    this.recetMedica.fechaReceta = this.fecha;
    this.recetMedica.horaEgreso = moment().format('LT');
    this.recetMedica.origen = CEDE;
  }

  obetenerConsulta(){
      this._historiaClinicaService.obtenerConsultaPorElId(  this.id)
      .subscribe(  (data:any) => {
        
        this.paciente.nombre = data['data'].paciente.nombrePaciente;
        this.paciente.apellidoPaterno = data['data'].paciente.apellidoPaterno;
        this.paciente.apellidoMaterno = data['data'].paciente.apellidoMaterno;
        this.paciente.registro = data['data']['paciente']['fechaRegistro'];
        this.paciente.calle = data['data'].paciente.callePaciente;
        this.paciente.edad = data['data']['paciente']['edad'];
        this.paciente.municipio = data['data']['paciente']['estadoPaciente'];
        this.paciente.sexo = data['data']['paciente']['genero'];
        this.paciente.curp = data['data']['paciente']['curp'];
        this.paciente.idPaciente = data['data']['paciente']['_id'];
        this.paciente.membresiaActica = data['data']['paciente']['membresiaActiva'];
        // this.paciente.registro = data['data'].paciente._id;

      } );
  }


  buscarMedicamento(){

    if(this.busuqedaMedicamento.length >= 4){
      
      this._medicamentosService.obtenerMedicamentoPorNombre(  this.busuqedaMedicamento )
      .subscribe( (data) => {

        
        this.nombresMedicamentosDB = data['data'][0];        
        this.salOSustanciaDB = data['data'][1];
         this.medicamentos =  this.nombresMedicamentosDB.concat(  this.salOSustanciaDB );

      
      });
    }
  }


  agregarMedicamentosDesdeDoctor( ) {

    return this._medicamentosService.agragarmedicamentos(   this.medicamentoServiceDoctor  )
    .subscribe(  (data) => { if (data['ok']) {alert('Medicamentos agregados') } });
  }

  setMedicamentos(event: HTMLElement) {
    this.indicaciones.medicmanento = event.textContent;
    this.medicamentos = [];
  }


  agregarMedicamentosEIndicaciones( ){


    if(  this.validarOtrasIndicaciones()  ){ 
      let indicaciones = {
        medicamento: this.indicaciones.medicmanento,
        indicaciones: this.indicaciones.indcacionesMedicamento,
      }
  
      
      this.recetMedica.medicamentos.push(  indicaciones  );
      this.resetearIndicaciones();
  
    }else{ 
      return;

    }
  }


  resetearIndicaciones() {
    this.indicaciones.medicmanento ="";
    this.indicaciones.indcacionesMedicamento="";
    this.medicamentos = [];
  }

  medicamentosRE(medicamentos, indicaciones) {
    this.indicaciones.medicmanento =  medicamentos,
    this.indicaciones.indcacionesMedicamento = indicaciones
  }

  setIDconsulta(){
    this.recetMedica.idConsulta = this.id;
  }

  setIdPaceinte() {
    this.recetMedica.idPaciente = this.paciente.idPaciente;
  }

  agregarReceta() {
    this.setIDconsulta();
    this.setIdPaceinte();
  }


  setIds() {
    this.recetMedica.idPaciente = this.paciente.idPaciente;
    this.recetMedica.idConsulta = this.id;
  }

  validarOtrasIndicaciones(){
    
    if( this.indicaciones.indcacionesMedicamento == '' ){
     alert('Completa las indicaciones del medicamnento');
     return false;
    }
    return true;
  }

  guardarReceta(){

    this.setIds();
    this.setRecetaDataMedico();

    this.imprimirReverso();

      this._consultaService.agregarReceta(  this.recetMedica )
      .subscribe( data => {
        
        
        if(data['ok']){
          
          let estado = {
            status : 'Receta'
          }

          this._consultaService.cambiarEstadoConsulta( this.id,  estado  )
          .subscribe( data  => {
            if(data['ok']){ 
              // console.log(data);
              alert('Receta guardada');
              this._router.navigateByUrl('/bitacora/medicina/general');
            }
          });
        }
      });
  }

  // bisca los estudios en al base de datos
  buscarEstudio(){
    if( this.buscarEstudiotxt.estudio.length > 5 ){
      // console.log( this.paciente.membresiaActica );
      
      if(  this.paciente.membresiaActica ){

        this._integradosService.getServiciossoloMembresia(this.buscarEstudiotxt )
        .subscribe( (data) => {
          this.estudios = data['data'];
        });
        
      }else {
        this._integradosService.getAllDepartments( this.buscarEstudiotxt )
         .subscribe( data => {
          this.estudios = data['data'][1]
         
        });

      }
    }
  }

  // agrega los estudio al json y a la interfaz
  agregarEstudio(estudio){
    
    this.recetMedica.estudios.push(  estudio );
    this.buscarEstudiotxt.estudio = "";
    this.estudios = [];
  
  }

  imprimirFrente(){

    let sexo = "";

    if( this.paciente.sexo == 'femenino' ){
      sexo = 'F';
    }else if( this.paciente.sexo == 'masculino' ){
        sexo = 'M';
    }

    const doc:any = new jsPDF('p', 'cm', [21.5, 14]);

    doc.setFontSize(12);
    doc.text( 8, 1.6, `${this.fecha}` );
    doc.text(4.1, 2.3,`${this.paciente.nombre} ${this.paciente.apellidoPaterno} ${this.paciente.apellidoMaterno}`);
    doc.text(12,2.3, `${sexo}`);
    doc.text(13.5,2.3,  `${this.paciente.edad}`);

    this.recetMedica.medicamentos.forEach( (medicamento:any) => {
      
      doc.text( this.recetaPDF.width, this.recetaPDF.height , `${medicamento.medicamento}`);
      this.recetaPDF.height += 1;
    
      doc.text( this.recetaPDF.width , this.recetaPDF.height,  `${medicamento.indicaciones}`);
      this.recetaPDF.height += 1;


    });

    doc.save('hoja por el frente.pdf');
  }

  imprimirReverso(){

    const doc:any = new jsPDF('p', 'cm', [21.5, 14]);
    doc.setFontSize(11);

    doc.text( 2, 2 , `${this.recetMedica.otrasIndicaciones}` );

    var x = 6;
    var y = 2;

    this.recetMedica.estudios.forEach( (estudio, i) => {

      y += 1;

      // se manda todo el objeto para poder sacar el precio en el carro de la compra


      doc.text( x, y , `${estudio.ESTUDIO}` );
    });


    doc.save('hoja por atras.pdf');
  }

}
