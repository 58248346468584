import { CommonModule } from '@angular/common';
import { PagesComponent } from './pages/pages.component';
import { ComponentsModule } from '../components/components.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgModule } from '@angular/core';
import { ServiciosComponent } from './servicios/servicios/servicios.component';
import { PAGES_ROTES } from './page.routes';
import { VerPacientesComponent } from './ver-pacientes/ver-pacientes.component';
import { RegistroComponent } from './ver-pacientes/registro/registro.component';
import { BitacoraComponent } from './consultas/bitacora/bitacora.component';
import { HojaEvolucionComponent } from './consultas/hoja-evolucion/hoja-evolucion.component';
import { ConsultasComponent } from './consultas/consultas/consultas.component';
import { EnfermeriaComponent } from './enfermeria/enfermeria.component';
import { BitacoraConsultasComponent } from './consultas/doctores/bitacora-consultas/bitacora-consultas.component';
import { DetailsComponent } from './ver-pacientes/detail/details/details.component';
// import { PagoComponent } from './servicios/pago-servicios/pago-servicios.component';
import { ServicioIndividualComponent } from './servicios/servicio-individual/servicio-individual.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PagoComponent } from './servicios/pago/pago.component';
import { ReportesComponent } from './admin/reportes/reportes.component';
import { DetalleReportesComponent } from './admin/detalle-reportes/detalle-reportes.component';
import { FichaEnfermeriaComponent } from './consultas/ficha-enfermeria/ficha-enfermeria.component';
import { ChartsModule } from 'ng2-charts';
import { RecetaComponent } from './consultas/receta/receta/receta.component';
import { HevolucionCgComponent } from './consultas/hevolucion-cg/hevolucion-cg.component';
import { DashResultadosComponent } from './servicios/recepcion-regresos/dash-resultados/dash-resultados.component';


import { DashboardResultadosComponent } from './servicios/recepcion-regresos/dashboard-resultados/dashboard-resultados.component';
import { EntregaRayosxComponent } from './servicios/recepcion-regresos/entrega-rayosx/entrega-rayosx.component';
import { EntregaUltrasonidoComponent } from './servicios/recepcion-regresos/entrega-ultrasonido/entrega-ultrasonido.component';
import { ResultadosRayosxComponent } from './servicios/recepcion-regresos/resultados-rayosx/resultados-rayosx.component';
import { ResultadosUltrasonidoComponent } from './servicios/recepcion-regresos/resultados-ultrasonido/resultados-ultrasonido.component';
import { ServiciosRecepcionComponent } from './servicios/recepcion-regresos/servicios-recepcion/servicios-recepcion.component';
import { ResultadosFinalesComponent } from './servicios/recepcion-regresos/resultados-finales/resultados-finales.component';
import { RegresosComponent } from './servicios/laboratorio/regresos/regresos.component';
import { RegresosLabComponent } from './servicios/laboratorio/regresos-lab/regresos-lab.component';
// import { HistoriaClinicaComponent } from '../components/historiaClinica/historia-clinica/historia-clinica.component';

// %%%%%%%%%%%%%%%%%%%%%%%%%%%% RECEPCION %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
import { BitacoraRayosXComponent } from './servicios/recepcion-regresos/bitacora-rayos-x/bitacora-rayos-x.component';
import { BitacoraUltrasonidoComponent } from './servicios/recepcion-regresos/bitacora-ultrasonido/bitacora-ultrasonido.component';

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%555%%%%%%%%%%%%%%%%%%%%%%%%%%%%
import { GenerosPipe } from '../pipes/generos.pipe';
import { PacientesComponent } from './pacientes/pacientes/pacientes.component';
import { PaquetePacienteComponent } from './pacientes/paquete-paciente/paquete-paciente.component';
import { VerPacienteComponent } from './pacientes/ver-paciente/ver-paciente.component';
import { ReceptoresComponent } from './receptores/receptores.component';
import { DocspacienteComponent } from './docspaciente/docspaciente.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ProductosComponent } from './censur-productos/productos/productos.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ReaccionesAdversasComponent } from './reacciones-adversas/reacciones-adversas.component';
import { BitacoraReaccionesAdversasComponent } from './bitacora-reacciones-adversas/bitacora-reacciones-adversas.component';
import { ReaccionesTransfucionalesComponent } from './admin/reacciones-transfucionales/reacciones-transfucionales.component';





@NgModule({
  declarations: [
    GenerosPipe,
    PagesComponent,
    ReaccionesAdversasComponent,
    BitacoraReaccionesAdversasComponent,
    ReaccionesTransfucionalesComponent,
    ServiciosComponent,
    VerPacientesComponent,
    RegistroComponent,
    BitacoraComponent,
    HojaEvolucionComponent,
    // HistoriaClinicaComponent,
    ConsultasComponent,
    EnfermeriaComponent,
    BitacoraConsultasComponent,
    DetailsComponent,
    PagesComponent,
    ServicioIndividualComponent,
    PagoComponent,
    ReportesComponent,
    DetalleReportesComponent,
    FichaEnfermeriaComponent,
    RecetaComponent,
    HevolucionCgComponent,
    DashResultadosComponent,
    BitacoraRayosXComponent,
    BitacoraUltrasonidoComponent,
    DashboardResultadosComponent,
    EntregaRayosxComponent,
    EntregaUltrasonidoComponent,
    ResultadosRayosxComponent,
    ResultadosUltrasonidoComponent,
    ServiciosRecepcionComponent,
    ResultadosFinalesComponent,
    RegresosComponent,
    RegresosLabComponent,
    PacientesComponent,
    PaquetePacienteComponent,
    VerPacienteComponent,
    ReceptoresComponent,
    DocspacienteComponent,
    ProductosComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    PAGES_ROTES,
    NgxPaginationModule,
    RouterModule,
    ChartsModule,
    NgxDropzoneModule,
    NgxSpinnerModule
    ]
})

export class PagesModule { }
