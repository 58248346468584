<div class="container">
    <div class="title">
        <h1>ADJUNTAR DOCUMENTOS DEL RECEPTOR</h1>
    </div>

    <!-- Componente de ficha de identificaion del paciente -->
    <section>
        <ficha-info-user [pacienteInfo]='pacienteInfo' (usuarioSeleccionado)=setSeleccionado($event)></ficha-info-user>
    </section>

    <!-- <div class="row">
        <div class="col-md-12">
            <h3 style="text-transform: uppercase;">
                Documentos Adjuntos
            </h3>
        </div>
        <div class="row">
            <div class="col">
                <h5 style="color: blue;">concentimiento_pura_prueba.pdf</h5>
            </div>
            <div class="col">
                <h5 style="color: blue;">concentimiento_pura_prueba_prueba_dos.pdf</h5>
            </div>
        </div>
    </div>
    <br> -->
    <div class="col">
        <p>
            <a style="text-decoration: none;" href="/assets/assets/contracts/censur/SOLICITUD DE COMPONENTES SANGUINEOS.pdf" target="_black" [download]>Solicitud de componentes sanguineos</a>
        </p>
    </div>
    <!-- Carga de los archivos -->
    <div class="card">
        <ngx-dropzone (change)="onSelect($event)">
            <ngx-dropzone-label>
                <!-- <span class="material-icons">
                    add
                </span> -->
                AGREGAR ARCHIVOS
            </ngx-dropzone-label>
            <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">cd
                <ngx-dropzone-label style="font-size: .5rem;">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
            </ngx-dropzone-preview>
        </ngx-dropzone>
    </div>

    <br>
</div>
<div class="btn btn-primary center" style="margin-left: 37rem; text-transform: uppercase;" (click)="agregarDocumentosReceptor()">
    Guardar
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#c02137" type = "ball-scale-ripple-multiple" [fullScreen] = "true">
    <p style="color: white" > Cargando... </p>
</ngx-spinner>