<form autocomplete="off" #form="ngForm">

    <div id="tablaData">

        <section class="row">
            <div class="col-md-12">
                <ficha-info-user [pacienteInfo]="paciente"></ficha-info-user>

            </div>
        </section>
        <div class="card" *ngFor="let titles of resultado">
            <h2 class="text-center">REPORTE DE ANALISIS CLINICOS</h2>
            <label class="text-center"> LABORATORIO: {{titles.idEstudio.ESTUDIO}} </label>
        </div>

        <div class="card">

            <div class="form-group row">
                <label class="col-4 col-form-label">ELEMENTOS</label>
                <label class="col-4 col-form-label">OBTENIDO</label>
                <label class="col-4 col-form-label">VALORES DE REFERENCIA</label>
            </div>
            <div *ngFor="let item of resultado">
                <!-- poner un div -->
                <!-- <div *ngFor="let tipo of tipos">
                    {{tipo}}
                </div> -->
                <div class="form-group row" *ngFor="let it of item.idEstudio.ELEMENTOS; let i = index">

                    <div class="col-4 col-form-label">
                        <!-- <pre>  {{item.idEstudio.ELEMENTOS[i].elementos | json}} </pre> -->
                        <label class="col col-form-label">{{tipos[i]}} </label>
                        <label class="col col-form-label">{{it.elementos}} </label>

                    </div>
                    <div class="col-4 col-form-label">
                        <label class="col col-form-label"></label>
                        <label class="col col-form-label">{{obtenido[i+1]}}</label>
                        <!-- <pre> {{item.obtenidos | json}}</pre> -->
                    </div>
                    <div class="col-md col-form-label" *ngFor="let item of it.referencia">
                        <label class="col col-form-label"></label>
                        <label class="col-md">{{item.referencia}} </label>
                    </div>
                </div>

            </div>

        </div>

        <div class="card">
            <label class="text-center"></label>
            <label class="text-center">QFB SILVIA SUSANA ALVAREZ SALAZAR</label>

            <label class="text-center">  ATENTAMENTE  </label>
        </div>
    </div>

    <button type="button" class="btn btn-outline-primary btn-block" (click)="pdf()">Descargar PDF</button>
</form>
<br>