import { Component, OnInit } from '@angular/core';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { ActivatedRoute, Router } from '@angular/router';
import { getDataStorage } from 'src/app/functions/storage/storage.functions';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';
import { ConsultaService } from 'src/app/services/consultas/consulta.service';
import { Cie10Service } from 'src/app/services/consultas/consulta/cie10.service';
import swal from 'sweetalert';
import * as moment from 'moment'

@Component({
  selector: 'app-hevolucion-cg',
  templateUrl: './hevolucion-cg.component.html',
  styleUrls: ['./hevolucion-cg.component.css']
})
export class HevolucionCgComponent implements OnInit {

  


  public fecha: string;
  public hora: string;
  public id: string;
  public buscarDiagnostico: string;
  public diagnosticos: [] = [];

  // se le puso hoja de evilucion al objeto pero se llama historia clinca por aparatos y sistemas
  public antecedentesPersonalesNoPatologicos = {
    tabaquismoPorDia: "",
    aniosConsumo: "",
    exFumadorPasivo: "",
    alcoholismoPorDia: "",
    aniosDeconsumoAlcohol: "",
    exAlcoholicoUOcasional: "",
    alergias: "",
    tipoAlergias: "",
    tipoSanguineo: "",
    desconoceTipoSanguineo: "",
    drogadiccionTipo: "",
    aniosConsumoDrogas: "",
    exDrogadicto: "",
    alimentacionAdecuada: "",
    viviendaConServiciosBasicos: "",
    otrosAntecedentesNoPatologicos: "",
    idPaciente: ""
    }
    
  public pageTitle: 'ddd';

  public hojaEvolucion = {
    motivoDeConsulta:'',
    evolucionDelPadecimiento:'',
    medicoTrante:'',
    diagnostico:[],
    plan:'',
    respiratorioCardiovascular:"",
    digestivo:"",
    endocrino:"",
    musculoEsqueletico:"",
    genitourinario:"",
    hematopoyeticoLinfatico:"",
    pielAnexos:"",
    neurologicoPsiquiatricos:"",
    piel:'',
    cabezaCuello:"",
    torax:"",
    abdomen:"",
    genitales:"",
    extremidades:"",
    sistemaNervioso:""
  }
  public edad:number=0;

  public necistaReceta = "";

  // este json nos ayuda en la representacion de los datos
  public paciente = {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    edad: 0,
    registro: '',
    genero: '',
    consultas:0,
    medico: '',
    calle:"",
    curp:"",
    idPaciente:"",
    municipio:"",
    talla: '',
    peso: '',
    imc: '',
    fc: '',
    ta:"",
    fr: '',
    lpm:'',
    sistolica: '',
    diastolica: '',
    temp: '',
    glucosa: '',
    pc: '',
    pa: '',
    pt: '',
    apgar: 0  ,
    sao: '',
    rpm:'',
    alergias: '',
    pao: '',

  }







  public tallatl;
  public tallaPrueba = [];


    // Grafica Mamalona de peso
    // public lineChartData: ChartDataSets[] = [
    //   { data: [1.2, 1.3, 1.32, 1.32, 1.34, 1.34, 1.35, 1.40, 1.45, 1.50, 1.50, 1.50, 1.50, 1.52], label: 'Peso Mínimo' }

    // ];

    // public lineChartLabels: Label[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14'];

    // public lineChartOptions: (ChartOptions & { annotation: any }) = {
    //   responsive: true,
    //   scales: {
    //     // We use this empty structure as a placeholder for dynamic theming.
    //     xAxes: [{}],
    //     yAxes: [
    //       {
    //         id: 'y-axis-0',
    //         position: 'left',
    //       },
    //       {
    //         id: 'y-axis-1',
    //         position: 'right',
    //         gridLines: {
    //           color: 'rgba(0,0,2555,0.2)',
    //         },
    //         ticks: {
    //           fontColor: 'black',
    //         }
    //       }
    //     ]
    //   },
    //   annotation: {
    //     annotations: [
    //       {
    //         type: 'line',
    //         mode: 'vertical',
    //         scaleID: 'x-axis-0',
    //         value: 'March',
    //         borderColor: 'orange',
    //         borderWidth: 2,
    //         label: {
    //           enabled: true,
    //           fontColor: 'orange',
    //           content: 'LineAnno'
    //         }
    //       },
    //     ],
    //   },
    // };
    // public lineChartColors: Color[] = [
    //   { // grey
    //     // backgroundColor: 'rgba(148,159,177,0.2)',
    //     borderColor: 'rgba(255,0,0,0.6)',
    //     pointBackgroundColor: 'rgba(255,0,0,0.6)',
    //     pointBorderColor: 'rgba(255,0,0,0.6)',
    //     // pointHoverBackgroundColor: '#fff',
    //     // pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    //   },
    //   { // dark grey
    //     // backgroundColor: 'rgba(0,0,255,1)',
    //     borderColor: 'rgba(0,0,255,1)',
    //     pointBackgroundColor: 'rgba(0,0,255,1)',
    //     pointBorderColor: 'rgba(0,0,255,1)',
    //     // pointHoverBackgroundColor: '#fff',
    //     // pointHoverBorderColor: 'rgba(77,83,96,1)'
    //   },
    //   { // red
    //     // backgroundColor: 'rgba(0,255,0,0.6)',
    //     borderColor: 'green',
    //     pointBackgroundColor: 'rgba(0,255,0,0.6)',
    //     pointBorderColor: 'green',
    //     // pointHoverBackgroundColor: 'green',
    //     // pointHoverBorderColor: 'rgba(0,255,0,0.6)'
    //   }
    // ];
    // public lineChartLegend = true;
    // public lineChartType = 'line';
    // public lineChartPlugins = [];

    // @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  // FIN GRAFICA Mamalona


  constructor(
    private _route: ActivatedRoute,
    private _HistoriaClinicaService: HistoriaClinicaService,
    public _consultaService: ConsultaService,
    private router: Router,
    public _Cie10Service: Cie10Service
  ) { }

  ngOnInit(): void {
    this.fecha = moment().format('L');

    // OBTENEMOS LA INFORMCION DEL ROL
      this.hojaEvolucion.medicoTrante = getDataStorage().nombre;
    // Obtener Id del Paciente
    this.id = this._route.snapshot.paramMap.get('id');
    this.obtenerConsulta();
    // this.setEdad();
  }

  alertaGraficas(){
    swal({
      icon: 'error',
      title: 'Vaya...',
      text: 'Al parecer es la primer visita del paciente, no hemos encontrado Historico de Signos Vitales',
      // footer: '<a href>Why do I have this issue?</a>'
    })
  }

  // setEdad(){
  //   this.edad = parseFloat(this.paciente.edad);
  // }

  obtenerConsulta(){
    this._HistoriaClinicaService.obtenerConsultaPorElId( this.id  )
    .subscribe(
      (data:any) => {
        this.paciente.consultas = data['data']['paciente']['consultas'];
        this.hora = data['data'].horaIngreso;
        this.paciente.nombre = data['data']['paciente']['nombrePaciente'];
        this.paciente.apellidoPaterno = data['data']['paciente'].apellidoPaterno;
        this.paciente.apellidoMaterno = data['data']['paciente'].apellidoMaterno;
        this.paciente.edad = data['data']['paciente'].edad;
        this.paciente.curp = data['data']['paciente'].curp;
        this.paciente.idPaciente = data['data'].paciente._id;
        this.paciente.diastolica = data['data'].diastolica;
        this.paciente.sistolica = data['data'].sistolica;
        this.paciente.genero = data['data'].paciente.genero;
        this.paciente.registro = data['data']['paciente']['fechaRegistro'];
        this.paciente.calle = data['data']['paciente']['callePaciente'];
        this.paciente.talla = data['data']['talla'];
        this.paciente.temp = data['data']['temp'];
        this.paciente.peso = data['data']['peso'];
        this.paciente.pt = data['data']['pt'];
        this.paciente.pc = data['data']['pc'];
        this.paciente.imc = data['data']['imc'];
        this.paciente.lpm = data['data']['lpm'];
        this.paciente.pa = data['data']['pa'];
        this.paciente.apgar = data['data']['apgar'];
        this.paciente.sao = data['data']['SaO'];
        this.paciente.pao = data['data']['pao'];
        this.paciente.rpm =data['data']['rpm'];
        this.paciente.glucosa =data['data']['glucosa'];
        this.paciente.fc = data['data'].fc
        this.paciente.fr = data['data'].fr
      })
  }

   //Inicio Funciones Grafica
  //  public randomize(): void {
  //   for (let i = 0; i < this.lineChartData.length; i++) {
  //     for (let j = 0; j < this.lineChartData[i].data.length; j++) {
  //       this.lineChartData[i].data[j] = this.generateNumber(i);
  //     }
  //   }
  //   this.chart.update();
  // }

  // private generateNumber(i: number) {
  //   return Math.floor((Math.random() * (i < 2 ? 100 : 1000)) + 1);
  // }

  // // events
  // public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  //   // console.log(event, active);
  // }

  // public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  //   // console.log(event, active);
  // }

  // public hideOne() {
  //   const isHidden = this.chart.isDatasetHidden(1);
  //   this.chart.hideDataset(1, !isHidden);
  // }

  // public pushOne() {
  //   this.lineChartData.forEach((x, i) => {
  //     const num = this.generateNumber(i);
  //     const data: number[] = x.data as number[];
  //     data.push(num);
  //   });
  //   this.lineChartLabels.push(`Label ${this.lineChartLabels.length}`);
  // }

  // public changeColor() {
  //   this.lineChartColors[2].borderColor = 'green';
  //   this.lineChartColors[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;
  // }

  // public changeLabel() {
  //   this.lineChartLabels[2] = ['1st Line', '2nd Line'];
  //   // this.chart.update();
  // }

  // funciones de validaciones

  validarMotivoConsulta(){
    if(this.hojaEvolucion.motivoDeConsulta.length < 6 || this.hojaEvolucion.motivoDeConsulta == ''){
      return  false;
    }else {
      return true;
    }
  }

  validarEvolucionDelPaciente(){
    if(  this.hojaEvolucion.evolucionDelPadecimiento == ""  || this.hojaEvolucion.evolucionDelPadecimiento.length < 6 ){
      return false;
    }else {
      return true;
    }
  }


  validarPlan(){
    if(   this.hojaEvolucion.plan == "" || this.hojaEvolucion.plan.length < 5 ){
      return false;    
    }else {
      return true;
    }
  }

  // validamos la hoja en general
  validarHistoriaClinica(){
    
    if(  this.validarMotivoConsulta() ){
     
    }else {
      swal("Llena el motivo de la consulta",{icon:"warning"})
      /* alert('Llena el motivo de la consulta'); */
      return false;
    }
    
    if( this.validarEvolucionDelPaciente() ){
        
    }else{
      swal("Llena la evolucion del paciete",{icon:"warning"})
      /* alert('Llena la evolucion del paciete'); */
      return false;

    }

    if(  this.validarPlan() ){

    }else{
      swal("Completa el plan",{icon:"warning"})
      /* alert('Completa el plan'); */
      return false;
    }

      return true;
  }

  agregarDiagnosticoUno( item  ){
    let diagnostico  = { diagnostico: item}
    
    this.hojaEvolucion.diagnostico.push( diagnostico );
    this.diagnosticos = [];
  }

  obtenerDiagnosticoUno(){

    if(this.buscarDiagnostico.length > 3) {
      this._Cie10Service.getCiePorNombre(this.buscarDiagnostico).subscribe(
        (data:any) => {
            this.diagnosticos = data.data;
        });
    }

  }

// validaciones
  validacion(texto: string){
    if( texto.length == 0  ){
      return true;
    }else {
      return false;
    }

  }

  alertValidacionicompleta(message){
    swal(message,{icon:"warning"})
    /* alert(message); */
    return false;
  }

  validarAparatosYsistemas(){


    if( this.validacion(    this.hojaEvolucion.respiratorioCardiovascular)  ){
      swal("Completa los campos",{icon:"warning"})
      /* alert('Completa los campos'); */
      return false;
    }else if(this.validacion(  this.hojaEvolucion.digestivo )  ){
      swal("Completa los campos",{icon:"warning"})
      /* alert('Completa los campos'); */
      return false;
    }else if(  this.validacion( this.hojaEvolucion.endocrino )) {
      swal("Completa los campos",{icon:"warning"})
      /* alert('Completa los campos'); */
      return false;
    }else if(this.validacion( this.hojaEvolucion.musculoEsqueletico )  ){
      swal("Completa los campos",{icon:"warning"})
      /* alert('Completa los campos'); */
      return false;
    }else if(this.validacion( this.hojaEvolucion.hematopoyeticoLinfatico) ) {
      swal("Completa los campos",{icon:"warning"})
      /* alert('Completa los campos'); */
      return false;
    }else if( this.validacion( this.hojaEvolucion.piel ) ){
      swal("Completa los campos",{icon:"warning"})
      /* alert('Completa los campos'); */
      return false;
    }else if(this.validacion( this.necistaReceta )  ){
      swal("Completa los campos",{icon:"warning"})
      /* alert('Completa los campos'); */
      return false;
    }else if( this.validacion( this.hojaEvolucion.pielAnexos ) ){
     return this.alertValidacionicompleta('completa el campo piel y anexos');
    }else if(this.validacion( this.hojaEvolucion.cabezaCuello ) ){
      return this.alertValidacionicompleta('completa el campo exploracion cabeza y cuello');
    }else if(this.validacion(  this.hojaEvolucion.torax) ){
      return this.alertValidacionicompleta('completa el campo torax');
    }else if( this.validacion(this.hojaEvolucion.abdomen) )  { 
      return this.alertValidacionicompleta( 'completa el campo Abdomen' )
    }else if( this.validacion(this.hojaEvolucion.genitales) )  { 
      return this.alertValidacionicompleta( 'completa el campo Genitales' );
    }else if( this.validacion(this.hojaEvolucion.extremidades) )  { 
      return this.alertValidacionicompleta( 'completa el campo Extremidades' )
    }else if( this.validacion(this.hojaEvolucion.sistemaNervioso) )  { 
      return this.alertValidacionicompleta( 'completa el campo Sistema nervioso' );
    }

    return true;

  }

  // agregamos la hoja de evlucion a la consulta
  agregarHistoriaClinica(){



    if(   this.validarHistoriaClinica() && this.validarAparatosYsistemas() == true ){

      this._HistoriaClinicaService.agregarHojaEvolucion( this.id, this.hojaEvolucion )
    .subscribe( (data) => {
      if(data['ok']){ 
        let idHistoriaClinica = { idHistoriaClinica: data['data']['_id'] }
         this._HistoriaClinicaService.actualizarHistoriaclinicaPorAparatos(  this.paciente.idPaciente, idHistoriaClinica )
         .subscribe( (data) => {
           if(data['ok']){
             if( this.necistaReceta == 'si' ){ 
              this.router.navigate(['receta','medica', this.id ]);               
            }else {

              this.router.navigate(['hoja','evolucion', this.id ]);
            }
            swal("Datos agregados",{icon:"success"})
            /* alert('Datos agregados'); */
            
           }
         });
      }
    });

    }

    return;
    
  }





}
