<!-- <section> -->
<div class="row">
    <div class="col-md-8">
        <h1 class="centerxd"> HOJA DE EVOLUCIÓN </h1>

    </div>
</div>
<!-- </section> -->

<section>
    <div class="card" name="card" id="card">
        <div class="row">
            <div class="col-md-5">
                <p> <span> Nombre Completo: {{paciente.nombre | uppercase}} {{paciente.apellidoPaterno | uppercase }}
                            {{paciente.apellidoMaterno | uppercase}} </span> </p>
                <p> CURP: {{ paciente.curp | uppercase}} </p>
                <p> Fecha: {{ fecha }} </p>

            </div>
            <div class="col-md-4">
                <p> <span> Edad: {{paciente.edad}} </span> </p>
                <p>Registro: {{paciente.registro}} </p>
                <p>Dirección: {{paciente.calle | uppercase}} {{ paciente.municipio | uppercase}}</p>
            </div>
            <div class="col-md-3">
                <p> <span> Sexo: {{paciente.sexo | uppercase}} </span> </p>
                <p> ID: {{ paciente.idPaciente }} </p>

            </div>
        </div>
    </div>
</section>

<!-- COMPONENTE DE LAS GRAFICAS BROW -->
<div class="">
    <app-graficas-pediatria [paciente]="paciente" *ngIf="paciente.sexo == 'FEMENINO' && paciente.edad < 9">
    </app-graficas-pediatria>
</div>

<div class="">
    <app-graficas-ninos [paciente]="paciente" *ngIf="paciente.sexo == 'MASCULINO' && paciente.edad < 9"></app-graficas-ninos>
</div>

<section>
    <div class="card" id="card">
        <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-link" data-toggle="modal" data-target="#exampleModal"> Historia clinica
                </button>
            <button type="button" class="btn btn-link" data-toggle="modal" data-target="#labModal" (click)="obtenerResultados()"> Laboratorio </button>
            <button type="button" class="btn btn-link" data-toggle="modal" data-target="#xrayModal" (click)="XrayResultados()"> Rayos x </button>
            <button type="button" class="btn btn-link"> Endoscopias </button>
            <button type="button" class="btn btn-link"> Tomografias </button>
            <button type="button" class="btn btn-link" data-toggle="modal" data-target="#usgModal" (click)="mostarUsg()"> Ultrasonidos </button>
            <button type="button" class="btn btn-link"> Resonancias </button>
            <button type="button" class="btn btn-link"> Otros Estudios </button>
            <button type="button" class="btn btn-link" data-toggle="modal" data-target="#evolucion" (click)="obtenerHojasDeEvolucion()"> Historico Evolución </button>
        </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5> Historia clínica </h5>
                </div>
                <div class="modal-body">
                    <app-historia-clinica [paciente]="paciente"></app-historia-clinica>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"> Cerrar </button>
                </div>
            </div>
        </div>
    </div>

    <!-- modal laboratorio -->
    <!--div class="modal fade" id="labModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header" *ngFor="let titles of resultado">
                        <h5> Laboratorio: {{titles.idEstudio.ESTUDIO}} </h5>
                    </div>
                    <div class="modal-body">
                        <div class="card">
                            <div class="form-group row">
                            </div>
                            <div class="form-group row">
                                <label class="col-4 col-form-label">ELEMENTOS</label>
                                <label class="col-4 col-form-label">OBTENIDO</label>
                                <label class="col-4 col-form-label">VALORES DE REFERENCIA</label>
                            </div>
                            <div *ngFor="let item of resultado">
                                <div class="form-group row" *ngFor="let it of item.idEstudio.ELEMENTOS; let i = index">
                                    <div class="col-4 col-form-label">
                                        <label class="col col-form-label">Grupo:{{it.tipo}} </label>
                                        <label class="col col-form-label">{{it.elementos}} </label>
                                    </div>
                                    <div class="col-4 col-form-label">
                                        <label class="col col-form-label"></label>
                                        <label class="col col-form-label">{{obtenido[i+1]}}</label>
                                    </div>
                                    <div class="col-md col-form-label" *ngFor="let item of it.referencia">
                                        <label class="col col-form-label"></label>
                                        <label class="col-md col-form-label">{{item.referencia}} </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal"> Cerrar </button>
                    </div>
                </div>
            </div>
        </div-->

    <!--SVY-->
    <div class="modal fade" id="labModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5> ESTUDIOS LABORATORIO </h5>
                </div>
                <div class="modal-body">
                    <div class="card" id="card" style="height: 30rem !important; overflow: scroll-y;">
                        <div role="tabpanel">
                            <ul class="nav nav-tabs">
                                <li class="nav-item" *ngFor="let titles of resultado ; index as i  ; let first = first">
                                    <a class="nav-link active fade show" data-toggle="tab" href="#{{ i }}" [ngClass]="{'active': first}" id="{{i}}" (click)="selectContentB(i)">
                                            {{titles.idEstudio.ESTUDIO}}
                                        </a>
                                </li>
                            </ul>
                            <div class="tab-content" *ngFor="let item of resultado; index as i;">
                                <div role="tabpanel container" class="tab-pane fade show active" id="{{ i }}" [ngClass]="{'active': i == idContentB}">
                                    <br>
                                    <table class="table" style="display: block;height: 25rem;overflow: scroll;">
                                        <thead>
                                            <th>TIPO</th>
                                            <th>ELEMENTOS</th>
                                            <th>OBTENIDO</th>
                                            <th>VALORES DE REFERENCIA</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let it of item.idEstudio.ELEMENTOS; let i = index">
                                                <td>
                                                    {{ tipos[i] }}
                                                </td>
                                                <td>
                                                    {{ it.elementos }}
                                                </td>
                                                <td>
                                                    {{ obtenido [i + 1] }}
                                                </td>
                                                <tbody>
                                                    <tr *ngFor="let itemB of it.referencia">
                                                        <td>
                                                            {{ itemB.referencia }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </tr>
                                        </tbody>

                                        <!--div class="col-4 col*form-label">
                                                <label class="col col-form-label">
                                                    {{ it.tipo }}
                                                </label>
                                                <label class="col col-form-label">
                                                    {{ it.elementos }}
                                                </label>
                                            </div>
    
                                            <div class="col-4 col-form-label">
                                                <label class="col col-form-label"></label>
                                                <label class="col-md col-form-label">
                                                    {{ obtenido [i + 1] }}
                                                </label>
                                            </div>
    
                                            <div class="col-md col-form-label" *ngFor="let itemB of it.referencia">
                                                <label class="col col-form-label">-</label>
                                                <label class="col-md col-form-label">
                                                    {{ itemB.referencia }}
                                                </label>
                                            </div-->

                                        <!--div *ngFor="let item of resultado; index as i;" id="{{i}}"-->
                                        <!--div class="form-group row"
                                                    *ngFor="let it of item.idEstudio.ELEMENTOS; let i = index" id="{{i}}">
                                                    <div class="col-4 col-form-label">
                                                        <label class="col col-form-label">{{it.tipo}} </label>
                                                        <label class="col col-form-label">{{it.elementos}} </label>
                                                    </div>
                                                    <div class="col-4 col-form-label">
                                                        <label class="col col-form-label"></label>
                                                        <label class="col col-form-label">{{obtenido[i+1]}}</label>
                                                    </div>
                                                    <div class="col-md col-form-label" *ngFor="let item of it.referencia">
                                                        <label class="col col-form-label"></label>
                                                        <label class="col-md col-form-label">{{item.referencia}} </label>
                                                    </div>
                                                </div-->
                                        <!--/div-->

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"> Cerrar </button>
                </div>
            </div>
        </div>
    </div>
    <!--SVY-->

    <!--  MODAL MOSTRAR ULTRASONIDO POR FECHA -->
    <div class="modal fade" id="usgModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5> FECHAS REPORTES USG </h5>
                </div>
                <div class="modal-body">
                    <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal" data-toggle="modal" data-target="#DusgModal" (click)="mostarUsg()"> {{ fechaAntes | date }} </button> -->

                    <div class="card" id="card" style="width: 61rem !important; height: 30rem !important; overflow: scroll-y;">
                        <div role="tabpanel">
                            <ul class="nav nav-tabs">
                                <li class="nav-item" *ngFor="let item of dates ; index as i  ; let first = first">
                                    <a class="nav-link active fade show" data-toggle="tab" href="#{{ i }}" [ngClass]="{'active': first}" id="{{i}}" (click)="selectContentC(i)"> {{
                                            item.fecha | date }} 
                                        </a>
                                </li>
                            </ul>
                            <div class="tab-content" *ngFor="let ss of dates ; index as i;">
                                <div role="tabpanel" class="tab-pane fade show active" id="{{ i }}" [ngClass]="{'active': i == idContentC}">
                                    <br>
                                    <div class="container" style="display: block;height: 25rem;overflow: scroll;">
                                        <h5> REPORTE ULTRASONIDO </h5>
                                        <br>
                                        <h3 class="text-center">
                                            {{ss.idEstudio.ESTUDIO}}
                                        </h3>
                                        <br>
                                        <h5>INTERPRETACION</h5>
                                        <br>
                                        <div class="text-justify">
                                            {{ss.machoteEdit}}
                                        </div>
                                        <br>
                                        <h5>DIAGNOSTICO</h5>
                                        {{ss.diagnostico}}
                                        <br>
                                        <h5>OBSERVACIÓNES</h5>
                                        {{ss.observaciones}}
                                        <h5>IMAGENES</h5>
                                        <div class="row">
                                            <div class="col">
                                                <div class="usg">
                                                    <div *ngFor="let t of ss.imageUrl">
                                                        <img class="img-fluid  img-thumbnail" src="https://horizonte-backend.herokuapp.com/img/{{t}}">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"> Cerrar </button>
                </div>
            </div>
        </div>
    </div>


    <!-- ############################ MODAL RAYOS X ############################################################ -->

    <div class="modal fade" id="xrayModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5> FECHAS REPORTES RAYOS X </h5>
                </div>
                <div class="modal-body">
                    <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal" data-toggle="modal" data-target="#DusgModal" (click)="mostarUsg()"> {{ fechaAntes | date }} </button> -->

                    <div class="card" id="card" style="width: 75rem !important; height: 30rem !important; overflow: scroll-y;">
                        <div role="tabpanel">
                            <ul class="nav nav-tabs">
                                <li class="nav-item" *ngFor="let item of datesx ; index as i  ; let first = first">
                                    <a class="nav-link active fade show" data-toggle="tab" href="#{{ i }}" [ngClass]="{'active': first}" id="{{i}}" (click)="ContenidoXray(i)"> {{
                                            item.fecha | date }} </a>
                                </li>

                            </ul>



                            <div class="tab-content" *ngFor="let ss of datesx ; index as i">

                                <div role="tabpanel" class="tab-pane fade show active" id="{{ i }}" [ngClass]="{'active': i == idContentXray}">
                                    <br>
                                    <div class="container" style="display: block;height: 25rem;overflow: scroll;">


                                        <h5> REPORTE RAYOS X </h5>
                                        <br>

                                        <h3 class="text-center">
                                            {{ss.idEstudio.ESTUDIO}}
                                        </h3>
                                        <br>
                                        <h5>INTERPRETACION</h5>
                                        <br>

                                        <div class="text-justify">
                                            {{ss.machoteEdit}}

                                        </div>
                                        <br>
                                        <h5>DIAGNOSTICO</h5>
                                        {{ss.diagnostico}}

                                        <br>
                                        <h5>OBSERVACIÓNES</h5>
                                        {{ss.observaciones}}

                                        <h5>IMAGENES</h5>
                                        <div class="row">
                                            <div class="col">
                                                <div class="xray">
                                                    <div *ngFor="let t of ss.imageUrl">

                                                        <img class="img-fluid  img-thumbnail" src="https://horizonte-backend.herokuapp.com/img/{{t}}">
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>

                </div>


                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"> Cerrar </button>
                </div>
            </div>
        </div>
    </div>
    <!--  MODAL MOSTRAR ULTRASONIDO POR FECHA -->
    <div class="modal fade" id="evolucion" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5> Historico de evoluciones </h5>
                </div>
                <div class="modal-body">
                    <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal" data-toggle="modal" data-target="#DusgModal" > {{ fechaAntes | date }} </button> -->

                    <div class="card" id="card" style="width: 61rem !important; height: 30rem !important; overflow: scroll-y;">
                        <div role="tabpanel">
                            <ul class="nav nav-tabs">
                                <li class="nav-item" *ngFor="let item of hojasEvoluciones; index as i;  let first = first">
                                    <a class="nav-link active fade show" data-toggle="tab" href="#{{ i }}" [ngClass]="{'active': first}" id="{{i}}" (click)="ContentHistoricoEvolucion(i)"> {{
                                        item.fechaHojaEvolucion  }} 
                                    </a>

                                </li>
                            </ul>
                            <div class="tab-content" *ngFor="let item of hojasEvoluciones; index as i">

                                <div role="tabpanel" class="tab-pane fade show active" id="{{ i }}" [ngClass]="{'active': i == idContentEvolucion}">

                                    <div class="container" style="display: block;height: 25rem;overflow: scroll;">
                                        <!-- <h5> Evolución </h5> -->
                                        <h5>MOTIVO DE CONSULTA</h5>

                                        <p class="text-center" style="text-align: justify!important">
                                            {{item.evolucion | uppercase}}
                                        </p>


                                        <!-- <p>{{hojaEvolucion.evolucion}}</p> -->


                                        <div class="text-justify">

                                        </div>

                                        <h5>DIAGNOSTICO</h5>
                                        <p *ngFor="let pendejo of item.diagnosticos">
                                            {{pendejo.diagnostico | uppercase}}
                                        </p>

                                        <h5>PLAN</h5>
                                        <p>{{item.plan | uppercase}}</p>




                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"> Cerrar </button>
                </div>
            </div>
        </div>
    </div>


    <!-- ##########################################################################################################3 -->




</section>
<section>

    <div class="row mt-3">
        <div class="col-md-3">
            <div class="card" id="card" style="overflow-x: scroll; height: 25rem;">
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>Talla: <span>{{paciente.talla}}</span> m</p>
                    </div>
                    <div class="col-6">
                        <button type="button" (click)="graficaTallaL()" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaTalla"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>Peso: <span>{{paciente.peso}}</span> Kg</p>
                    </div>
                    <div class="col-6">
                        <button type="button" (click)="graficaPeso()" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>IMC: <span> {{paciente.imc}}</span> Kg/m<sup>2</sup> </p>
                    </div>
                    <div class="col-6">
                        <button type="button" (click)="graficaIMC()" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaIMC"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad > 9">
                    <div class="col-6">
                        <p>Sistolica: <span> {{paciente.sistolica}} </span> </p>
                    </div>
                    <div class="col-6">
                        <button type="button" (click)="graficarPresion()" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaTA"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad > 9">
                    <div class="col-6">
                        <p>Diastolica: <span> {{paciente.diastolica}} </span> </p>
                    </div>
                    <!-- <div class="col-6">
                            <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#modalPesoNino"><i class="fas fa-chart-bar"></i></button>
                        </div> -->
                </div>
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad < 9">
                    <div class="col-6">
                        <p>FR: <span> {{paciente.fr}} </span> RPM</p>
                    </div>
                    <div class="col-6">
                        <button type="button" (click)="graficarFR()" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaFR"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad <9">
                    <div class="col-6">
                        <p>FC: <span> {{paciente.fc}} </span> LPM</p>
                    </div>
                    <div class="col-6">
                        <button type="button" (click)="graficarFC()" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaFC"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>TEMP: <span> {{paciente.temp}} </span> °C</p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaTemp" (click)="verSignosVitalesAnteriores()"><i
                                    class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>P.C.: <span> {{paciente.pc}} </span> cm</p>
                    </div>
                    <div class="col-6">
                        <button type="button" (click)="graficarPC()" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeC"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>P.A.: <span> {{paciente.pa}} </span> cm</p>
                    </div>
                    <div class="col-6">
                        <button type="button" (click)="graficarPA()" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPA"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>P.T.: <span> {{paciente.pt}} </span> cm</p>
                    </div>
                    <div class="col-6">
                        <button type="button" (click)="graficarPT()" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPT"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad <= 9">
                    <div class="col-6">
                        <p *ngIf="paciente.edad < 8">APGAR: <span> {{paciente.apgar}} </span></p>
                    </div>
                    <!-- <div class="col-6">
                            <button *ngIf="paciente.edad > 8" type="button" class="btn btn-primary col-sm-8"><i class="fas fa-chart-bar"></i></button>
                        </div> -->
                </div>
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad <= 9">
                    <div class="col-6">
                        <p>SaO: <span> {{paciente.sao}} </span> %</p>
                    </div>
                    <div class="col-6">
                        <button type="button" (click)="graficarSAO()" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaSao"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad > 9">
                    <div class="col-6">
                        <p>PaO: <span>{{paciente.pao}}</span>%SpO<sub>2</sub></p>
                    </div>
                    <div class="col-6">
                        <button type="button" (click)="graficarPAO()" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPao"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>Glucosa: <span>{{paciente.glucosa}}</span> mg/dl</p>
                    </div>
                    <div class="col-6">
                        <button type="button" (click)="graficarGlucosa()" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaGlucosa"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p> Alergias:<span> {{paciente.alergias}} </span></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-7">
            <div class="card" id="card" style="width: 61rem !important; height: 25rem !important;">
                <div role="tabpanel">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#motivoConsulta"> Descripción </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#diagnosticos"> Diagnósticos </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#plan"> Plan </a>
                        </li>
                    </ul>


                    <!-- SE MODIFICO EL MOTIVO DE LA CONSULTA POR DESCRIPCION -->
                    <div class="tab-content">

                        <div role="tabpanel" class="tab-pane active" id="motivoConsulta">
                            <br>
                            <div class="container">
                                <form>
                                    <div class="row">
                                        <textarea class="form-control text-upper" name="evolucion" id="evolucion" [(ngModel)]="hojaEvolucion.evolucion" cols="90" rows="10"></textarea>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <!-- Diagnosticos -->

                        <div role="tabpanel" class="tab-pane" id="diagnosticos">
                            <div class="container" style="overflow-x: scroll; height: 19rem;">
                                <div class="row">
                                    <br>
                                    <div class="col-md-12">
                                        <input class="form-control mt-3" type="text" [(ngModel)]="diagnosticoTxt" (keyup)="obtenerDiagnosticoUno()" name="diagnosticoUno" placeholder="Diagnostico...">
                                        <br>
                                        <div style="overflow-x: scroll; height: 5rem;">
                                            <table>
                                                <tr *ngFor="let item of diagnosticos">
                                                    <td (click)="agregarDiagnosticoUno( item.NOMBRE  )"> {{ item.NOMBRE }} </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="card">
                                            <ul *ngFor="let item of hojaEvolucion.diagnosticos">
                                                <li>
                                                    {{ item.diagnostico }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- PLAN -->
                        <div role="tabpanel" class="tab-pane" id="plan">
                            <div class="row">
                                <textarea class="form-control text-upper" name="evolucion" id="evolucion" [(ngModel)]="hojaEvolucion.plan" cols="110" rows="10" style="width: 60rem; margin: 1rem;"></textarea>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-4 offset-md-2">
                                    <button class="principalButton" (click)="agregarHojaEvolucion()"> Ambulatorio
                                        </button>

                                </div>
                                <div class="col-md-6">
                                    <button class="secondaryButton"> Hospitalización </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Modal -->
<div class="modal fade" id="graficaTalla" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="max-width: 500px; margin: 0 auto;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Grafica Talla</h5>
              <!--   <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> -->
            </div>
            <div class="modal-body">
                <div class="flex">
                    <div class="flex-item">
                        <div style="display: block;">
                            <canvas baseChart width="400" height="400" [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarModalGraficas()">Cerrar</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>

<!-- Graficas de Prueba -->
<div class="modal fade" id="graficaPeso" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="max-width: 500px; margin: 0 auto;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Grafica Peso</h5>
<!--                 <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> -->
            </div>
            <div class="modal-body">
                <div class="flex">
                    <div class="flex-item">
                        <div style="display: block;">
                            <canvas baseChart width="400" height="400" [datasets]="linePeso" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarModalGraficas()">Cerrar</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>

<!-- Grafica IMC -->
<div class="modal fade" id="graficaIMC" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="max-width: 500px; margin: 0 auto;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Grafica IMC</h5>
<!--                 <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> -->
            </div>
            <div class="modal-body">
                <div class="flex">
                    <div class="flex-item">
                        <div style="display: block;">
                            <canvas baseChart width="400" height="400" [datasets]="lineIMC" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarModalGraficas()">Cerrar</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>
<!-- Fin IMC -->
<!-- Inicio FC -->
<div class="modal fade" id="graficaFC" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="max-width: 500px; margin: 0 auto;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Grafica FC</h5>
<!--                 <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> -->
            </div>
            <div class="modal-body">
                <div class="flex">
                    <div class="flex-item">
                        <div style="display: block;">
                            <canvas baseChart width="400" height="400" [datasets]="lineFC" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarModalGraficas()">Cerrar</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>
<!-- Fin FC -->
<!-- FR -->
<div class="modal fade" id="graficaFR" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="max-width: 500px; margin: 0 auto;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Grafica FR</h5>
<!--                 <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> -->
            </div>
            <div class="modal-body">
                <div class="flex">
                    <div class="flex-item">
                        <div style="display: block;">
                            <canvas baseChart width="400" height="400" [datasets]="lineFR" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarModalGraficas()">Cerrar</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>
<!-- Fin FR -->
<!-- TEMP -->
<div class="modal fade" id="graficaTemp" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="max-width: 500px; margin: 0 auto;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Grafica Temperatura</h5>
<!--                 <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> -->
            </div>
            <div class="modal-body">
                <div class="flex">
                    <div class="flex-item">
                        <div style="display: block;">
                            <canvas baseChart width="400" height="400" [datasets]="lineTemp" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarModalGraficas()">Cerrar</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>
<!-- Fin Temp -->
<!-- PC -->
<div class="modal fade" id="graficaPeC" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="max-width: 500px; margin: 0 auto;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Grafica PC</h5>
<!--                 <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> -->
            </div>
            <div class="modal-body">
                <div class="flex">
                    <div class="flex-item">
                        <div style="display: block;">
                            <canvas baseChart width="400" height="400" [datasets]="linePC" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarModalGraficas()">Cerrar</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>
<!-- Fin PC -->
<!-- PA -->
<div class="modal fade" id="graficaPA" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="max-width: 500px; margin: 0 auto;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Grafica PA</h5>
<!--                 <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> -->
            </div>
            <div class="modal-body">
                <div class="flex">
                    <div class="flex-item">
                        <div style="display: block;">
                            <canvas baseChart width="400" height="400" [datasets]="linePA" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarModalGraficas()">Cerrar</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>
<!-- Fin PA -->
<!-- PT -->
<div class="modal fade" id="graficaPT" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="max-width: 500px; margin: 0 auto;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Grafica PT</h5>
<!--                 <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> -->
            </div>
            <div class="modal-body">
                <div class="flex">
                    <div class="flex-item">
                        <div style="display: block;">
                            <canvas baseChart width="400" height="400" [datasets]="linePT" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarModalGraficas()">Cerrar</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>
<!-- Fin PT -->
<!-- SAO -->
<div class="modal fade" id="graficaSao" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="max-width: 500px; margin: 0 auto;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Grafica SAO</h5>
<!--                 <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> -->
            </div>
            <div class="modal-body">
                <div class="flex">
                    <div class="flex-item">
                        <div style="display: block;">
                            <canvas baseChart width="400" height="400" [datasets]="lineSAO" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarModalGraficas()">Cerrar</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>
<!-- Fin SAO -->
<!-- glucosa -->
<div class="modal fade" id="graficaGlucosa" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="max-width: 500px; margin: 0 auto;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Grafica Glucosa</h5>
<!--                 <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> -->
            </div>
            <div class="modal-body">
                <div class="flex">
                    <div class="flex-item">
                        <div style="display: block;">
                            <canvas baseChart width="400" height="400" [datasets]="lineGlucosa" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarModalGraficas()">Cerrar</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>
<!-- Fin gluco -->
<!-- Presion Arterial -->
<div class="modal fade" id="graficaTA" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="max-width: 500px; margin: 0 auto;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Grafica Presión Arterial</h5>
<!--                 <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> -->
            </div>
            <div class="modal-body">
                <div class="flex">
                    <div class="flex-item">
                        <div style="display: block;">
                            <canvas baseChart width="400" height="400" [datasets]="linePresion" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarModalGraficas()">Cerrar</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>
<!-- Fin TA -->
<!-- PAO -->
<div class="modal fade" id="graficaPao" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="max-width: 500px; margin: 0 auto;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Grafica Oximetría</h5>
<!--                 <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button> -->
            </div>
            <div class="modal-body">
                <div class="flex">
                    <div class="flex-item">
                        <div style="display: block;">
                            <canvas baseChart width="400" height="400" [datasets]="linePAO" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cerrarModalGraficas()">Cerrar</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>
<!-- Fin PAO -->
<!-- Fin Graficas Prueba -->