<div class="container-fluid" style="height: 40rem; padding-left: 3.5rem;
        padding-right: 3.5rem;">
    <h1 class="centerxd">LISTADO DE PACIENTES</h1>
    <div class="row">
        <div class="col-md-9">
            <div class="form-group">
                <input type="text" name="filterPost" placeholder="Buscar" class="form-control" id="busquedaPaciente" style="margin-left: 25px; max-width:50em">
            </div>
        </div>
        <div class="col-md-2">
            <a type="button" class="btn btn-secondary boton_contra2">NUEVO</a>
        </div>
    </div>
    <br>


    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card" style="border-radius: 30px; margin-left: 25px;">
                <div class="card-header header">
                    <div class="row">
                        <div class="col text_header">
                            <p>Nombre</p>
                        </div>
                        <div class="col text_header">
                            <p>Género</p>
                        </div>
                        <div class="col text_header">
                            <p>Edad</p>
                        </div>
                        <div class="col text_header">
                            <p>Teléfono</p>
                        </div>
                        <div class="col text_header">
                            <p> Acciones </p>
                        </div>
                    </div>
                </div>


                <div class="card-body">
                    <div *ngFor="let  paciente  of pacientes2 | paginate:{id:'listapacientes',
                    itemsPerPage: 7,
                    currentPage:pagina,
                    totalItems:totalpaciente}; let i = index ">
                        <div class="row" [routerLink]="['/paciente', paciente._id]">
                            <div class="col" style="text-align: center;">
                                <p> {{ paciente.nombrePaciente | uppercase }} {{ paciente.apellidoPaterno | uppercase }} {{ paciente.apellidoMaterno | uppercase }} </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p> {{ paciente.genero | uppercase }} </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p> {{ paciente.edad }}</p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p> {{ paciente.telefono }} </p>
                            </div>
                            <div class="col" style="text-align: center;">
                                <p>
                                    <a [routerLink]="['/paciente', paciente._id]">Ver...</a> <br>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="offset-md-4">
                    <pagination-controls id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- <section class="row">
    <div class="col">
        <h1 class="centerxd" id="centerxd">LISTADO DE PACIENTES</h1>
    </div>
</section>


<section class="row">
    <div class="col-md-8">
        <input type="text" placeholder="Buscar" class="form-control" name="filtroPacientes" [(ngModel)]="filtropacientes" id="buscador">
    </div>

    <div>
        <button class="boton" style="color: aliceblue; ">IMPRIMIR</button>
    </div>


    <div class="col-md ">
        <button class="btn" style="color: aliceblue; ">AGREGAR</button>

    </div>

</section> -->
