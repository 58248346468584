<div class="col-md-12">
    <h1 class="centerxd">BITÁCORA DE LABORATORIO</h1>
    <button class="principalButton otherSVY" (click)="printTable()">
        Imprimir
    </button>
    <br>
</div>
<br>

<div class="col-md-12" id="printTableThis">
    <div class="card" style="border-radius: 30px;">
        <div class="card-header header" style="border-top-left-radius: 24px;
        border-top-right-radius: 24px;">
            <div class="row">

                <div class="col text_header">
                    <p>No.</p>
                </div>
                <div class="col text_header">
                    <p>Fecha</p>
                </div>
                <div class="col text_header">
                    <p>Nombre</p>
                </div>
                <div class="col text_header">
                    <p>Edad</p>
                </div>
                <div class="col text_header">
                    <p>Sexo</p>
                </div>
                <div class="col text_header">
                    <p>Sede</p>
                </div>
                <div class="col text_header">
                    <p>Estudio</p>
                </div>
                <div class="col text_header">
                    <p>Prioridad</p>
                </div>

            </div>

        </div>
    </div>
    <div class="card-body" style="background-color: #ffffff; border-radius: 30px;">
        <div *ngFor="let item of consultas | paginate:{id:'listaAmbulancia',
        itemsPerPage: 4 ,
        currentPage:pagina,
        totalItems:totalAmbulancia}; let i = index" [routerLink]="['/hoja-servicios', item._id]">

            <div class="row">
                <div class="col">
                    <p>{{ i +1 }}</p>
                </div>
                <div class="col">
                    <p>{{item.fecha}}</p>
                </div>
                <div class="col">
                    <p>{{item.idPaciente.nombrePaciente}} {{item.idPaciente.apellidoPaterno}} {{item.idPaciente.apellidoMaterno}}
                    </p>
                </div>
                <div class="col">
                    <p>{{item.idPaciente.edad}}</p>
                </div>
                <div class="col">
                    <p>{{item.idPaciente.genero | generos:true }}</p>
                </div>
                <div class="col">
                    <p>{{item.sede}}</p>
                </div>
                <div class="col">
                    <p>{{item.estudios[0].nombreEstudio}}</p>
                </div>
                <div class="col">
                    <p>{{item.prioridad}}</p>
                </div>
            </div>
        </div>
        <div class="offset-md-3">
            <pagination-controls id="listaAmbulancia" maxSize="10" directionLinks="true" (pageChange)="pagina = $event">
            </pagination-controls>
        </div>
    </div>
</div>
<!-- <div class="card" style="border-radius: 30px;">

    <div class="row">
        <div class="col-md-12">


            <table class="table table-hover colors izq" id="bitacora">

                <thead class="center" style="background-color: #03318C;">

                    <tr style="color: white;">

                        <th scope="col"> No.</th>
                        <th scope="col"> Fecha </th>
                        <th scope="col"> Nombre </th>
                        <th scope="col"> Edad </th>
                        <th scope="col"> Sexo </th>
                        <th scope="col"> Sede </th>
                        <th scope="col ">Diagnostico</th>
                        <th scope="col ">Estudio</th>
                        <th scope="col"> Prioridad </th>
                        <th scope="col ">Nota</th>

                    </tr>




                </thead>

                <tbody class="center" style="background-color: white;color: black;">

                    <tr *ngFor="let item of consultas; let i = index">



                        <td> {{ i +1 }} </td>
                        <th>{{item.fecha}}</th>
                        <th [routerLink]="['/hoja-servicios', item._id]">{{item.idPaciente.nombrePaciente}} {{item.idPaciente.apellidoPaterno}} {{item.idPaciente.apellidoMaterno}}</th>
                        <th>{{item.idPaciente.edad}}</th>
                        <th> {{item.idPaciente.genero | generos:true }}</th>
                        <th>{{item.sede}}</th>
                        <th></th>
                        <th>{{item.estudios[0].nombreEstudio}}</th>
                        <th>{{item.prioridad}}</th>
                        <th></th>



                    </tr>
                </tbody>

            </table>
        </div>


    </div>

</div> -->

<br>