import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { PacientesService } from '../../../services/pacientes/pacientes.service';
import {   IdentificacionService } from 'src/app/services/consultas/consulta/identificacion.service';
import { Router } from '@angular/router';

import * as moment from 'moment';
import { WsLoginService } from 'src/app/services/sockets/ws-login.service';
import {  ConsultaService } from 'src/app/services/consultas/consulta.service';
import swal from 'SweetAlert';

moment.locale('es');

@Component({
  selector: 'app-consuta',
  templateUrl: './consultas.component.html',
  styleUrls: ['./consultas.component.css']
})
export class ConsultasComponent implements OnInit {

  public nombrePaciente ="";
  public pacientes:[]=[];
  public idPaciente="";
  public fechaIngreso = moment().format('l');
  public horaIngreso = moment().format('LTS');
  public servicio = "";
  public consultorio = "";

  public consultasCount = {
    consultas: 0
  }


  public PACIENTES_SERVICES:[]=[];

  public paciente = {

    // los datos del paciente son solo representacionales
    nombrePaciente:"",
    apellidoPaterno:"",
    apellidoMaterno:"",
    genero:"",
    curp: "",
    id:"",
    fechaIngreso:this.fechaIngreso,
    horaIngreso: this.horaIngreso,
    enfermeraAtendio:  "" ,
    diagnosticoActual: "",
    notaDeLaEnfermera: "",
    consultorio:"",
    doctorAPasar:'',
    consultas: 0,
    servicio:'',
    motivoIngreso:'',
    notaRecepcion:'',
    turno:'Matutino'
  }

  public listaEspera = [];

  constructor(
    public _pacienteService: PacientesService,
    public _route: ActivatedRoute,
    public _wsLoginService: WsLoginService,
    public _consultaService: IdentificacionService,
    public _consultasService: ConsultaService,
    public _router:Router
  ) { }

  ngOnInit(): void {
    this.idPaciente =  this._route.snapshot.paramMap.get('id');
    // console.log(  this.idPaciente );
    this.obtenerConsultas();
    if( this.idPaciente == null ){

      return;
    }else {

      this.obtenerPacientePorId();

    }
  }



  obtenerPacientePorId(){
      this._pacienteService.getPacienteBtID(  this.idPaciente )
      .subscribe(
        (data:any) => {
          this.paciente.nombrePaciente = data['paciente']['nombrePaciente'];
          this.paciente.apellidoPaterno = data['paciente']['apellidoPaterno'];
          this.paciente.apellidoMaterno = data['paciente']['apellidoMaterno'];
          this.paciente.curp = data['paciente']['curp'];
          this.paciente.genero = data['paciente']['genero']
          this.paciente.id = data['paciente']['_id'];
        });
  }


  seleccionarConsultprop( consultorio  ) {


    let selectConsultorio = document.getElementById('consultorioSelect');

      if( consultorio['value']   == "seleccionarConsultorio" ){
        alert('Selecciona un consultorio');
        selectConsultorio.classList.add('is-invalid')
        return;
      }
      this.consultorio = consultorio;

  }


  seleccionarPaciente( item ) {
      this.paciente.nombrePaciente = item['nombrePaciente'];
      this.paciente.apellidoPaterno = item['apellidoPaterno'];
      this.paciente.apellidoMaterno = item['apellidoMaterno'];
      this.paciente.curp = item['curp'];
      this.paciente.genero = item['genero'];
      this.paciente.consultas = item['consultas'];
      this.paciente.id = item['_id'];
      this.PACIENTES_SERVICES = [];
  }

  buscarPaciente(  nombre: string ){

    if(  nombre.length >= 3 ){
      this._pacienteService.getPacientePorNombre( nombre )
      .subscribe(
         (data) => {

           if( data['pacientes']  == [] ){
             alert('No hay pacientes con ese nombre');
             return;
           }
          this.PACIENTES_SERVICES = data['data'];
          } );
    }
  }

  actualizarConteoConsultas(){

    this.consultasCount.consultas = this.paciente.consultas = this.paciente.consultas + 1;
    this._consultasService.actualizarConteoConsultas(  this.paciente.id, this.consultasCount )
    .subscribe( data => console.log(data));

  }

  enviar(){


    // let exampleModal1 = document.querySelector('.modal-backdrop');

    // console.log( exampleModal1 );
    // aca se crea la tabla que se va modificando de acurdo a la evolucion del paciete

    this._consultaService.agregarConsulta(  this.paciente )
    .subscribe((data) => {
        if( data['ok'] ){
          this._wsLoginService.enviarConsultas(  data['data']['_id'] );
          swal('El paciente pasó a enfermería', '' , 'success');
          // alert('Se pasó a enfermería');
          this.actualizarConteoConsultas();
          this.PACIENTES_SERVICES = [];

          // exampleModal1.classList.remove('modal-backdrop');
          this._router.navigateByUrl('/');
          // this.ngOnInit();

        }
      });

  }
  obtenerConsultas(){
    this._consultasService.verConsultasPendientesEnfermeria()
    .subscribe( (data) => this.listaEspera = data['data'] );

  }
}
