import { Component, Input, OnInit} from '@angular/core';
import swal from 'sweetalert';
import  Carrito  from '../../../../clases/carrito/carrito.class';
import { eliminarStorage, getCarritoStorage } from '../../../../functions/storage/pacienteIntegrados';
import { ServiciosService } from '../../../../services/serviciosInt/servicios.service';
import { Router } from '@angular/router';
import Tickets from 'src/app/clases/tickets/tickets.class';

@Component({
  selector: 'app-tabla-service',
  templateUrl: './tabla-service.component.html',
  styleUrls: ['./tabla-service.component.css']
})
export class TablaServiceComponent implements OnInit {

  @Input() serviceSi: any [] = [];
  public sede = "";
  public carrito = {
    totalSin: 0,
    totalCon: 0,
    items: []
  };

  public btnCotizacion = false;
  public btnPago = true;

  @Input() membresia = false;
  @Input() RoleUser = "";
  public pagina = 0;
  @Input() servicios ="";
  @Input() totalAmbulancia: string;

  constructor(private _service:ServiciosService, private _router:Router) { }

  ngOnInit(): void {
    this.obtenerCarritoStorage();
    this.getSede();
    this.obtenerPacienteYMembresia();
  }


  getSede (){
    this.sede = JSON.parse(localStorage.getItem('CEDE'));
  }

  obtenerCarrito (){

    this.carrito = getCarritoStorage();

    if ( this.carrito == null ){
        this.carrito = {
          totalSin: 0,
          totalCon: 0,
          items: []
        };
    }

  }

  obtenerCarritoStorage(){
    const storageCarrito = new Carrito();
    this.carrito = storageCarrito.obtenerSotorageCarrito();
  }

  agregarCarrito( event, item: any ) {
    let carrito = new Carrito();
    this.carrito = carrito.agregarItem(  event, item , this.membresia);
    this.validarCarrito();


  }

  eliminar(index){
    //console.log('Pedido', pedido);
    let carrito = new Carrito();
    carrito.eliminar( index );

    this.obtenerCarritoStorage();
    this.validarCarrito();
  }

  alertcomparasion( ev, precioPublico, precioMembresia, item2:any ){    
    /* let precioSinTrim  =  precioPublico.replace('$', '');
    let precioSinComaPublico = precioSinTrim.replace(',', '');
    let precioMemTrim  =  precioMembresia.replace('$', '');
    let precioMemComaMembresia = precioMemTrim.replace(',', ''); */
    
    this.agregarCarrito(ev, item2);
    swal("Con membrecia ahorras:"+(precioPublico - precioMembresia),{icon:"success"});
  }

  verDatos(){
    this._service.obtenerServiciosSedeNombre(this.servicios, this.sede).subscribe(
      (res: any) => {
          this.serviceSi = res.data;
          this.totalAmbulancia = res.data.results;
      },
    err => {
        console.log(<any>err);
    }
  );
  }
  validarCarrito(){
    this.obtenerCarritoStorage();
    if(this.carrito.items.length == 0){
      return true;
    }else{
      return false;
    }
  }

  
  obtenerPacienteYMembresia(){
    // se obtienen los precios con membresia
    let usuario = JSON.parse( localStorage.getItem('paciente'));
    if( usuario == null ){
      this.btnPago = false;
      this.btnCotizacion = true;
      this.verDatos();

    }else {
        this.membresia = usuario.membresiaActiva;
        this.verDatos();
    }

  }

  cotizacion(carrito){
    this.generarTicket(carrito);
  }
  generarTicket(carrito){
    const tickets = new Tickets();
    tickets.imprimirCotizacion(carrito);
    eliminarStorage();
    this.obtenerCarrito();
  }
}
