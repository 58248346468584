import { Component, OnInit } from '@angular/core';
import  CarritoCensur  from '../../../clases/carrito-censur/censur-carrito';
import { eliminarStorageCensur, getCarritoStorageCensur } from '../../../../app/functions/storage/pacienteIntegrados';
import { BancodesangreService } from '../../../services/bancodeSangre/bancodesangre.service'
import swal from 'sweetalert'
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css']
})
export class PedidosComponent implements OnInit {

  public bitacoraHeader=["PRODUCTOS", "PRECIO"];
  public productos=[];

  public carrito = {
    total: 0,
    items: []
  };

  public pedido = {
    idcensur: '',
    vendedor: '',
    estudios: [],
    sede_banco: '',
    totalCompra: 0
  }

  public pedidoLab={
    idcensur:'',
    pedido:[]
  }

  public boton = false;

  constructor(private _bancoSangre: BancodesangreService,
              private _router: Router,
              private spinner: NgxSpinnerService){}

  ngOnInit(){
    this.spinner.show();
    this.obtenerCarritoStorage();
    this.allproductosalmacen()
  }

  //Obtener los productos de censur
  obtenerProductos(){
    this._bancoSangre.obtenerProductosBS().subscribe((resp:any)=>{
        
    })
  }
  allproductosalmacen(){
    this._bancoSangre.allproductosAlmacen().subscribe((resp:any)=>{
      resp.data.forEach(element => {
        let data2 = { 
          producto:element.nombre,
          precio: element.proveedor[0]?.precioVenta,
          cantidad:0,
          idProducto:element._id
        }
        this.productos.push(data2)
      });
      this.spinner.hide();
    })
  }

  //Obtener carrito de storage
  obtenerCarritoStorage(){
    const storageCarrito = new CarritoCensur();
    this.carrito = storageCarrito.obtenerSotorageCarrito();
  }

  //Agregar item al carrito
  agregarCarrito(item: any) {
    let carrito = new CarritoCensur();
    this.carrito = carrito.agregarItem(item);
    this.validarCarrito();
  }

  //Eliminar item del carrito
  eliminar(index){
    let carrito = new CarritoCensur();
    carrito.eliminar( index );
    this.obtenerCarritoStorage();
    this.validarCarrito();
  }

  //Validacion de carrito vacio
  validarCarrito(){
    this.obtenerCarritoStorage();
    if(this.carrito.items.length == 0){
      return true;
    }else{
      return false;
    }
  }

  //Enviar la solicitud a censur
  enviarSolicitud(){
    this.spinner.show();
    this.boton = true;
    this.setPedido();
    if(this.carrito.items.length == 0){
      swal("Necesitas agregar un producto al carrito",{icon:"error"})
    }else{
      this.setPedido();
      this._bancoSangre.generarTypeCruce(this.pedidoLab).subscribe((resp:any)=>{
      });   
      this._bancoSangre.generarOrdenReceptor(this.pedido).subscribe((resp:any)=>{
        swal("Se envio la solicitud correctamente",{icon:"success"})
        this.spinner.hide();
        this.eliminarLocal();
        this._router.navigateByUrl('/dashboard') 
      })       
    }
  }

  //Llenado del pedido
  setPedido(){
    const usuario = JSON.parse(localStorage.getItem('usuario'))
    //orden pedido
    this.pedido.estudios = this.carrito.items
    this.pedido.totalCompra = this.carrito.total
    this.pedido.vendedor = usuario.nombre;
    this.pedido.sede_banco = usuario.sede
    this.pedido.idcensur = localStorage.getItem('idCensur');
    //ordenar tipe y cruce
    this.pedidoLab.idcensur = localStorage.getItem('idCensur');
    this.pedidoLab.pedido  = ['TIPE Y CRECE'];
  }

  //eliminar localStorage
  eliminarLocal(){
    localStorage.removeItem('idCensur');
    localStorage.removeItem('censur-carrito');
  }

}
