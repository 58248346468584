import { Component, OnInit } from '@angular/core';
import { BancodesangreService } from 'src/app/services/bancodeSangre/bancodesangre.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Reacciones } from './reaciones.interface';
import { buscarReceptor } from 'src/app/helpers/filterReceptor';

@Component({
  selector: 'app-reacciones-transfucionales',
  templateUrl: './reacciones-transfucionales.component.html',
  styleUrls: ['./reacciones-transfucionales.component.css']
})
export class ReaccionesTransfucionalesComponent implements OnInit {

  public reacciones: Reacciones[]=[];
  public filtropacientes = "";
  public reaccionesAux: Reacciones[]=[];
  
  constructor(
    private _bancoSangre: BancodesangreService,
    private _sppiner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this._sppiner.show();
    this.getBitacora();
  }

  getBitacora(){
    this._bancoSangre.bitacoraReaccionesTransfucionales()
    .subscribe((resp:any) => {
      if(resp.ok){
        this._sppiner.hide();
        this.agregarReacciones(resp.data)
        this.reaccionesAux = resp.data;
      }
    });
  }

  agregarReacciones(data:any){
    data.forEach(element => {
      if(element.paciente != null){
        this.reacciones.push(element)
      }
    });
  }

  typeSearchPatients(){
    this.reacciones = this.reaccionesAux;
    if( this.filtropacientes.length >= 3 ){
     this.reacciones = buscarReceptor(this.reacciones, this.filtropacientes);
    }
  }

  //TODO: AGREGAR EL PAGINADOR 
}

