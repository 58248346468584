<div class="container">
    <div class="title">
        <h1>ADJUNTAR REACCIONES DERIVADAS</h1>
    </div>
    <!-- Componente de ficha de identificaion del paciente -->
    <section>
        <ficha-info-user [pacienteInfo]='pacienteInfo'></ficha-info-user>
    </section>

    <div class="row">
        <div class="col">
            <p>
                <a style="text-decoration: none;" href="/assets/assets/contracts/censur/REACCION TRANSFUSIONAL.pdf" target="_black" [download]>Reacciones derivadas de la transfusion</a>
            </p>
        </div>
        <div class="col">
            <div class="row">
                <div class="col">
                    <p style="margin-right: 10px;"> ¿Hubo reaccion adversa despues de la transfusion? </p>
                </div>
                <div class="col">
                    <label for="switch-id" class="switch">
                        <input type="checkbox" id="switch-id" (change)="obtenerValor($event)">
                        <span></span>
                    </label>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Carga de los archivos -->
    <div class="card">
        <ngx-dropzone (change)="onSelect($event)">
            <ngx-dropzone-label>
                <!-- <span class="material-icons">
                    add
                </span> -->
                AGREGAR ARCHIVOS
            </ngx-dropzone-label>
            <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">cd
                <ngx-dropzone-label style="font-size: .5rem;">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
            </ngx-dropzone-preview>
        </ngx-dropzone>
    </div>
</div>
<div class="btn btn-primary center" (click)="guardarDocumentos()" style="margin-left: 37rem; text-transform: uppercase; margin-top: 15px; margin-bottom: 15px;">
    Guardar
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#c02137" type = "ball-scale-ripple-multiple" [fullScreen] = "true">
    <p style="color: white" > Cargando... </p>
</ngx-spinner>