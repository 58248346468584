import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jsPDF from 'jspdf';
import { ConsultaService } from 'src/app/services/consultas/consulta.service';
import { LaboratorioService } from 'src/app/services/consultasLab/laboratorio.service';

@Component({
  selector: 'app-resultados-finales',
  templateUrl: './resultados-finales.component.html',
  styleUrls: ['./resultados-finales.component.css']
})
export class ResultadosFinalesComponent implements OnInit {

  public paciente ={
    apellidoMaterno: '',
  apellidoPaterno: '',
callePaciente: '',
consultas:'' ,
contactoEmergencia1ApellidoMaterno: '',
contactoEmergencia1ApellidoPaterno: '',
contactoEmergencia1Curp: "",
contactoEmergencia1Edad: "",
contactoEmergencia1Genero: "",
contactoEmergencia1Nombre: "",
contactoEmergencia1Telefono: "",
contactoEmergencia2ApellidoMaterno: "",
contactoEmergencia2ApellidoPaterno: "",
contactoEmergencia2Curp: "",
contactoEmergencia2Edad: "",
contactoEmergencia2Genero: "",
contactoEmergencia2Nombre: "",
contactoEmergencia2Telefono: "",
correoPaciente: "",
correoRazonSocial2: "",
cpPaciente: '',
cpRazonSocial: "",
cpRazonSocial2: "",
curp: "",
edad: '',
estadoPaciente: "",
familia: [],
fechaNacimientoPaciente: "",
fechaRegistro: "",
genero: "",
membresiaActiva: '',
nombrePaciente: "",
nomenclaturaRegistro: "",
paisNacimiento: "",
paisPaciente: "",
paquetes: [],
paquetesPacientes: [],
razonSocial1: "",
razonSocial1Calle: "",
razonSocial1Estado: "",
razonSocial1Municipio: "",
razonSocial2: "",
razonSocial2Calle: "",
razonSocial2Estado: "",
razonSocial2Municipio: "",
razoncocial1RFC: "",
razoncocial2RFC: "",
status: "",
telefono: '',
_id: ''
  }
  



  public resultado =[{
    idEstudio:{
      ELEMENTOS:[{
        elementos:"",
        referencia:"",
        tipo:""
      }
      ]
    },
    obtenidos:{},
    idPaciente:[],
    idPedido:""
  }]
  
  public obtenido=[];
  public tipos = [];

public id;
  constructor(

    private _laboratorio: LaboratorioService,
    private _route: ActivatedRoute,
    private _consultaService: ConsultaService 

  ) { }

  ngOnInit(): void {

    this.id = this._route.snapshot.paramMap.get('id');
    this.obtenerResultados()
  }




  obtenerResultados(){
    // console.log(this.id)

    this.paciente=JSON.parse(localStorage.getItem('idPaciente'))
    this._laboratorio.obtenerLaboratorioEvolucion(this.paciente._id)
    .subscribe(( data ) =>{ this.setEstudio( data['data'])
   })
    
  }
 setEstudio(estudio){
 this.resultado = estudio
// iteramos los resultados
for(let item of this.resultado){
  for(let items of item.idEstudio.ELEMENTOS){
      // se hace el push al elemento 0 del arreglo 
    if(this.tipos.length == 0){
        this.tipos.push(items.tipo);  
     }else{
       // busca los elementos dentro del arreglo

       const encontrar= this.tipos.find(element => element == items.tipo)
       if(encontrar){
          this.tipos.push("")
          // se hace un push de elementos vacios
       }else{
         // un push del elemento nuevo
         this.tipos.push(items.tipo)
       }
     }   
  }
}


 for(let item in this.resultado[0].obtenidos[0]){
   
   this.obtenido.push(this.resultado[0].obtenidos[0][item])
   
 }
 
 }
 

 pdf(){
  // var canvas = document.getElementById('tablaData');
  // domtoimage.toPng(canvas).then((dataUrl)=>{
  //     let imagen= new Image();
  //     imagen.src=dataUrl;/*obtengo el screenshot*/
  //     let pdf = new jsPDF('l','mm','A4');/* creamos el pdf con jspdf, l es de landscape, mm: medidas en milímetros, y A4 el formato*/
  //     pdf.addImage( imagen, 18, 10, 250,160); /*imagen: es la captura que insertaremos en el pdf, 18: margen izquierdo, 10: margen superior, 260:ancho, 189:alto, pueden jugar con estos valores, de esta forma me quedó prolijo en A4 horizontal*/
  //     pdf.save( 'Laboratorios ' + this.paciente.nombrePaciente +' '+ this.paciente.apellidoPaterno ); /* descargamos el pdf con ese nombre.*/
  // }
  // );
  alert("Yo lo habia ponido aquí")
 }

}
