<div class="container-fluid">
    <h1 class="centerxd">ORDEN DE PRODUCTOS</h1>

    <div class="row">
        <div class="col-md-8">
            <form>
                <div class="form-group">
                    <input style="margin-top: 15px;" type="text" name="filterPost" placeholder="Buscar" class="form-control" id="buscador">
                </div>
            </form>
        </div>
    </div>

    <div style="margin-top: 15px;" class="row">
        <div class="col-md-9">
            <div class="card" style="border-radius: 30px; ">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-md-1 text_header">
                            <p style="text-align: left; margin-left: 20px;">No</p>
                        </div>
                        
                        <div class="col text_header" *ngFor="let item of bitacoraHeader">
                            <p style="text-align: center;">{{item}}</p>
                        </div>

                        <div class="col-md-2 text_header">
                            <p style="text-align: left;">ACCIONES</p>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="card-body" style="border-radius: 0px 0px 30px 30px; background-color: #ffffff;">
                <div *ngFor="let item of productos; let i = index">
                    <div class="row">
                        <div class="col-md-1">
                            <p style="text-align: left; margin-left: 10px;">{{i+1}}</p>
                        </div>
        
                        <div class="col">
                            <p style="text-align: center;">{{item.producto}}</p>
                        </div>
        
                        <div class="col">
                            <p style="text-align: center;">{{item.precio |currency}}</p>
                        </div>
        
                        <div class="col-md-2">
                            <button type="button" style="height: 30px; width: 100px; margin-top: 0px; margin-bottom: 15px;" class="btn btn-block btn-tarjeta" (click)="agregarCarrito(item)">
                                <p style="text-align: left; color: #007CC3; height: 30px; width: 100px; margin-top: -6px;">AGREGAR</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="col-md-3">
            <div class="card" style="height: 23em; border-radius: 24px;">
    
                <div class="card-title" style=" border-radius:24px 24px 0px 0px; border-bottom-style: solid;">
                    <h4 style="text-align: center;">COTIZACIÓN</h4>
                </div>
    
                <div class="card-body" style="overflow-y: auto;">
                    <div class="row" *ngFor="let pedido of carrito.items; let i=index">
                        <div class="col-md-8">
                            <p style="font-size: 11px; font-weight: 600; margin-top: 10px; text-align: left;">
                                {{pedido.cantidad}} {{pedido.producto}}: {{pedido.cantidad * pedido.precio | currency}}
                            </p>
                        </div>
    
                        <div class="col-md-2">
                            <button type="button" class="btn btn-link" style="color: red; font-size: .8rem; font-weight: bold;" (click)="eliminar(i)"> 
                                Eliminar
                            </button>
                        </div>
                    </div>
                    <div *ngIf="validarCarrito()">
                        <p style="text-align: center;">No cuentas con productos en el carrito</p>
                    </div>
                </div>

                <!-- <div class="offset-md-4">
                    <pagination-controls id="listaproductos" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div> -->
    
                <div class="card-footer" style="border-top-style: solid;">
                    <div class="row" style="border-bottom-style: solid; border-top-style: solid;">
                        <div class="col-md-12">
                            <h5 style="font-size: 12px; font-weight: 800; text-align: right; margin-top: 5px; color: black;">TOTAL A PAGAR: {{carrito.total | currency}}</h5>
                        </div>
                    </div>
                    <button type="button" [disabled]="boton" class="btn btn-block btn-tarjeta" (click)="enviarSolicitud()">
                        <p style="text-align: center; color: #007CC3; height: 10px;">ENVIAR SOLICITUD</p>
                    </button>
    
                </div>
            </div>
        </div>
    </div>

</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#c02137" type = "ball-scale-ripple-multiple" [fullScreen] = "true">
    <p style="color: white" > Cargando... </p>
</ngx-spinner>
