import { Injectable } from '@angular/core';
import {  HttpClient } from '@angular/common/http';
import { URL } from '../../config/conf';
@Injectable({
  providedIn: 'root'
})
export class ConsultaService {

  // public url = "nueva/visita";
  public url = URL;

  constructor(public _http: HttpClient) {
   }

   // consulta que nos trae todas las consultas de consulta general de acuerdo a la certificacion

   public verConsultasRecepcion(){
    let url = `${this.url}/ver/consultas`;
    return this._http.get( url);
  }

   agregarVisita(visita){
    return this._http.post(this.url, visita );
  }

  agregarConsulta( body ){


    let url = `${this.url}/consultas/general/identificacion`;



    return this._http.post(url, body);
  }

  verConsultasPendientesEnfermeria(){
    let url = `${this.url}/ver/consultas/pendietes/enfemeria`;
    return this._http.get( url );
  }


  verConsultaIdentificacion(){

    let url = `${this.url}/ver/consultas/identificacion`;

    return this._http.get(  url );

  }

  verConsultasMedico(nombre) {


    let body = {
      nombre
    }

    let url = `${this.url}/ver/consultas/medico`;
    return this._http.post( url, body );
  }

  verConsultasSocios(  sede = 'CTLA01'){
    const url = `${this.url}/ver/consultas/${sede}`;
    return this._http.get(url);
  }

  // bitacora laboratorio
  verListaLaboratorio(){
    let url =`${this.url}/pedidos/pendientes`;
    return this._http.get(url);

  }

  verPedidosLaboratorio(id){
    let url =`${this.url}/obtener/pedido/${id}`;
    return this._http.get(url);
  }

  cambiarEstadoConsulta( id, estado ){
    let url = `${this.url}/actualizar/estado/consulta/${id}`;
    return this._http.put( url, estado );

  }
  actualizarConteoConsultas(id:string, consultas) {
    let url = `${this.url}/actualizar/conteo/consultas/${id}`;
    return this._http.put( url, consultas);
  }
  agregarReceta(  receta ){
      let url = `${this.url}/agregar/receta`;
      return this._http.post(url, receta);
  }

  agregarHojaEvolucion(hoja){
    let url = this.url+"/hoja/evolucion";
    return this._http.post(url, hoja);
  }


  agregarHojaEvolucionEnConsulta( id, body ) {
    let url = `${this.url}/agregar/hoja/evolucion/consulta/${id}`;
    return this._http.put( url, body )
  }

  agregarRecetaACosnulta(id, body){
    let url = `${this.url}/agregar/receta/consulta/${id}`;
    return this._http.put( url, body );
  }

  actualizarSignosVitalesEnElPaciente(id, body) {
    let url = `${this.url}/agregar/signos/consulta/${id}`;
    return this._http.put(url, body);
  }

}
