import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL } from 'src/app/config/conf';

@Injectable({
  providedIn: 'root'
})
export class LaboratorioService {

  public url  = URL;
  

  constructor(public _http: HttpClient) {
   }

   agregarEstudio(  seveEstudio){
    let url = `${this.url}/nuevo/estudio`;
    return this._http.post(url, seveEstudio);
}

verNombreLaboratorio(nombre){
    
    let url =`${this.url}/ver/estudios`;
    return this._http.post(url, nombre);
  }

  enviarObtenidosLab(enviarObtenido){
    let url = `${this.url}/agregar/regresos/lab`;
return this._http.post(url,enviarObtenido)
  }

  obtenerLaboratorioEvolucion(id){
    let url = `${this.url}/ver/estudio/laboratorio/${id}`;
return this._http.get(url)
  }


  actualizarEstado(id,idEstudio,body){
    let url = `${this.url}/actualizar/pedido/${id}/${idEstudio}`;
    return this._http.put(url,body)
  }

  actualizarPrioridad(id, body){
    let url = `${this.url}/actualizar/prioridad/pedido/${id}`;
    return this._http.put(url,body)
  }
}
