import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ConsultaService } from 'src/app/services/consultas/consulta.service';
import { HistoriaClinicaService } from 'src/app/services/historiaClinica/historia-clinica.service';
import { WsLoginService } from 'src/app/services/sockets/ws-login.service';


@Component({
  selector: 'app-ficha-enfermeria',
  templateUrl: './ficha-enfermeria.component.html',
  styleUrls: ['./ficha-enfermeria.component.css']
})
export class FichaEnfermeriaComponent implements OnInit {

  public id: string;
  public fecha: string;

  public paciente  = {
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    estadoPaciente: '',
    fechaNacimiento: '',
    telefono: '',
    edad: 0,
    genero: '',
    curp:'',
    callePaciente:'',
    cpPaciente:'',
    paisPaciente:'',
    idMedicinaPreventiva: '',
    idAntecedentesHeredoFam: '',
    idPaciente:''
  };

 

  

  constructor(
    private _HistoriaClinicaService: HistoriaClinicaService,
    private _route: ActivatedRoute,
    public _consultaService: ConsultaService,
    public _WsLoginService: WsLoginService
  ) { }

  ngOnInit(): void {

    this.id = this._route.snapshot.paramMap.get('id');
    this.fecha = moment().format('l');
    this.obtenerConsultaPorId();

  }

  public obtenerConsultaPorId(){

    this._HistoriaClinicaService.obtenerConsultaPorElId( this.id )
    .subscribe(  (data:any) => {      
      this.paciente.nombre = data['data']['paciente']['nombrePaciente'];
      this.paciente.apellidoMaterno = data['data']['paciente']['apellidoMaterno'];
      this.paciente.apellidoPaterno = data['data']['paciente']['apellidoPaterno'];
      this.paciente.genero = data['data']['paciente']['genero'];
      this.paciente.edad = data['data']['paciente']['edad'];
      this.paciente.fechaNacimiento = data['data']['paciente']['fechaNacimientoPaciente'];
      this.paciente.paisPaciente = data['data']['paciente']['paisPaciente'];
      // this.paciente.estadoPaciente = data['data']['paciente']['estadoPaciente'];
      this.paciente.callePaciente = data['data']['paciente']['callePaciente'];
      this.paciente.curp = data['data']['paciente']['curp'];
      this.paciente.telefono = data['data']['paciente']['telefono'];
      this.paciente.cpPaciente = data['data']['paciente']['cpPaciente'];
      this.paciente.idPaciente = data['data']['paciente']['_id'];
      this.paciente.idMedicinaPreventiva = data['data']['paciente']['medicinaPreventiva'];
      this.paciente.idAntecedentesHeredoFam = data['data']['paciente']['antecedentesHeredoFamiliares'];


    });

  }


}
