import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL } from 'src/app/config/conf';


@Injectable({
  providedIn: 'root'
})
export class XrayService {

  public  url = URL;

  constructor(  private _http:HttpClient    ) {
  }


 

  getXray(){
      const url = `${this.url}/ver/pedidos/rayosX`;
      return this._http.get( url);
  }

  verPedidoXray (id){
      const url = `${this.url}/obtener/rayosX/pedido/${id}`;
      return this._http.get( url);
  }

  postPedidosXray(pedido){
      const url = `${this.url}/nuevo/pedido/rayosX`;
      return this._http.post( url, pedido );

  }

  // servicios de regresos

  enviarXrayRegreso(enviarXray){
      let url = `${this.url}/agregar/regresos/xray`;
  return this._http.post(url,enviarXray)
    }
  
    obtenerXrayRecepcionHC(id){
      let url = `${this.url}/ver/servicio/regreso/xray/${id}`;
  return this._http.get(url)
    }
}
