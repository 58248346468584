export function buscarReceptor( receptores:any , busqueda:any ) {
    const pacientesFilter = [];
    
    receptores.forEach(element => {
        let nombreCompleto = element.paciente.nombrePaciente.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, "").concat(" "+ element.paciente.apellidoPaterno.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, "")+" "+element.paciente.apellidoMaterno.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, ""));
        if(nombreCompleto.toUpperCase().includes(busqueda.trim().replace(/^\s+|\s+$|\s+(?=\s)/g, "").toUpperCase())){
            pacientesFilter.push(element);
        }
    });
    return pacientesFilter;
}