<div id="content">

    <div class="card-columns animate bounceIn">

        <a [routerLink]="module.modules.route" *ngFor="let module of modules">
            <div class="card" id="card">
                <img [src]="'assets/'+module.modules.icon" id="img2">
                <h2 id="h2">{{ module.modules.nameModule}}</h2>
            </div>
        </a>
    </div>
</div>