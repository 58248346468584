import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.eliminarPaciente();
  }

  eliminarPaciente(){
    localStorage.removeItem('paciente');
    localStorage.removeItem('carrito');
    localStorage.removeItem('idPaciente');
    localStorage.removeItem('idCensur')
    localStorage.removeItem('censur-carrito')
  }

}
