<!--FORMULARIO, AGRAGR NUEVO EN LA PARTE DE AGREGAR UN NUEVO PACIENTE, SE QUITARON LAS FALTAS DE ORTOGRAFÍA EN ESTE FORM -->


<!-- 06/03/21 SE REALIZARON LOS CAMBIOS CORRESPONDIENTES COMO: TODAS LAS LETRAS A MAYUSCULAS
Y AGREGAR CONYUGUE EN PARENTESCO -->
<!-- <div class="card" style="border-radius: 30px; margin-left: 25px;">


    <form #form='ngForm'>
         Agregar el puto BrowserAnimationsModule en el app.module.ts
        <mat-horizontal-stepper linear #stepper style="background: none;">
            <mat-step label="DATOS PERSONALES">

                <h3>
                    PERSONALES
                </h3>
                <div class="row">
                    <div class="col-md-3">

                        <mat-label> NOMBRES (S) </mat-label>

                    </div>

                    <div class="col-md-3">
                        <input matInput maxlength="50" type="text" required name="nombrePaciente" ngModel id="nombre" class="form-control">
                    </div>

                    <div class="col-md-3">

                        <label for="apellidoPaterno">  APELLIDO PATERNO </label>


                    </div>
                    <div class="col-md-3">

                        <input type="text" maxlength="70" required ngModel name="apellidoPaterno" id="apellidoPaterno" class="form-control">

                    </div>
                    <div class="col-md-3">

                        <label for="apellidoMaterno">  APELLIDO MATERNO </label>


                    </div>
                    <div class="col-md-3">

                        <input type="text" maxlength="70" required ngModel name="apellidoMaterno" id="apellidoMaterno" class="form-control">

                    </div>


                    <div class="col-md-3">

                        <label for="curp">  CURP
    
                         <br>
                          <a href="https://www.gob.mx/curp/"   target="_blank"  >    CONSULTAR CURP </a>
                        </label>


                    </div>
                    <div class="col-md-3">

                        <input type="text" maxlength="18" ngModel name="curp" id="curp" class="form-control">

                    </div>



                    <div class="col-md-3">

                        <label for="lugarOrigen">  LUGAR DE ORIGEN </label>


                    </div>
                    <div class="col-md-3">

                        <select name="lugarOrigen" ngModel id="lugarOrigen" class="form-control">
                        <option value="" selected>  SELECCIONA PAÍS </option>
                        <option [value]="pais.name"  *ngFor="let pais of paises" >  {{  pais.name }} </option>
                  </select>

                    </div>
                    <div class="col-md-3">

                        <label for="fechaNacimiento">  FECHA DE NACIMIENTO </label>


                    </div>
                    <div class="col-md-3">

                        <input type="date" #edadForm (blur)="generarEdad(edadForm)" ngModel name="fechaNacimiento" id="fechaNacimiento" class="form-control">

                    </div>


                    <div class="col-md-3">
                        <label for="edad"> EDAD</label>
                    </div>

                    <div class="col-md-3">
                        <input type="string" min="0" max="150" ngModel name="edad" id="edad" min="0" max="3" class="form-control">
                    </div>

                    <div class="col-md-3">
                        <label for="telefono"> TELÉFONO </label>
                    </div>

                    <div class="col-md-3">
                        <input type="number" maxlength="10" ngModel name="telefono" id="telefono" class="form-control">
                    </div>


                    <div class="col-md-3">
                        <label for="estadoCivil"> ESTADO CIVIL </label>
                    </div>

                    <div class="col-md-3">


                        <select name="estadoCivil" id="Paciente" class="form-control">
                            <option value="UNION LIBRE" >  UNIÓN LIBRE </option>
                            <option value="CASADO">  CASADO  </option>
                            <option value="SOLTERO">  SOLTERO  </option>
                            <option value="VIUDO">  VIUDO </option>
                        </select>

                    </div>

                    <div class="col-md-3">
                        <label for="genero"> GÉNERO </label>
                    </div>

                    <div class="col-md-3">

                        <select name="genero" required ngModel id="genero" class="form-control">
                            <option value="FEMENINO">  FEMENINO </option>
                            <option value="MASCULINO"> MASCULINO </option>
                        </select>

                    </div>

                    <div class="col-md-3">
                        <label for="correoPaciente"> CORREO </label>
                    </div>
                    <div class="col-md-9">
                        <input type="email" maxlength="100" ngModel name="correoPaciente" id="correoPaciente" class="form-control">
                    </div>

                    <div class="col-md-3">
                        <label for="tipoDeSangre"> TIPO DE SANGRE </label>
                    </div>

                    <div class="col-md-3">
                        <select name="tipoDeSangre" required ngModel id="sangre" class="form-control">
                            <option value="Tipo" selected>Seleccionar una opción</option>
                            <option value="AB+">AB+</option>
                            <option value="AB-">AB-</option>
                            <option value="A+">A+</option>
                            <option value="A-">A-</option>
                            <option value="B+">B+</option>
                            <option value="B-">B-</option>
                            <option value="O+">O+</option>
                            <option value="O-">O-</option>
                            <option value="desconoce">Desconocido</option>
                        </select>
                    </div>

                    <div class="col-md-12">
                        <h3>
                            DIRECCIÓN
                        </h3>
                    </div>

                    <div class="col-md-3">
                        <label for="calle"> CALLE </label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" ngModel name="callePaciente" id="calle" class="form-control">
                    </div>
                    <div class="col-md-3">
                        <label for="numeroInterior"> N° EXTERIOR </label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" ngModel name="numeroPaciente" id="numeroInterior" class="form-control">
                    </div>
                    <div class="col-md-3">
                        <label for="codigoPostal"> CÓDIGO POSTAL </label>
                    </div>

                    <div class="col-md-3">
                        <input maxlength="5" type="text" ngModel name="codigoPostal" id="codigoPostal" class="form-control">
                    </div>
                    <div class="col-md-3">
                        <label for="paisActual"> PAÍS </label>
                    </div>

                    <div class="col-md-3">
                        <input ngModel type="text" name="paisActual" id="paisActual" class="form-control">
                    </div>
                    <div class="col-md-3">
                        <label for="estado"> ESTADO </label>
                    </div>

                    <div class="col-md-3">
                        <select name="estadoPaciente" class="form-control" id="" [(ngModel)]="estado" (change)="buscarMunicipios()">
                            <option [value]="estado" *ngFor="let estado of estados"    >   {{ estado }}  </option>
                        </select>
                    </div>








                    <div class="col-md-3">
                        <label for="localidad"> MUNICIPIO</label>
                    </div>

                    <div class="col-md-3">
                        <select name="municipio" id="municipio" class="form-control" ngModel>
                         <option value=""  *ngFor="let item of municipios" > {{ item  }} </option>
                       </select>
                    </div>



                    <div class="col-md-3">
                        <label for="referencia1"> REFERENCIA 1 </label>
                    </div>

                    <div class="col-md-3">
                        <input ngModel type="text" name="referencia1" id="referencia1" class="form-control">
                    </div>

                    <div class="col-md-3">
                        <label for="referencia2"> REFERENCIA 2 </label>
                    </div>

                    <div class="col-md-3">
                        <input ngModel type="text" name="referencia2" id="referencia2" class="form-control">
                    </div>
                </div>
            </mat-step>
            <mat-step label="DATOS FISCALES">
                <div class="row">

                    <div class="col-md-12">
                        <h3>
                            DATOS FISCALES
                        </h3>
                    </div>

                </div>



                <div class="row">

                    <div class="col-md-2 offset-1">
                        <label for="razonSocial">  RAZÓN SOCIAL </label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" name="razonSocial" ngModel id="razonSocial" class="form-control">
                    </div>

                    <div class="col-md-2">
                        <label for="rfcRazonSocial">  RFC </label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" maxlength="13" name="razoncocial1RFC" ngModel id="razoncocial1RFC" class="form-control">
                    </div>


                    <div class="col-md-2 offset-1">
                        <label for="cpRazonSocial"> C.P </label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" name="cpRazonSocial" ngModel id="cpRazonSocial" class="form-control">
                    </div>

                    <div class="col-md-2">
                        <label for="estadoRazonSocial">  ESTADO </label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" name="estadoRazonSocial" ngModel id="estadoRazonSocial" class="form-control">
                    </div>


                    <div class="col-md-2 offset-1">
                        <label for="municipioRazonSocial"> MUNICIPIO </label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" name="municipioRazonSocial" ngModel id="municipioRazonSocial" class="form-control">
                    </div>

                    <div class="col-md-2">
                        <label for="localidadRazonSocial"> CALLE </label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" name="razonSocial1Calle" ngModel id="razonSocial1Calle" class="form-control">
                    </div>


                    <div class="col-md-2 offset-1">
                        <label for="correoRazonSocial"> CORREO </label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" name="correoRazonSocial" ngModel id="correoRazonSocial" class="form-control">
                    </div>

                    <div class="col-md-2">
                        <label for="calleRazonSocial">  CALLE </label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" name="calleRazonSocial" ngModel id="calleRazonSocial" class="form-control">
                    </div>




                </div>
                <hr>

                <div class="row">


                    <div class="col-md-2 offset-1">
                        <label for="razonSocial2">  RAZÓN SOCIAL</label>
                    </div>

                    <div class="col-md-3">
                        <input ngModel type="text" name="razonSocial2" id="razonSocial2" class="form-control">
                    </div>

                    <div class="col-md-2">
                        <label for="rfcRazonSocial2">  RFC </label>
                    </div>

                    <div class="col-md-3">
                        <input ngModel maxlength="13" type="text" name="razoncocial2RFC" id="razoncocial2RFC" class="form-control">
                    </div>


                    <div class="col-md-2 offset-1">
                        <label for="cpRazonSocial2"> C.P </label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" ngModel name="cpRazonSocial2" id="cpRazonSocial2" class="form-control">
                    </div>

                    <div class="col-md-2">
                        <label for="estadoRazonSocial2">  ESTADO</label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" name="razonSocial2Estado" ngModel id="razonSocial2Estado" class="form-control">
                    </div>


                    <div class="col-md-2 offset-1">
                        <label for="municipioRazonSocial2"> MUNICIPIO </label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" name="municipioRazonSocial2" ngModel id="municipioRazonSocial2" class="form-control">
                    </div>

                    <div class="col-md-2">
                        <label for="localidadRazonSocial2">  CALLE </label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" name="calleRazonSocial2" ngModel id="calleRazonSocial2" class="form-control">
                    </div>


                    <div class="col-md-2 offset-1">
                        <label for="correoRazonSocial2"> CORREO </label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" name="correoRazonSocial2" ngModel id="correoRazonSocial2" class="form-control">
                    </div>

                    <div class="col-md-2">
                        <label for="calleRazonSocial2">  CALLE</label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" name="calleRazonSocial2" ngModel id="calleRazonSocial2" class="form-control">
                    </div>

                </div>
            </mat-step>


            <mat-step label="CONTACTO">
                <div class="row">
                    <div class="col-md-12">
                        <h3>
                            CONTACTO DE EMERGENCIA 1
                        </h3>
                    </div>
                </div>


                <div class="row">


                    <div class="col-md-2 offset-1">
                        <label for="nombreContactoDeEmergencia"> NOMBRE (S) </label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" name="contactoEmergencia1Nombre" ngModel id="contactoEmergencia1Nombre" class="form-control">
                    </div>

                    <div class="col-md-2">
                        <label for="apellidoPaternoContactoDeEmergencia"> APELLIDO PATERNO </label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" name="contactoEmergencia1ApellidoPaterno" ngModel id="contactoEmergencia1ApellidoPaterno" class="form-control">
                    </div>

                    <div class="col-md-2 offset-1">
                        <label for="apellidoMaternoContactoDeEmergencia"> APELLIDO MATERNO</label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" name="contactoEmergencia1ApellidoMaterno" ngModel id="contactoEmergencia1ApellidoMaterno" class="form-control">
                    </div>

                    <div class="col-md-2">
                        <label for="contactoEmergencia1Parentesco">  PARENTESCO  </label>
                    </div>


                    <div class="col-md-3">
                        <select name="contactoEmergencia1Parentesco" class="form-control" ngModel id="contactoEmergencia1Parentesco">
                            <option  value="PADRE">  PADRES   </option>
                            <option value="ABUELOS">   ABUELOS </option>
                            <option value="HIJOS"> HIJOS </option>
                            <option value="HERMANOS">  HERMANOS </option>
                            <option value="PRIMOS">   PRIMOS </option>
                            <option value="CONYUGUE">  CÓNYUGUE </option>
                            <option value="OTROS">  OTROS </option>
                        </select>
                    </div>


                    <div class="col-md-2 offset-1">
                        <label for="edadContactoDeemergencia">  EDAD  </label>
                    </div>

                    <div class="col-md-3">
                        <input type="number" name="contactoEmergencia1Edad" ngModel id="contactoEmergencia1Edad" class="form-control">
                    </div>

                    <div class="col-md-2">
                        <label for="telefonoContactoDeEmergencia">  TELÉFONO </label>
                    </div>

                    <div class="col-md-3">
                        <input type="tel" name="contactoEmergencia1Telefono" ngModel id="contactoEmergencia1Telefono" class="form-control">
                    </div>



                    <div class="col-md-2 offset-1">
                        <label for="estadoCivilContactoDeEmergencia">  ESTADO CIVIL  </label>
                    </div>

                    <div class="col-md-3">

                        <select name="contactoEmergencia1EstadoCivil" id="contactoEmergencia1EstadoCivil" class="form-control">
                            <option value="UNION LIBRE" >  UNIÓN LIBRE </option>
                        <option value="CASADO">  CASADO  </option>
                        <option value="SOLTERO">  SOLTERO  </option>
                        <option value="VIUDO">  VIUDO  </option>
                    </select>

                    </div>

                    <div class="col-md-2">
                        <label for="generoContactoDeEmergencia">  GÉNERO </label>
                    </div>

                    <div class="col-md-3">

                        <select name="contactoEmergencia1Genero" ngModel id="contactoEmergencia1Genero" class="form-control">
                          <option value="FEMENINO">  FEMENINO</option>
                          <option value="MASCULINO"> MASCULINO </option>
                      </select>
                    </div>



                </div>

                <div class="row">
                    <div class="col-md-12">
                        <h3>
                            CONTACTO DE EMERGENCIA 2
                        </h3>
                    </div>
                </div>

                <div class="row">


                    <div class="col-md-2 offset-1">
                        <label for="nombreContactoDeEmergencia2"> NOMBRE (S) </label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" name="contactoEmergencia2Nombre" ngModel id="contactoEmergencia2Nombre" class="form-control">
                    </div>

                    <div class="col-md-2">
                        <label for="contactoEmergencia2ApellidoPaterno"> APELLIDO PATERNO </label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" name="contactoEmergencia2ApellidoPaterno" ngModel id="contactoEmergencia2ApellidoPaterno" class="form-control">
                    </div>

                    <div class="col-md-2 offset-1">
                        <label for="contactoEmergencia2ApellidoMaterno"> APELLIDO MATERNO</label>
                    </div>

                    <div class="col-md-3">
                        <input type="text" name="contactoEmergencia2ApellidoMaterno" ngModel id="contactoEmergencia2ApellidoMaterno" class="form-control">
                    </div>


                    <div class="col-md-2">
                        <label for="contactoEmergencia2Parentesco">  PARENTESCO  </label>
                    </div>


                    <div class="col-md-3">
                        <select name="contactoEmergencia2Parentesco" class="form-control" ngModel id="contactoEmergencia2Parentesco">
                            <option  value="PADRE">  PADRES   </option>
                            <option value="ABUELOS">   ABUELOS </option>
                            <option value="HIJOS"> HIJOS </option>
                            <option value="HERMANOS">  HERMANOS </option>
                            <option value="PRIMOS">   PRIMOS </option>
                            <option value="CONYUGUE">  CÓNYUGUE </option>
                            <option value="OTROS">  OTROS </option>
                        </select>
                    </div>



                    <div class="col-md-2 offset-1">
                        <label for="edadContactoDeemergencia2">  EDAD </label>
                    </div>

                    <div class="col-md-3">
                        <input type="number" name="contactoEmergencia2Edad" ngModel id="contactoEmergencia2Edad" class="form-control">
                    </div>

                    <div class="col-md-2">
                        <label for="contactoEmergencia2Telefono">  TELÉFONO</label>
                    </div>

                    <div class="col-md-3">
                        <input type="tel" name="contactoEmergencia2Telefono" ngModel id="contactoEmergencia2Telefono" class="form-control">
                    </div>



                    <div class="col-md-2 offset-1">
                        <label for="contactoEmergencia2EstadoCivil">  ESTADO CIVIL  </label>
                    </div>


                    <div class="col-md-3">

                        <select name="contactoEmergencia2EstadoCivil" id="contactoEmergencia2EstadoCivil" class="form-control">
                            <option value="union libre" > UNIÓN LIBRE </option>
    
                            <option value="CASADO">  CASADO  </option>
                        <option value="SOLTERO">  SOLTERO </option>
                        <option value="VIUDO">  VIUDO  </option>
                    </select>

                    </div>

                    <div class="col-md-2">
                        <label for="contactoEmergencia2Genero">  GÉNERO </label>
                    </div>

                    <div class="col-md-3">

                        <select name="contactoEmergencia2Genero" ngModel id="contactoEmergencia2Genero" class="form-control">
                            <option value="FEMENINO">  FEMENINO </option>
                            <option value="MASCULINO"> MASCULINO </option>
                          </select>
                    </div>

                </div>
                <br>
                 <div class="row">
                    <div class="col-md-12">
                        <h3 style="text-transform: uppercase;">
                            Documentos Adjuntos
                        </h3>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h5 style="color: blue;">concentimiento_pura_prueba.pdf</h5>
                        </div>
                        <div class="col">
                            <h5 style="color: blue;">concentimiento_pura_prueba_prueba_dos.pdf</h5>
                        </div>
                    </div>
                </div>
                <br>
                <div class="center">
                    <button mat-button matStepperNext class="principalButton" (click)="enviar(form)">   Agregar </button>
                </div>
                <br>
            </mat-step>

        </mat-horizontal-stepper>
    </form>
    <br>
</div> -->

<div class="card">
    <form 
     [formGroup]='registroForm' 
     #registroNgform='ngForm'>
     <mat-horizontal-stepper linear #stepper>
         <mat-step label="DATOS GENERALES">
             <div class="container">
                 <div class="row">
                     <div class="col-md-4 p-2">
                         <label for="nombre">NOMBRE (S)</label>
                         <input type="text" class="form-control" name="nombrePaciente" id="validationCustom01"  [formControlName]="'nombrePaciente'">
                         <div class="invalid-feedback" *ngIf="registroForm.get('nombrePaciente').hasError('required')">
                           EL nombre es requerido.
                         </div>
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="apellido 1">APELLIDO PATERNO</label>
                         <input type="text" class="form-control" id="validationCustom01" [formControlName]="'apellidoPaterno'" required>
                         <div class="invalid-feedback">
                           EL Apellido paterno es requerido.
                         </div>
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="apellido 2">APELLIDO MATERNO</label>
                         <input type="text" class="form-control" id="validationCustom01" [formControlName]="'apellidoMaterno'">
                     </div>
                 </div>
                 <div class="row">
                     <div class="col-md-4 p-2">
                         <label for="nombre">FECHA DE NACIMIENTO</label>
                         <input type="date" class="form-control" id="validationCustom01" [formControlName]="'fechaNacimientoPaciente'" (blur)="edad($event.target.value)">
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="nombre">EDAD</label>
                         <div class="input-group">
 
                             <input type="text" class="form-control" id="validationCustom01" [formControlName]="'edad'"  ([ngModel]) = "registroForm.get('edad')">
                             <div class="input-group-prepend">
                                 <span class="input-group-text" id="inputGroupPrepend3">AÑOS</span>
                               </div>
                         </div>
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="apellido 1">GÉNERO</label>
                         <select name="genero" required id="genero" class="form-control custom-select" (blur)="genero($event.target.value)">
                             <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                             <option value="FEMENINO">  FEMENINO </option>
                             <option value="MASCULINO"> MASCULINO </option>
                         </select>
                     </div>
                 </div>
                 <div class="row">
                     <div class="col-md-4 p-2">
                         <label for="apellido 2">LUGAR DE ORIGEN</label>
                         <input type="text" class="form-control" id="validationCustom01"  [formControlName]="'paisNacimiento'">
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="apellido 1">ESTADO DE ORIGEN</label>
                         <select name="genero" required  id="genero" class="form-control custom-select" (blur)="estado($event.target.value)">
                             <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                             <option value="Aguascalientes">AGUASCALIENTES</option>
                             <option value="Baja California">BAJA CALIFORNIA</option>
                             <option value="Baja California Sur">BAJA CALIFORNIA SUR</option>
                             <option value="Campeche">CAMPECHE</option>
                             <option value="Chiapas">CHIAPAS</option>
                             <option value="Chihuahua">CHIHUAHUA</option>
                             <option value="Ciudad de Mexico">CDMX</option>
                             <option value="Coahuila de Zaragoza">COAHUILA</option>
                             <option value="Colima">COLIMA</option>
                             <option value="Durango">DURANGO</option>
                             <option value="Mexico">ESTADO DE MÉXICO</option>
                             <option value="Guanajuato">GUANAJUATO</option>
                             <option value="Guerrero">GUERRERO</option>
                             <option value="Hidalgo">HIDALGO</option>
                             <option value="Jalisco">JALISCO</option>
                             <option value="Michoacan de Ocampo">MICHOACÁN</option>
                             <option value="Morelos">MORELOS</option>
                             <option value="Nayarit">NAYARIT</option>
                             <option value="Nuevo Leon">NUEVO LÉON</option>
                             <option value="Oaxaca">OAXACA</option>
                             <option value="Puebla">PUEBLA</option>
                             <option value="Queretaro">QUERÉTARO</option>
                             <option value="Quintana Roo">QUINTANA ROO</option>
                             <option value="San Luis Potosi">SAN LUIS POTOSÍ</option>
                             <option value="Sinaloa">SINALOA</option>
                             <option value="Sonora">SONORA</option>
                             <option value="Tabasco">TABASCO</option>
                             <option value="Tamaulipas">TAMAULIPAS</option>
                             <option value="Tlaxcala">TLAXCALA</option>
                             <option value="Veracruz de Ignacio de la Llave">VERACRUZ</option>
                             <option value="Yucatan">YUCATÁN</option>
                             <option value="Zacatecas">ZACATECAS</option>
                         </select>
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="apellido 2">CURP</label>
                         <input type="text" class="form-control" id="validationCustom01" [formControlName]="'curp'"  ([ngModel]) = "registroForm.get('curp')">
                     </div>
                 </div>
                 <div class="row">
                     <div class="col-md-4 p-2">
                         <label for="apellido 2">TELÉFONO</label>
                         <input type="number" class="form-control" id="validationCustom01" [formControlName]="'telefono'">
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="apellido 1">ESTADO CIVIL</label>
                         <select name="genero" required  id="genero" class="form-control custom-select" (blur)="estadoCivil($event.target.value)">
                             <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                                 <ng-container *ngIf="registroForm.get('genero').value == 'MASCULINO'">
                                     <option value="SOLTERO">SOLTERO</option>
                                     <option value="CASADO">CASADO</option>
                                     <option value="DIVORCIADO">DIVORCIADO</option>
                                     <option value="SEPARACIÓN EN PROCESO JUDICIAL">SEPARACIÓN EN PROCESO JUDICIAL</option>
                                     <option value="VIUDO">VIUDO</option>
                                     <option value="CONCUBINATO">CONCUBINATO</option>
                                     <option value="UNION LIBRE" >UNIÓN LIBRE</option>
                                 </ng-container>
                                 <ng-container *ngIf="registroForm.get('genero').value == 'FEMENINO'">   
                                         <option value="SOLTERO">SOLTERA</option>
                                         <option value="CASADO">CASADA</option>
                                         <option value="DIVORCIADO">DIVORCIADA</option>
                                         <option value="SEPARACIÓN EN PROCESO JUDICIAL">SEPARACIÓN EN PROCESO JUDICIAL</option>
                                         <option value="VIUDO">VIUDA</option>
                                         <option value="CONCUBINATO">CONCUBINATO</option>
                                         <option value="UNION LIBRE" >UNIÓN LIBRE</option>
                                 </ng-container>
                         </select>
                     </div>
                     <div class="col-md-4 p-2">
                        <label for="apellido 1">TIPO DE SANGRE</label>
                        <select name="tipoDeSangre" class="form-control" [formControlName]="'tipoDeSangre'">
                            <option value="Tipo" selected>Seleccionar una opción</option>
                            <option value="AB+">AB+</option>
                            <option value="AB-">AB-</option>
                            <option value="A+">A+</option>
                            <option value="A-">A-</option>
                            <option value="B+">B+</option>
                            <option value="B-">B-</option>
                            <option value="O+">O+</option>
                            <option value="O-">O-</option>
                            <option value="desconoce">Desconocido</option>
                        </select>
                     </div>
                 </div>
                 <div class="row">
                     <div class="col-md-12 p-2">
                         <label for="apellido 2">CORREO</label>
                         <input type="text" class="form-control" id="validationCustom01" [formControlName]="'correoPaciente'">
                     </div>
                 </div>
             </div>
         </mat-step>
         <mat-step label="DIRECCIÓN PERSONAL">
             <div class="container">
                 <div class="row">
                     <div class="col-md-4 p-2">
                         <label for="nombre">CALLE</label>
                         <input type="text" class="form-control" id="validationCustom01" [formControlName]="'callePaciente'">
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="apellido 1">NÚMERO INTERIOR</label>
                         <input type="text" class="form-control" id="validationCustom01" [formControlName]="'numeroIntPaciente'">
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="apellido 2">NÚMERO EXTERIOR</label>
                         <input type="text" class="form-control" id="validationCustom01" [formControlName]="'numeroPaciente'">
                     </div>
                 </div>
                 <div class="row">
                     <div class="col-md-4 p-2">
                         <label for="nombre">PAÍS</label>
                         <select name="paisActual" id="paisActual" class="form-control custom-select" (blur)="pais($event.target.value)">
                             <option value="AF">Afganistán</option>
                             <option value="AL">Albania</option>
                             <option value="DE">Alemania</option>
                             <option value="AD">Andorra</option>
                             <option value="AO">Angola</option>
                             <option value="AI">Anguilla</option>
                             <option value="AQ">Antártida</option>
                             <option value="AG">Antigua y Barbuda</option>
                             <option value="AN">Antillas Holandesas</option>
                             <option value="SA">Arabia Saudí</option>
                             <option value="DZ">Argelia</option>
                             <option value="AR">Argentina</option>
                             <option value="AM">Armenia</option>
                             <option value="AW">Aruba</option>
                             <option value="AU">Australia</option>
                             <option value="AT">Austria</option>
                             <option value="AZ">Azerbaiyán</option>
                             <option value="BS">Bahamas</option>
                             <option value="BH">Bahrein</option>
                             <option value="BD">Bangladesh</option>
                             <option value="BB">Barbados</option>
                             <option value="BE">Bélgica</option>
                             <option value="BZ">Belice</option>
                             <option value="BJ">Benin</option>
                             <option value="BM">Bermudas</option>
                             <option value="BY">Bielorrusia</option>
                             <option value="MM">Birmania</option>
                             <option value="BO">Bolivia</option>
                             <option value="BA">Bosnia y Herzegovina</option>
                             <option value="BW">Botswana</option>
                             <option value="BR">Brasil</option>
                             <option value="BN">Brunei</option>
                             <option value="BG">Bulgaria</option>
                             <option value="BF">Burkina Faso</option>
                             <option value="BI">Burundi</option>
                             <option value="BT">Bután</option>
                             <option value="CV">Cabo Verde</option>
                             <option value="KH">Camboya</option>
                             <option value="CM">Camerún</option>
                             <option value="CA">Canadá</option>
                             <option value="TD">Chad</option>
                             <option value="CL">Chile</option>
                             <option value="CN">China</option>
                             <option value="CY">Chipre</option>
                             <option value="VA">Ciudad del Vaticano (Santa Sede)</option>
                             <option value="CO">Colombia</option>
                             <option value="KM">Comores</option>
                             <option value="CG">Congo</option>
                             <option value="CD">Congo, República Democrática del</option>
                             <option value="KR">Corea</option>
                             <option value="KP">Corea del Norte</option>
                             <option value="CI">Costa de Marfíl</option>
                             <option value="CR">Costa Rica</option>
                             <option value="HR">Croacia (Hrvatska)</option>
                             <option value="CU">Cuba</option>
                             <option value="DK">Dinamarca</option>
                             <option value="DJ">Djibouti</option>
                             <option value="DM">Dominica</option>
                             <option value="EC">Ecuador</option>
                             <option value="EG">Egipto</option>
                             <option value="SV">El Salvador</option>
                             <option value="AE">Emiratos Árabes Unidos</option>
                             <option value="ER">Eritrea</option>
                             <option value="SI">Eslovenia</option>
                             <option value="ES">España</option>
                             <option value="US">Estados Unidos</option>
                             <option value="EE">Estonia</option>
                             <option value="ET">Etiopía</option>
                             <option value="FJ">Fiji</option>
                             <option value="PH">Filipinas</option>
                             <option value="FI">Finlandia</option>
                             <option value="FR">Francia</option>
                             <option value="GA">Gabón</option>
                             <option value="GM">Gambia</option>
                             <option value="GE">Georgia</option>
                             <option value="GH">Ghana</option>
                             <option value="GI">Gibraltar</option>
                             <option value="GD">Granada</option>
                             <option value="GR">Grecia</option>
                             <option value="GL">Groenlandia</option>
                             <option value="GP">Guadalupe</option>
                             <option value="GU">Guam</option>
                             <option value="GT">Guatemala</option>
                             <option value="GY">Guayana</option>
                             <option value="GF">Guayana Francesa</option>
                             <option value="GN">Guinea</option>
                             <option value="GQ">Guinea Ecuatorial</option>
                             <option value="GW">Guinea-Bissau</option>
                             <option value="HT">Haití</option>
                             <option value="HN">Honduras</option>
                             <option value="HU">Hungría</option>
                             <option value="IN">India</option>
                             <option value="ID">Indonesia</option>
                             <option value="IQ">Irak</option>
                             <option value="IR">Irán</option>
                             <option value="IE">Irlanda</option>
                             <option value="BV">Isla Bouvet</option>
                             <option value="CX">Isla de Christmas</option>
                             <option value="IS">Islandia</option>
                             <option value="KY">Islas Caimán</option>
                             <option value="CK">Islas Cook</option>
                             <option value="CC">Islas de Cocos o Keeling</option>
                             <option value="FO">Islas Faroe</option>
                             <option value="HM">Islas Heard y McDonald</option>
                             <option value="FK">Islas Malvinas</option>
                             <option value="MP">Islas Marianas del Norte</option>
                             <option value="MH">Islas Marshall</option>
                             <option value="UM">Islas menores de Estados Unidos</option>
                             <option value="PW">Islas Palau</option>
                             <option value="SB">Islas Salomón</option>
                             <option value="SJ">Islas Svalbard y Jan Mayen</option>
                             <option value="TK">Islas Tokelau</option>
                             <option value="TC">Islas Turks y Caicos</option>
                             <option value="VI">Islas Vírgenes (EEUU)</option>
                             <option value="VG">Islas Vírgenes (Reino Unido)</option>
                             <option value="WF">Islas Wallis y Futuna</option>
                             <option value="IL">Israel</option>
                             <option value="IT">Italia</option>
                             <option value="JM">Jamaica</option>
                             <option value="JP">Japón</option>
                             <option value="JO">Jordania</option>
                             <option value="KZ">Kazajistán</option>
                             <option value="KE">Kenia</option>
                             <option value="KG">Kirguizistán</option>
                             <option value="KI">Kiribati</option>
                             <option value="KW">Kuwait</option>
                             <option value="LA">Laos</option>
                             <option value="LS">Lesotho</option>
                             <option value="LV">Letonia</option>
                             <option value="LB">Líbano</option>
                             <option value="LR">Liberia</option>
                             <option value="LY">Libia</option>
                             <option value="LI">Liechtenstein</option>
                             <option value="LT">Lituania</option>
                             <option value="LU">Luxemburgo</option>
                             <option value="MK">Macedonia, Ex-República Yugoslava de</option>
                             <option value="MG">Madagascar</option>
                             <option value="MY">Malasia</option>
                             <option value="MW">Malawi</option>
                             <option value="MV">Maldivas</option>
                             <option value="ML">Malí</option>
                             <option value="MT">Malta</option>
                             <option value="MA">Marruecos</option>
                             <option value="MQ">Martinica</option>
                             <option value="MU">Mauricio</option>
                             <option value="MR">Mauritania</option>
                             <option value="YT">Mayotte</option>
                             <option selected value="MX">México</option>
                             <option value="FM">Micronesia</option>
                             <option value="MD">Moldavia</option>
                             <option value="MC">Mónaco</option>
                             <option value="MN">Mongolia</option>
                             <option value="MS">Montserrat</option>
                             <option value="MZ">Mozambique</option>
                             <option value="NA">Namibia</option>
                             <option value="NR">Nauru</option>
                             <option value="NP">Nepal</option>
                             <option value="NI">Nicaragua</option>
                             <option value="NE">Níger</option>
                             <option value="NG">Nigeria</option>
                             <option value="NU">Niue</option>
                             <option value="NF">Norfolk</option>
                             <option value="NO">Noruega</option>
                             <option value="NC">Nueva Caledonia</option>
                             <option value="NZ">Nueva Zelanda</option>
                             <option value="OM">Omán</option>
                             <option value="NL">Países Bajos</option>
                             <option value="PA">Panamá</option>
                             <option value="PG">Papúa Nueva Guinea</option>
                             <option value="PK">Paquistán</option>
                             <option value="PY">Paraguay</option>
                             <option value="PE">Perú</option>
                             <option value="PN">Pitcairn</option>
                             <option value="PF">Polinesia Francesa</option>
                             <option value="PL">Polonia</option>
                             <option value="PT">Portugal</option>
                             <option value="PR">Puerto Rico</option>
                             <option value="QA">Qatar</option>
                             <option value="UK">Reino Unido</option>
                             <option value="CF">República Centroafricana</option>
                             <option value="CZ">República Checa</option>
                             <option value="ZA">República de Sudáfrica</option>
                             <option value="DO">República Dominicana</option>
                             <option value="SK">República Eslovaca</option>
                             <option value="RE">Reunión</option>
                             <option value="RW">Ruanda</option>
                             <option value="RO">Rumania</option>
                             <option value="RU">Rusia</option>
                             <option value="EH">Sahara Occidental</option>
                             <option value="KN">Saint Kitts y Nevis</option>
                             <option value="WS">Samoa</option>
                             <option value="AS">Samoa Americana</option>
                             <option value="SM">San Marino</option>
                             <option value="VC">San Vicente y Granadinas</option>
                             <option value="SH">Santa Helena</option>
                             <option value="LC">Santa Lucía</option>
                             <option value="ST">Santo Tomé y Príncipe</option>
                             <option value="SN">Senegal</option>
                             <option value="SC">Seychelles</option>
                             <option value="SL">Sierra Leona</option>
                             <option value="SG">Singapur</option>
                             <option value="SY">Siria</option>
                             <option value="SO">Somalia</option>
                             <option value="LK">Sri Lanka</option>
                             <option value="PM">St Pierre y Miquelon</option>
                             <option value="SZ">Suazilandia</option>
                             <option value="SD">Sudán</option>
                             <option value="SE">Suecia</option>
                             <option value="CH">Suiza</option>
                             <option value="SR">Surinam</option>
                             <option value="TH">Tailandia</option>
                             <option value="TW">Taiwán</option>
                             <option value="TZ">Tanzania</option>
                             <option value="TJ">Tayikistán</option>
                             <option value="TF">Territorios franceses del Sur</option>
                             <option value="TP">Timor Oriental</option>
                             <option value="TG">Togo</option>
                             <option value="TO">Tonga</option>
                             <option value="TT">Trinidad y Tobago</option>
                             <option value="TN">Túnez</option>
                             <option value="TM">Turkmenistán</option>
                             <option value="TR">Turquía</option>
                             <option value="TV">Tuvalu</option>
                             <option value="UA">Ucrania</option>
                             <option value="UG">Uganda</option>
                             <option value="UY">Uruguay</option>
                             <option value="UZ">Uzbekistán</option>
                             <option value="VU">Vanuatu</option>
                             <option value="VE">Venezuela</option>
                             <option value="VN">Vietnam</option>
                             <option value="YE">Yemen</option>
                             <option value="YU">Yugoslavia</option>
                             <option value="ZM">Zambia</option>
                             <option value="ZW">Zimbabue</option>
                         </select>
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="apellido 1">CÓDIGO POSTAL</label>
                         <input type="text" class="form-control" id="validationCustom01" [formControlName]="'cpPaciente'" ([ngModel]) = "registroForm.get('cpPaciente')" (blur)="cogidoPostal($event.target.value,false)">
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="apellido 2">ESTADO</label>
                         <ng-container *ngIf="registroForm.get('cpPaciente').value != '' && estadocp != 'false'" >
                             <select name="estado" id="estado" class="form-control custom-select">
                                 <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                                 <option>{{estadocp}}</option>
                             </select>
                         </ng-container>
                         <ng-container *ngIf="registroForm.get('cpPaciente').value == ''">
                             <select name="estado" id="estado" class="form-control custom-select" (blur)="cogidoPostal(false, $event.target.value)">
                                 <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                                 <option value="Aguascalientes">AGUASCALIENTES</option>
                                 <option value="Baja California">BAJA CALIFORNIA</option>
                                 <option value="Baja California Sur">BAJA CALIFORNIA SUR</option>
                                 <option value="Campeche">CAMPECHE</option>
                                 <option value="Chiapas">CHIAPAS</option>
                                 <option value="Chihuahua">CHIHUAHUA</option>
                                 <option value="Ciudad de Mexico">CDMX</option>
                                 <option value="Coahuila de Zaragoza">COAHUILA</option>
                                 <option value="Colima">COLIMA</option>
                                 <option value="Durango">DURANGO</option>
                                 <option value="Mexico">ESTADO DE MÉXICO</option>
                                 <option value="Guanajuato">GUANAJUATO</option>
                                 <option value="Guerrero">GUERRERO</option>
                                 <option value="Hidalgo">HIDALGO</option>
                                 <option value="Jalisco">JALISCO</option>
                                 <option value="Michoacan de Ocampo">MICHOACÁN</option>
                                 <option value="Morelos">MORELOS</option>
                                 <option value="Nayarit">NAYARIT</option>
                                 <option value="Nuevo Leon">NUEVO LÉON</option>
                                 <option value="Oaxaca">OAXACA</option>
                                 <option value="Puebla">PUEBLA</option>
                                 <option value="Queretaro">QUERÉTARO</option>
                                 <option value="Quintana Roo">QUINTANA ROO</option>
                                 <option value="San Luis Potosi">SAN LUIS POTOSÍ</option>
                                 <option value="Sinaloa">SINALOA</option>
                                 <option value="Sonora">SONORA</option>
                                 <option value="Tabasco">TABASCO</option>
                                 <option value="Tamaulipas">TAMAULIPAS</option>
                                 <option value="Tlaxcala">TLAXCALA</option>
                                 <option value="Veracruz de Ignacio de la Llave">VERACRUZ</option>
                                 <option value="Yucatan">YUCATÁN</option>
                                 <option value="Zacatecas">ZACATECAS</option>
                             </select>
                         </ng-container>                       
                     </div>
                 </div>
                 <div class="row">
                     <div class="col-md-4 p-2">
                         <label for="apellido 2">MUNICIPIO</label>
                         <ng-container *ngIf="registroForm.get('cpPaciente').value != '' && estadocp != 'false'">
                             <select name="estado" id="estado" class="form-control custom-select" (blur)="municipio($event.target.value)">
                                 <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                                 <option *ngFor="let item of municipios; index as i">{{item}}</option>
                             </select>
                         </ng-container>
                         <ng-container *ngIf="registroForm.get('cpPaciente').value == ''">
                             <select name="estado" id="estado" class="form-control custom-select" (blur)="municipio2($event.target.value)">
                                 <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                                 <option *ngFor="let item of municipios; index as i">{{item}}</option>
                             </select>
                         </ng-container>
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="nombre">CIUDAD</label>
                         <ng-container *ngIf="registroForm.get('cpPaciente').value != '' && estadocp != 'false'">
                             <select name="estado" id="estado" class="form-control custom-select" (blur)="ciudad($event.target.value)">
                                 <option value="" selected>SELECCIONA UNA OPCIÓN</option> 
                                 <option *ngFor="let item of asentamiento; index as i">{{item}}</option>
                             </select>
                         </ng-container>
                         <ng-container *ngIf="registroForm.get('cpPaciente').value == ''">
                             <select name="estado" id="estado" class="form-control custom-select" (blur)="ciudad2($event.target.value)">
                                 <option value="" selected>SELECCIONA UNA OPCIÓN</option> 
                                 <option *ngFor="let item of asentamiento; index as i">{{item}}</option>
                             </select>
                         </ng-container>
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="apellido 1">REFERENCIA</label>
                         <input type="text" class="form-control" id="validationCustom01" [formControlName]="'referenciaPaciente'">
                     </div>
                 </div>
             </div>
         </mat-step>
         <mat-step label="DATOS FISCALES">
             <div class="container">
                 <div class="row">
                     <div class="col-md-6 p-2">
                         <label>¿TU DOMICILIO FISCAL ES EL MISMO QUE TU DOMICILIO ACTUAL?</label>
                     </div>
                     <div class="col-md-1 p-2">
                         <div class="form-check form-check-inline">
                             <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="si" (change)="domicilioFiscal($event.target.value)">
                             <label class="form-check-label" for="inlineRadio1">SI</label>
                           </div>
                     </div>
                     <div class="col-md-1 p-2">
                           <div class="form-check form-check-inline">
                             <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="no" (change)="domicilioFiscal($event.target.value)">
                             <label class="form-check-label" for="inlineRadio2">NO</label>
                           </div>
                     </div>
                 </div>
                 <div class="row">
                     <div class="col-md-4 p-2">
                         <label for="nombre">RAZÓN SOCIAL</label>
                         <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1'">
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="apellido 1">RFC</label>
                         <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razoncocial1RFC'">
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="apellido 2">CORREO</label>
                         <input type="text" class="form-control" id="validationCustom01" [formControlName]="'correoRazonSocial1'">
                     </div>
                 </div>
                 <div class="row">
                     <ng-container *ngIf="datosfiscales == 'si'">
                         <div class="col-md-4 p-2">
                             <label for="nombre">CALLE</label>
                             <input [ngModel] = "registroForm.get('callePaciente').value" type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1Calle'" >
                         </div>
                         <div class="col-md-4 p-2">
                             <label for="apellido 1">NÚMERO EXTERIOR</label>
                             <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1NumExt'" [ngModel] = "registroForm.get('numeroPaciente').value">
                         </div>
                         <div class="col-md-4 p-2">
                             <label for="apellido 2">NÚMERO INTERIOR</label>
                             <input [ngModel] = "registroForm.get('numeroIntPaciente').value" type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1NumInt'">
                         </div>
                     </ng-container>
                     <ng-container *ngIf="datosfiscales == 'no'">
                         <div class="col-md-4 p-2">
                             <label for="nombre">CALLE</label>
                             <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1Calle'" >
                         </div>
                         <div class="col-md-4 p-2">
                             <label for="apellido 1">NÚMERO EXTERIOR</label>
                             <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1NumExt'">
                         </div>
                         <div class="col-md-4 p-2">
                             <label for="apellido 2">NÚMERO INTERIOR</label>
                             <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1NumInt'">
                         </div>
                     </ng-container>
                 </div>
                 <div class="row">
                     <ng-container *ngIf="datosfiscales == 'si'">
                         <div class="col-md-4 p-2">
                             <label for="apellido 2">PAÍS</label>
                             <input [ngModel] = "registroForm.get('paisPaciente').value" type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocialPais'">
                         </div>
                         <div class="col-md-4 p-2">
                             <label for="nombre">CÓDIGO POSTAL</label>
                             <input [ngModel] = "registroForm.get('cpPaciente').value" type="text" class="form-control" id="validationCustom01" [formControlName]="'cpRazonSocial'">
                         </div>
                         <div class="col-md-4 p-2">
                             <label for="apellido 1">ESTADO</label>
                             <input [ngModel] = "registroForm.get('estadoPaciente').value" type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1Estado'">
                         </div>
                     </ng-container>
                     <ng-container *ngIf="datosfiscales == 'no'">
                         <div class="col-md-4 p-2">
                             <label for="apellido 2">PAÍS</label>
                             <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocialPais'">
                         </div>
                         <div class="col-md-4 p-2">
                             <label for="nombre">CÓDIGO POSTAL</label>
                             <input type="text" class="form-control" id="validationCustom01" [formControlName]="'cpRazonSocial'">
                         </div>
                         <div class="col-md-4 p-2">
                             <label for="apellido 1">ESTADO</label>
                             <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1Estado'">
                         </div>
                     </ng-container>
                 </div>
                 <div class="row">
                     <ng-container *ngIf="datosfiscales == 'si'">
                         <div class="col-md-4 p-2">
                             <label for="apellido 2">MUNICIPIO</label>
                             <input [ngModel] = "registroForm.get('referenciaPaciente2').value" type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1Municipio'">
                         </div>
                         <div class="col-md-4 p-2">
                             <label for="nombre">CIUDAD</label>
                             <input [ngModel] = "registroForm.get('colonia').value" type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1colonia'">
                         </div>
                     </ng-container>
                     <ng-container *ngIf="datosfiscales == 'no'">
                         <div class="col-md-4 p-2">
                             <label for="apellido 2">MUNICIPIO</label>
                             <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1Municipio'">
                         </div>
                         <div class="col-md-4 p-2">
                             <label for="nombre">CIUDAD</label>
                             <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonSocial1colonia'">
                         </div>
                     </ng-container>
                     <div class="col-md-4 p-2">
                         <label for="apellido 1">TELÉFONO</label>
                         <input type="text" class="form-control" id="validationCustom01" [formControlName]="'razonsocial1Telefono'">
                     </div>
                 </div>
             </div>
         </mat-step>
         <mat-step label="CONTACTO EMERGENCIA">
             <div class="container">
                 <div class="row">
                     <div class="col-md-4 p-2">
                         <label for="nombre">NOMBRE (S)</label>
                         <input type="text" class="form-control" id="validationCustom01" [formControlName]="'contactoEmergencia1Nombre'">
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="apellido 1">APELLIDO PATERNO</label>
                         <input type="text" class="form-control" id="validationCustom01" [formControlName]="'contactoEmergencia1ApellidoPaterno'">
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="apellido 2">APELLIDO MATERNO</label>
                         <input type="text" class="form-control" id="validationCustom01" [formControlName]="'contactoEmergencia1ApellidoMaterno'">
                     </div>
                 </div>
                 <div class="row">
                     <div class="col-md-4 p-2">
                         <label for="nombre">EDAD</label>
                         <input type="text" class="form-control" id="validationCustom01" [formControlName]="'contactoEmergencia1Edad'">
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="apellido 1">TELÉFONO</label>
                         <input type="text" class="form-control" id="validationCustom01" [formControlName]="'contactoEmergencia1Telefono'">
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="apellido 2">PARENTESCO</label>
                         <select name="contactoEmergencia1Parentesco" class="form-control custom-select" id="contactoEmergencia1Parentesco" (blur)="parentesco($event.target.value)" >
                             <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                             <option  value="PADRE">  PADRES   </option>
                             <option value="ABUELOS">   ABUELOS </option>
                             <option value="HIJOS"> HIJOS </option>
                             <option value="HERMANOS">  HERMANOS </option>
                             <option value="PRIMOS">   PRIMOS </option>
                             <option value="CONYUGUE">  CÓNYUGUE </option>
                             <option value="OTROS">  OTROS </option>
                         </select>
                     </div>
                 </div>
                 <div class="row">
                     <div class="col-md-4 p-2">
                         <label for="apellido 1">GÉNERO</label>
                         <select name="genero" required  id="genero" class="form-control custom-select" (blur)="generoContacto($event.target.value)">
                             <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                             <option value="FEMENINO">  FEMENINO </option>
                             <option value="MASCULINO"> MASCULINO </option>
                         </select>
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="apellido 1">ESTADO CIVIL</label>
                         <select name="genero" required id="genero" class="form-control custom-select" (blur)="estadoCivilContacto($event.target.value)">
                             <option value="" selected>SELECCIONA UNA OPCIÓN</option>
                             <ng-container *ngIf="registroForm.get('contactoEmergencia1Genero').value == 'MASCULINO'">
                                 <option value="SOLTERO">SOLTERO</option>
                                 <option value="CASADO">CASADO</option>
                                 <option value="DIVORCIADO">DIVORCIADO</option>
                                 <option value="SEPARACIÓN EN PROCESO JUDICIAL">SEPARACIÓN EN PROCESO JUDICIAL</option>
                                 <option value="VIUDO">VIUDO</option>
                                 <option value="CONCUBINATO">CONCUBINATO</option>
                                 <option value="UNION LIBRE" >UNIÓN LIBRE</option>
                             </ng-container>
                             <ng-container *ngIf="registroForm.get('contactoEmergencia1Genero').value == 'FEMENINO'">   
                                     <option value="SOLTERO">SOLTERA</option>
                                     <option value="CASADO">CASADA</option>
                                     <option value="DIVORCIADO">DIVORCIADA</option>
                                     <option value="SEPARACIÓN EN PROCESO JUDICIAL">SEPARACIÓN EN PROCESO JUDICIAL</option>
                                     <option value="VIUDO">VIUDA</option>
                                     <option value="CONCUBINATO">CONCUBINATO</option>
                                     <option value="UNION LIBRE" >UNIÓN LIBRE</option>
                             </ng-container>
                         </select>
                     </div>
                     <div class="col-md-4 p-2">
                         <label for="apellido 1">CORREO</label>
                         <input type="text" class="form-control" id="validationCustom01" [formControlName]="'contactoEmergencia1Correo'">
                     </div>
                 </div>
                 <div class="row">
                     <div class="col p-2">
                         <div class="center">
                             <button mat-button matStepperNext class="principalButton" (click)="nuevoDisponente()" > Agregar </button>
                         </div>
                     </div>
                 </div>
             </div>
         </mat-step>
     </mat-horizontal-stepper>
    </form> 
 </div>