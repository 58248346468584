<section>
    <div class="row">
        <div class="col-8">
            <h1 class="centerxd"> HISTORIA CLÍNICA </h1>
        </div>
    </div>
</section>
<section>
    <div class="card" name="card" id="card">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <p>Nombre: {{paciente.nombre | uppercase}} {{paciente.apellidoPaterno | uppercase}} {{paciente.apellidoMaterno | uppercase}} </p>
                </div>
                <div class="col-md-3">
                    <p> CURP: {{ paciente.curp | uppercase }} </p>
                </div>
                <div class="col-md-2">
                    <p>Edad: {{paciente.edad}} </p>
                </div>
                <div class="col-md-3">
                    <p>Sexo: {{paciente.genero | uppercase}} </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <p>Registro: {{paciente.registro}} </p>
                </div>
                <div class="col-md-3">
                    <p>Dirección: {{paciente.calle | uppercase}} {{ paciente.municipio | uppercase}}</p>
                </div>
                <div class="col-md-2">
                    <p> {{ fecha }} {{ hora }} </p>
                </div>
                <div class="col-md-3">
                    <p> ID: {{ paciente.idPaciente }} </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="card" id="card">
        <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-link" data-toggle="modal" data-target="#exampleModal"> Historia clínica </button>
            <button type="button" class="btn btn-link"> Laboratorio </button>
            <button type="button" class="btn btn-link"> Rayos x </button>
            <button type="button" class="btn btn-link"> Endoscopias </button>
            <button type="button" class="btn btn-link"> Tomografías </button>
            <button type="button" class="btn btn-link"> Ultrasonidos </button>
            <button type="button" class="btn btn-link"> Resonancias </button>
        </div>
    </div>



</section>

<!-- COMPONENTE DE LAS GRAFICAS BROW -->
<div class="">
    <app-graficas-pediatria [paciente]="paciente" *ngIf="paciente.genero == 'FEMENINO' && paciente.edad <=9">
    </app-graficas-pediatria>
</div>

<div class="">
    <app-graficas-ninos [paciente]="paciente" *ngIf="paciente.genero == 'MASCULINO' && paciente.edad <=9"></app-graficas-ninos>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5> Historia clínica </h5>
            </div>
            <div class="modal-body">
                <app-historia-clinica [paciente]="paciente"></app-historia-clinica>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"> Cerrar </button>
            </div>
        </div>
    </div>
</div>

<section>
    <div class="row">
        <div class="col-2">
            <div class="card" id="card" style="overflow-x: scroll; height: 25rem;">
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>Talla: <span>{{paciente.talla}} m</span></p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaTalla" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>Peso: <span>{{paciente.peso}} m</span></p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#graficaPeso" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>IMC: <span> {{paciente.imc}}  Kg/m<sup>2</sup> </span></p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <!--  -->
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad > 9">
                    <div class="col-6">
                        <p>Sistolica: <span> {{paciente.sistolica}}  <sup></sup> </span></p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad > 9">
                    <div class="col-6">
                        <p>Diastolica: <span> {{paciente.diastolica}}  <sup></sup> </span></p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>

                <!--  -->
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad < 9">
                    <div class="col-6">
                        <p>FC: <span> {{paciente.fc}} LPM </span></p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad < 9">
                    <div class="col-6">
                        <p>FR: <span> {{paciente.fr}} RPM </span></p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>TEMP: <span> {{paciente.temp}} °C </span></p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>P.C.: <span> {{paciente.pc}} cm </span></p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>P.A.: <span> {{paciente.pa}} cm </span></p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p>P.T.: <span> {{paciente.pt}} cm </span></p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p *ngIf="edad > 8">APGAR: <span> {{paciente.apgar}} </span></p>
                    </div>
                    <div class="col-6">
                        <button *ngIf="edad > 8" type="button" class="btn btn-primary col-sm-8" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad <= 9">
                    <div class="col-6">
                        <p>SaO: <span> {{paciente.sao}} % </span></p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;" *ngIf="paciente.edad > 9">
                    <div class="col-6">
                        <p>SaO: <span> {{paciente.pao}} </span>%SpO<sub>2</sub></p>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-primary col-sm-8" data-toggle="modal" data-target="#modalPesoNino" (click)="alertaGraficas()"><i class="fas fa-chart-bar"></i></button>
                    </div>
                </div>
                <div class="row" style="padding: 10px;">
                    <div class="col-6">
                        <p> Alergias:<span> {{paciente.alergias}} </span></p>
                    </div>
                    <div class="col-6"></div>
                </div>
            </div>
        </div>
        <div class="col-8">
            <div class="card" id="card" style="width: 71rem !important;
        height: 25rem !important;">
                <div class="col-12">
                    <div role="tabpanel">
                        <ul class="nav nav-tabs">

                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" aria-controls="#motivoConsulta" href="#motivoConsulta">Motivo de la consulta</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" href="#evolucionDelPaciente" data-toggle="tab" aria-controls="#evolucionDelPaciente">Evolución del paciente</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" href="#interrogacionPorAparatosYsistemasH" data-toggle="tab" aria-controls="#interrogacionPorAparatosYsistemasH">  Interrogación por aparatos y sistemas  </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" href="#exploracionFisicaH" data-toggle="tab" aria-controls="#exploracionFisicaH">  Exploración fisica </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" href="#diagnostico" data-toggle="tab" aria-controls="#diagnostico">  Diagnóstico </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" href="#plan" data-toggle="tab" aria-controls="#plan">  Plan </a>
                            </li>

                        </ul>
                        <div class="tab-content">


                            <!-- Motivo de la consulta -->
                            <div role="tabpanel" class="tab-pane active" id="motivoConsulta">
                                <br>
                                <!--h4> Motivo de la consulta </h4-->
                                <div class="container">
                                    <form>
                                        <div class="row">
                                            <textarea class="form-control text-upper" name="motivoDeConsulta" id="motivoDeConsulta" [(ngModel)]="hojaEvolucion.motivoDeConsulta" cols="130" rows="12"></textarea>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- Evolucion de la consulta  -->
                            <div role="tabpanel" class="tab-pane" id="evolucionDelPaciente">
                                <!--h4> Evolucion de la consulta </h4-->
                                <br>
                                <div class="container">
                                    <form>
                                        <div class="row">
                                            <textarea class="form-control text-upper" name="evolucionDelPadecimiento" [(ngModel)]="hojaEvolucion.evolucionDelPadecimiento" id="evolucionDelPadecimiento" cols="130" rows="12"></textarea>
                                        </div>
                                    </form>
                                </div>
                            </div>




                            <!-- INTEROGACION  POR APARATOS Y SISTEMAS -->

                            <div role="tabpanel" class="tab-pane" id="interrogacionPorAparatosYsistemasH">
                                <!--h4 class="text-principal"> Interrogacion por aparatos y sistemas </h4-->

                                <div class="container" style="overflow-x: scroll; height: 19rem;">
                                    <form action="">
                                        <div class="row">
                                            <div class="col-md-3">

                                                <label for="respiratorio">Respiratorio/Cardiovascular</label>
                                            </div>

                                            <div class="col-md-9">
                                                <input type="text" name="respiraotriosCardiovasculares" [(ngModel)]="hojaEvolucion.respiratorioCardiovascular" class="form-control">

                                            </div>
                                        </div>
                                        <div class="row">


                                            <div class="col-md-3">

                                                <label for="digestivo">Digestivo</label>
                                            </div>

                                            <div class="col-md-8">
                                                <input type="text" class="form-control" name="digestivo" [(ngModel)]="hojaEvolucion.digestivo">

                                            </div>

                                            <div class="col-md-3">
                                                <label for="endocrino"> Endocrino </label>
                                            </div>

                                            <div class="col-md-8">
                                                <input type="text" class="form-control" name="endocrino" id="endocrino" [(ngModel)]="hojaEvolucion.endocrino">
                                            </div>

                                            <div class="col-md-3">

                                                <label for="musculo">Músculo - Esquelético</label>
                                            </div>

                                            <div class="col-md-8">

                                                <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.musculoEsqueletico" name="musculoEsqueletico">
                                            </div>

                                            <div class="col-md-3">
                                                <label for="gitourinario">Gitourinario</label>

                                            </div>

                                            <div class="col-md-8">

                                                <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.genitourinario" name="genitourinario">
                                            </div>
                                            <div class="col-md-3">
                                                <label for="hematopoyetico">Hematopoyético - Linfático</label>

                                            </div>
                                            <div class="col-md-8">
                                                <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.hematopoyeticoLinfatico" name="hematopoyeticoLinfatico">

                                            </div>
                                            <div class="col-md-3">
                                                <label for="piel">Piel y anexo</label>

                                            </div>

                                            <div class="col-md-8">
                                                <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.pielAnexos" name="pielAnexos">

                                            </div>

                                            <div class="col-md-3">
                                                <label for="neurologico">Neurológico y Psiquiátrico</label>

                                            </div>

                                            <div class="col-md-8">
                                                <input type="text" class="form-control" [(ngModel)]="hojaEvolucion.neurologicoPsiquiatricos" name="neurologicoPsiquiatricos">

                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                            <!-- Exploracion fisica  -->
                            <div role="tabpanel" class="tab-pane" id="exploracionFisicaH">
                                <!--h4> Exploración fisica </h4-->
                                <br>
                                <div class="container" style="overflow-x: scroll; height: 19rem;">


                                    <form action="">
                                        <div class="row">

                                            <div class="col-md-3">
                                                <label for="piel">Piel y Anexo</label>

                                            </div>
                                            <div class="col-md-8">

                                                <input type="text" name="pielyanexo" class="form-control" [(ngModel)]="hojaEvolucion.piel">
                                            </div>
                                            <div class="col-md-3">

                                                <label for="cabezaYCuello">Cabeza y Cuello</label>
                                            </div>

                                            <div class="col-md-8">

                                                <input type="text" name="cabezaYCuello" class="form-control" [(ngModel)]="hojaEvolucion.cabezaCuello">
                                            </div>

                                            <div class="col-md-3">
                                                <label for="torax">Tórax</label>

                                            </div>

                                            <div class="col-md-8">
                                                <input type="text" name="torax" class="form-control" [(ngModel)]="hojaEvolucion.torax">
                                            </div>

                                            <div class="col-md-3">
                                                <label for="abdomen">Abdomen</label>

                                            </div>
                                            <div class="col-md-8">

                                                <input type="text" name="abdomen" class="form-control" [(ngModel)]="hojaEvolucion.abdomen">
                                            </div>

                                            <div class="col-md-3">
                                                <label for="genitales">Genitales</label>

                                            </div>

                                            <div class="col-md-8">
                                                <input type="text" name="genitales" class="form-control" [(ngModel)]="hojaEvolucion.genitales">

                                            </div>

                                            <div class="col-md-3">
                                                <label for="extremidades">Extremidades</label>

                                            </div>
                                            <div class="col-md-8">
                                                <input type="text" name="extremidades" class="form-control" [(ngModel)]="hojaEvolucion.extremidades">

                                            </div>
                                            <div class="col-md-3">
                                                <label for="sistemaNervioso">Sistema Nervioso</label>

                                            </div>

                                            <div class="col-md-8">
                                                <input type="text" name="sistemaNervioso" class="form-control" [(ngModel)]="hojaEvolucion.sistemaNervioso">

                                            </div>

                                        </div>
                                    </form>
                                </div>

                            </div>

                            <!-- DIAGNOSTICOS -->

                            <div role="tabpanel" class="tab-pane" id="diagnostico">
                                <div class="container" style="overflow-x: scroll; height: 19rem;">
                                    <div class="row">
                                        <!--h3> Dianostico </h3-->
                                        <br>
                                        <div class="col-md-12">
                                            <input type="text" class="form-control" name="buscarDiagnostico" (keyup)="obtenerDiagnosticoUno()" id="buscarDiagnostico" [(ngModel)]="buscarDiagnostico">
                                            <br>
                                            <div style="overflow-x: scroll; height: 5rem;">
                                                <table>
                                                    <tr *ngFor="let item of diagnosticos">
                                                        <td (click)="agregarDiagnosticoUno( item.NOMBRE  )"> {{ item.NOMBRE }} </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="col-md-9">
                                            <div class="card">
                                                <ul *ngFor="let item of hojaEvolucion.diagnostico">
                                                    <li>
                                                        {{ item.diagnostico }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="necesitaReceta"> Receta  </label>
                                            <br>
                                            <label for="si" class="resaltar"> Si </label>
                                            <input type="radio" class="primerReceta" value="si" [(ngModel)]="necistaReceta" name="receta" id="receta">
                                            <label for="no" class="resaltar"> No </label>
                                            <input type="radio" class="primerReceta" value="no" [(ngModel)]="necistaReceta" name="receta" id="receta">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- PLAN -->

                            <div role="tabpanel" class="tab-pane" id="plan">
                                <!--h4> Plan</h4-->
                                <br>
                                <div class="container">
                                    <div class="row">
                                        <textarea name="plan" class="form-control text-upper" [(ngModel)]="hojaEvolucion.plan" id="plan" cols="125" rows="6"></textarea>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-4 center">
                                        <button class="principalButton colorWhite" (click)="agregarHistoriaClinica()"> Guardar  </button>
                                    </div>
                                </div>
                            </div>



                        </div>



                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!--div class="row mb-2">
                                        <div class="col-md-2">
                                            <p>
                                                PESO <strong>  {{ paciente.peso }} </strong> KG
                                            </p>
                                        </div>
                                        <div class="col-md-2">
                                            <p>
                                                TALLA <strong> {{ paciente.talla }}</strong> MTs.
                                            </p>
                                        </div>
                                        <div class="col-md-2">
                                            <p>
                                                TA mmHG.
                                            </p>
                                        </div>
                                        <div class="col-md-2">
                                            <p>
                                                FC/PULSO <strong>  {{ paciente.rpm }} </strong> x min
                                            </p>
                                        </div>
                                        <div class="col-md-2">
                                            <p>
                                                TEMPERATURA <strong> {{ paciente.temp }}</strong> °C
                                            </p>
                                        </div>
                                    </div-->