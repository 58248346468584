<form class="chat">
    <div class="row">
        <div class="col-12">
            <h5>{{nickname}}</h5>
        </div>
    </div>
    <div class="row">
        <div class="col-9">
            <div class="chat-body">
                <div class="card ">
                    <div class="container-fluid">
                        <div class="row" *ngFor="let message of mesages" (scroll)="scrolled($event)">
                            <div class="col" style="padding-left: 2px;" *ngIf="message.role1 === usuario.nombre">
                                <p class="message-card">
                                    {{ message.message }}<span class="horaMessage"> {{message.fecha | date: 'shortTime'}}  </span> </p>
                            </div>
                            <div class="col" *ngIf="message.role1 !== usuario.nombre">
                                <p class="message-card-reci">
                                    {{ message.message }} <span class="horaMessage"> {{message.fecha | date: 'shortTime'}}  </span> </p>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
        <div class="col-3" style="height: 18rem; overflow-y: scroll;">
            <div class="container-membres">
                <diV *ngFor="let item of usuarioConectados">
                    <a class="badge badge-secondary" (click)="verUsaurio(  item.nombre, $event )">  {{ item.nombre }} -{{usuario.sede}} </a>
                </diV>
            </div>
        </div>
    </div>

    <div class="chat-message">
        <div class="row">
            <div class="col-8">
                <textarea type="text" rows="1" name="message" id="message" placeholder="Mensaje" class="form-control chat-input" [(ngModel)]="message"></textarea>
            </div>
            <div class="col-4">
                <button class="btn btn-primary btn-chat" (click)="enviarData()" [disabled]="message === ''"> Enviar </button>
            </div>
        </div>
    </div>

</form>