import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';

@Component({
  selector: 'app-ver-pacientes',
  templateUrl: './ver-pacientes.component.html',
  styleUrls: ['./ver-pacientes.component.css']
})
export class VerPacientesComponent implements OnInit {


  public pacientes2:any = [];
  public totalpaciente: string;
  public pagina : number = 0;
  filtropacientes = '';
  public sede = "";

  constructor(
    public _pacienteService: PacientesService,
  ) { }

  ngOnInit(): void {
    this.obtenerSede();
    this.obtenerPacientes();
  }

  obtenerSede(){
    this.sede = localStorage.getItem('cede');
    // console.log( this.sede );
  }

  obtenerPacientes() {

    this._pacienteService.getPacientesSede( this.sede )

    .subscribe( (data: any) => {
      // console.log( data );

      if( data['message']   === 'No hay pacientes' ) {

        alert('Aun no hay pacientes')
        return;
      }
       this.pacientes2 = data.data;
       this.totalpaciente = data.data.results;

    });

  }


  paginaAnterior(){
    this.pagina = this.pagina - 8;
    this._pacienteService.getPacientes( this.pagina)
    .subscribe( (data: any) => {
      this.pacientes2 = data.users;
    } )
  }


  siguentePagina(  ){
    this.pagina += 8;
    this._pacienteService.getPacientes( this.pagina)
    .subscribe( (data: any) => {
      // console.log( data.users );
      this.pacientes2= data.users;
    })
  }

  ingreso( f: NgForm ){
    // console.log( f )
    let nombre = f.value.buscarPaciente;
    // console.log( nombre );
    this._pacienteService.getPacientePorNombre( nombre ).subscribe( (data: any) =>{
      // console.log(data.pacientes)
      this.pacientes2 = data.pacientes;
    })
  }
}
