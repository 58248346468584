import { Component, OnInit } from '@angular/core';
import { WsLoginService } from 'src/app/services/sockets/ws-login.service';
import {  ConsultaService } from 'src/app/services/consultas/consulta.service';

import * as moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'app-bitacora',
  templateUrl: './bitacora.component.html',
  styleUrls: ['./bitacora.component.css']
})
export class BitacoraComponent implements OnInit {

  public consultas:[] =[];
  public fecha:String;

  public nombreDoctror="";

  constructor(
    private _consultaService: ConsultaService,
    private _wsLognService: WsLoginService
  ) { }

  ngOnInit(): void {
    this.fecha = moment().format('L');
    this.obtenerConsultasMedico();


    this._wsLognService.escucharBitacoraDoctor()
    .subscribe(arg => {
      if(arg){
        this.obtenerConsultasMedico();
      }
    });

  }


  obtenerNombreDoctor(){
    this.nombreDoctror = JSON.parse( localStorage.getItem('usuario') ).nombre
    // console.log( this.nombreDoctror );
  }


  obtenerConsultasMedico(){
    this.obtenerNombreDoctor();
    this._consultaService.verConsultasMedico( this.nombreDoctror )
    .subscribe( (data) => {
        this.consultas = data['data'];
        
    } )
  }


  cambiarEstado(id: string) {

    let estado = {
      status:'Medico'
    }

    this._consultaService.cambiarEstadoConsulta(id, estado)
    .subscribe(data => console.log(data));

  }


  imprimirBitacora(){

    const doc:any = new jsPDF();

    doc.autoTable({ html: "#bitacora" });

    doc.save( `Bitácora medicina general${this.fecha}.pdf`);
  }


}
