<div class="container-fluid" style="padding-left: 3.5rem; padding-right: 3.5rem;">
    <section class="center">
        <div class="row">
            <div class="col">
                <h1 class="centerxd title" id="centerxd"> {{ pacientes.nombrePaciente | uppercase }} {{ pacientes.apellidoPaterno | uppercase }} {{ pacientes.apellidoMaterno | uppercase }} </h1>
            </div>
        </div>
    </section>

    <section class="container">
        <div class="row">
            <div class="col-6">
                <select name="servicio" class="form-control" id="" #servicio (change)="irAUnServicio( servicio.value  )">
                    <option   value="" selected> Selecciona un servicio  </option>
                    <option   value="ambulancia"> Ambulancia</option>
                    <option   value="consulta"  > Consulta general </option>
                    <option   value="consultaEspecialista">  Consulta con especialista </option>
                    <option   value="endoscopia"> Endoscopía</option>
                    <!-- <option   value="resultados"> Entraga de resultados </option>  -->
                    <!-- <option   value="farmacia">   Farmacia </option>  -->
                    <!-- <option   value="hospitalizacion"> Hospitalización</option>  -->
                    <option   value="laboratorio"> Laboratorio</option>
                    <!-- <option   value="quirofano">  Quirofano</option> -->
                    <option   value="xray">     Rayos X </option>
                    <option   value="resonancia"> Resonancia</option>
                    <option   value="tomografias"> Tomografía</option>
                    <!-- <option   value="TrabajoSocial" > Trabajo Social  </option> -->
                    <option   value="ultrasonido"> Ultrasonido</option>
                    <!-- <option   value="urgencias"> Urgencias</option>  -->
                    <option   value="otros"> Servicios generales </option> 
                  </select>
            </div>
            <!-- <div class="col">
                <button class="btn principalButton" id="boton_contra1"> CONSULTAS </button>
            </div>
            <div class="col">
                <button class="btn secondaryButton" id="boton_contra2">   PAGOS </button>
            </div> -->
        </div>
    </section>
    <br>
    <section class="section">
        <div class="cardd" name="card" id="card">
    
            <div class="row paciente-card">
                <div class="col-md-4">
                    <p class="font"> <strong> CURP: </strong> <strong class="name"> {{ pacientes.curp }} </strong> </p>
                </div>
    
                <div class="col-md-4">
                    <p class="font"> <strong> Edad: </strong> <strong class="name">  {{ pacientes.edad }} </strong> </p>
                </div>
                <div class="col-md-4">
                    <p class="font"> <strong> Género: </strong> <strong class="name"> {{ pacientes.genero | uppercase }} </strong> </p>
                </div>
    
            </div>
            <div class="row">
    
                <div class="col-md-4">
                    <p class="font">Correo: {{ pacientes.correoPaciente }} </p>
                </div>
    
                <div class="col-md-4">
                    <p class="font"> C.P: {{ pacientes.cpPaciente }} </p>
                </div>
    
                <div class="col-md-4">
    
                </div>
    
            </div>
    
            <!-- <div class="row">
    
                </div> -->
    
        </div>
    </section>
    
    <h3 class="center color-text mt-5"> Tus paquetes </h3>

    <div class="card-columns">
        <div *ngFor="let item of paquetes">
            <div class="col" *ngIf="paquetes.length != 0">
                <div class="container">
                    <div class="card center" name="card" id="card" style="
                    height: 24rem !important;">
                        <img class="card-img-top img" [src]="item.paquete.icon" alt="Card image cap" style="width: 200px;" [routerLink]="['/paquete-paciente', item._id]">
                        <div class="card-body">
                            <h5 class="card-title color-text"> {{ item.paquete.nombrePaquete }} </h5>
                            <button type="button" class="btn btn-primary" (click)="ver_info_paquetes($event,item.nombrePaquete)">
                                Ver más
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 offset-3  mt-1  sin-paquetes" *ngIf="pacientes.paquetes.length == 0">

            <div class="card">
                <div class="alert alert-light center padding-auto" role="alert">
                    <p>
                        Aún no tienes paquetes
                    </p>
                </div>

            </div>
        </div>
        <div class="container">
            <div class="card center" name="card" id="card" style="height: 24rem!important;" [routerLink]="['/solicitar/paquete/', id]">
                <img class="card-img-top img" src="../../../../assets/icons/serviciosIconos/add.svg" alt="Card image cap" style="width: 200px; margin-top: 2rem;">
                <h5 class="card-title color-text" style="margin-top: 2rem;"> AGREGAR PAQUETE</h5>
            </div>
        </div>
    </div>
</div>




<!-- <br>

<div class="center">
    <section>
        <div class="card">
            <div style="scroll-margin-block-start: 75px;" class="card-header header">
                <div class="row">
                    <div class="text_header">
                        <p>HISTORIAL DE CONSULTAS</p>
                    </div>

                </div>
            </div>



            <div>

                <div style="color: whitesmoke;">
                    <div class="row">
                        <div class="col-1 text_header">
                            <p style="color: #001672;">FECHA</p>
                        </div>
                        <div class="col text_header">
                            <p style="color: #001672;">MÉDICO</p>
                        </div>
                        <div class="col text_header">
                            <p style="color: #001672;">CONCEPTO</p>
                        </div>

                    </div>
                </div>


                <div class="col-md-3">
                    <p style="color: #001672;"> 22 DE MAYO DEL 2020</p>
                </div>
                <div class="col-md-3">
                    <p style="color: #001672;">22 DE MAYO DEL 2020</p>
                </div>
                <div class="col-md-3">
                    <p style="color: #001672;">22 DE MAYO DEL 2020</p>
                </div>
                <div class="col-md-3">
                    <p style="color: #001672;">22 DE MAYO DEL 2020</p>
                </div>
                <div class="col-md-3">
                    <p style="color: #001672;">22 DE MAYO DEL 2020</p>
                </div>

            </div>

        </div>
        <br>
    </section>
</div> -->