<h1 class=""> Receta Médica</h1>

<div class="container">
    <div class="row">
        <app-header-receta [paciente]="paciente"></app-header-receta>
        <div class="col-md-12">

            <div role="tabpanel">

                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link center" data-toggle="tab" href="#dosificacion"> Posología </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link center" data-toggle="tab" href="#prescripcion"> Otras indicaciones </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link center" data-toggle="tab" href="#estudios">  Estudios </a>
                    </li>
                </ul>

                <div class="tab-content mt-5 mb-3">
                    <div role="tabpanel" class="tab-pane" id="dosificacion">
                        <div class="card container-fluid">
                            <div class="row">

                                <div class="col-md-6">
                                    <form>

                                        <h5 class="iz"> Medicamento </h5>

                                        <input type="text" class="" (keyup)="buscarMedicamento()" [(ngModel)]="busuqedaMedicamento" name="medicamento" id="buquedaDeMedicamento">
                                        <div>
                                            <tr class="card" *ngFor="let item of medicamentos">
                                                <td #tabla (click)="setMedicamentos( tabla )">
                                                    {{ item.nombreComercial}} {{ item.nombreDeSalOsustanciaActiva }} {{ item.presentacio }} {{ item.viaDeAdministracion }} {{ item.contenidoFrasco }}
                                                </td>
                                            </tr>
                                        </div>
                                        <h5 class="iz"> Indicaciones </h5>

                                        <textarea class="iz" [(ngModel)]="indicaciones.indcacionesMedicamento" name="medicamento" id="" cols="55" rows="3"></textarea>
                                        <button class="principalButton btn-receta" (click)="agregarMedicamentosEIndicaciones()"> Agregar </button>
                                        <button class="secondaryButton btn-receta" (click)="imprimirFrente()"> Imprimir  </button>
                                        <p class="nuevoMedicamento"> ¿ No encuentras algú medicamento? </p> <a data-toggle="modal" data-target="#medicamentoModal">  Nuevo </a>


                                    </form>
                                </div>

                                <div class="col-md-6">

                                    <table id="receta" *ngFor="let item of recetMedica.medicamentos">
                                        <tr class="first-medicine">
                                            <td>
                                                {{ item.medicamento }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ item.indicaciones }}

                                            </td>
                                        </tr>
                                    </table>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div role="tabpanel" class="tab-pane" id="prescripcion">

                        <div class="row">
                            <div class="col-md-3">
                                <h4>
                                    Receta
                                </h4>
                            </div>
                        </div>
                        <!-- INDICACIONES  -->
                        <div class="row">
                            <div class="col-md-12">

                                <textarea name="" [(ngModel)]="recetMedica.otrasIndicaciones" id="" cols="110" rows="8"></textarea>
                            </div>

                        </div>

                    </div>

                    <!-- ESTUDIOS  -->

                    <div role="tabpanel" class="tab-pane" id="estudios">
                        <h3> Estudios</h3>
                        <div class="row">
                            <div class="col-md-12">
                                <input type="text" name="buscarEstudios" id="buscarEstudios" [(ngModel)]="buscarEstudiotxt.estudio" (keyup)="buscarEstudio()">
                            </div>
                            <div class="col-md-12">
                                <tr class="list-estudios" *ngFor="let estudio of estudios">
                                    <td (click)="agregarEstudio( estudio )"> {{ estudio.ESTUDIO }} </td>
                                </tr>
                            </div>
                            <div class="col-md-12">
                                <ul *ngFor="let item  of recetMedica.estudios">
                                    <li class="center"> {{ item.estudio }} </li>
                                </ul>
                            </div>
                            <div class="col-md-4 offset-md-4 mt-4">
                                <!-- <button class="btn-indicaciones secondaryButton" (click)="imprimirReverso()">  Imprimir  </button> -->
                            </div>
                        </div>
                    </div>

                </div>

            </div>


        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-4 offset-md-4">
            <button class="principalButton" (click)="guardarReceta()">  Guardar  </button>
        </div>

        <!-- <div class="col-md-2 offset-md-1">
            <button class="secondaryButton" (click)="imprimirFrente()">  Imprimir  </button>
        </div> -->

    </div>
    <div class="row">

        <div id="medicamentoModal" class="modal fade bd-example-modal-lg center" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content modal-medicamento">
                    <div class="modal-header">
                        <h3 class="modal-title center">Registro Nuevo Medicamento</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-4">
                                <label for="nombreComercial">Nombre Comercial:</label>
                                <input type="text" class="inputStyle inModalS" style="
                                margin: -2rem 0 2rem 0;">
                            </div>
                            <div class="col-md-4">
                                <label for="sustamciaActiva">Sustancia Activa:</label>
                                <input type="text" class="inputStyle inModalS" style="
                                margin: -2rem 0 2rem 0;">
                            </div>
                            <div class="col-md-4">
                                <label for="presentacion">Presentación:</label>
                                <input type="text" class="inputStyle inModalS" style="
                                margin: -2rem 0 2rem 0;">
                            </div>
                            <div class="col-md-4">
                                <label for="contenido">Contenido del frasco:</label>
                                <input type="text" class="inputStyle inModalS" style="
                                margin: -2rem 0 2rem 0;">
                            </div>
                            <div class="col-md-4">
                                <label for="viaAdmon">Vía de Administración:</label>
                                <input type="text" class="inputStyle inModalS" style="margin: -2rem 0 2rem 0;">
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="row">
                            <div class="col-md-6">
                                <button type="button" class="secondaryButton" data-dismiss="modal">Cerrar</button>

                            </div>
                            <div class="col-md-6">

                                <button type="submit" class="principalButton">Guardar</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>