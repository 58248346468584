<div>
    <div class="card" style="border-radius: 30px;">
        <div class="card-heder header">
            <div class="row">
                <div class="col text_header">
                    <p style="text-align: left; margin-left: 20px;">No</p>
                </div>
                
                <div class="col text_header" *ngFor="let item of bitacoraHeader">
                    <p style="text-align: left; margin-left: 10px;">{{item}}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body" style="background-color: #ffffff;">
        <div>
            <div class="row">
                <div class="col-md-2">
                    <p style="text-align: left; margin-left: 10px;">1</p>
                </div>

                <div class="col">
                    <p style="text-align: center;">CONCENTRADOS ERITROCITARIOS</p>
                </div>

                <div class="col">
                    <p style="text-align: center;">$400</p>
                </div>

                <div class="col">
                    <p style="text-align: center;">AGREGAR</p>
                </div>
            </div>
        </div>
    </div>
</div>