import { Component, Input, OnInit } from '@angular/core';
import { ServiciosService } from '../../../../services/serviciosInt/servicios.service';
import { Router, ActivatedRoute} from '@angular/router';
/* import { gaurdarCotizacion } from '../../../../functions/storage.funcion';*/
import { getCarritoStorage } from '../../../../functions/storage/pacienteIntegrados';
import  {  getDataStorage   } from '../../../../functions/storage/storage.functions';
import swal from 'sweetalert';
import PacienteStorage from 'src/app/clases/pacientes/pacientes.class';
import  Carrito  from '../../../../clases/carrito/carrito.class';

@Component({
  selector: 'app-todos-serv',
  templateUrl: './todos-serv.component.html',
  styleUrls: ['./todos-serv.component.css']
})
export class TodosServComponent implements OnInit {

  // data de los servicios
  @Input() serviceSi: any [] = [];
  public ambulanciaSI: any [] = [];
  public totalAmbulancia: string;
  //forma: FormGroup;
  public buscar = {
    estudio:''
  }
  public pagina = 0;
  public showTableAmbulanacia = true;
  public showAmbulanacia = false;
  @Input() showVista= true;
  public searching = false;
  public servicios ="";

  public membresia = false;
  public preciosMembresia=[];

  public RoleUser = "";

  public carritoMembresia = {
    items:[],
    total:0
  }

  public paciente:any;


  obtenerPacienteYMembresia(){
    const usuarioMembresia = new PacienteStorage();
    this.paciente = usuarioMembresia.verPacienteConMembresia();

    if( this.paciente == null || this.paciente.membresiaHabilitada == false ) {
      this.verDatos();
    }else{
      this.membresia = usuarioMembresia.verMembresiaPaciente();
      
      this.verDatos();
    }
  }

  public carrito = {
    totalSin: 0,
    totalCon: 0,
    items: []
  };


  public sede="";

  constructor(private _service: ServiciosService, private _router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.servicios = this.activatedRoute.snapshot.paramMap.get('servicio');
    // console.log(this.serviceSi);

    this.getSede();
    // console.log(this.servicios);
    this.obtenerRoleStorage();
    this.obtenerCarritoStorage();
    this.obtenerPacienteYMembresia();
  }



  getSede (){
    this.sede = JSON.parse(localStorage.getItem('IdSede'));
  }

  obtenerCarrito (){

    this.carrito = getCarritoStorage();

    if ( this.carrito == null ){
        this.carrito = {
          totalSin: 0,
          totalCon: 0,
          items: []
        };
    }

  }

  obtenerRoleStorage(){
    this.RoleUser = getDataStorage().role;
    // console.log( this.RoleUser );
  }

  obtenerCarritoStorage(){
    const storageCarrito = new Carrito();
    this.carrito = storageCarrito.obtenerSotorageCarrito();
  }

  busquedaGeneral(){
    if(this.buscar.estudio.length >3){
      this._service.getAllDepartments(this.buscar)
      .subscribe((res:any) => {
        // console.log( res );
        if(res.data[0]!=0){
          this.ambulanciaSI = res.data[0];
          this.showAmbulanacia= true;
          this.showTableAmbulanacia = false;
        }else{
          this.serviceSi = res.data[1];
          this.searching = true;
        }
      })
    }
    if(this.buscar.estudio == ''){
      this.searching = false;
      this.showAmbulanacia= false;
      this.showTableAmbulanacia = true;
      this.obtenerCarritoStorage();
      this.verDatos();
    }
  }


  /* eliminar(index){
    //console.log('Pedido', pedido);
    let carrito = new Carrito();
    carrito.eliminar( index );

    this.obtenerCarritoStorage();
  } */

  verDatos(){
    this._service.obtenerServiciosSedeNombre(this.servicios, this.sede).subscribe(
      (res: any) => {
          this.serviceSi = res.data;
          this.totalAmbulancia = res.data.results;
      },
    err => {
        console.log(<any>err);
    }
  );
  }

}
