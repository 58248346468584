import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { fnCalculaCURP } from 'src/app/functions/storage/curpyrfc';
import { LocationServiceService } from 'src/app/services/otrosService/location-service.service';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
// import { NgStepperModule } from 'angular-ng-stepper';
@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class StepperComponent implements OnInit {

  
  @ViewChild('registroForm') registroNgForm: NgForm;
  
  public asentamiento = [];
  public municipios = [];
  public estadocp = "";
  public codigoPostal = "";
  public zipCode = [];

  //nuevas variabless

  registroForm: FormGroup;
  edadNgForm = 0;
  fechaNacimientoNgForm:any 
  datosfiscales = 'no'

  constructor(public _locationService: LocationServiceService,
              public _pacienteService: PacientesService,
              private _formBuilder: FormBuilder,
              private _router: Router,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.registroForm = this._formBuilder.group({
      nombrePaciente              : ['', [Validators.required]],
      apellidoPaterno             : ['', [Validators.required]],
      apellidoMaterno             : [''],
      fechaNacimientoPaciente     : [''],
      edad                        : ['0'], 
      genero                      : [''],
      paisNacimiento              : [''], 
      entidadNacimiento           : [''],
      curp                        : [''],
      telefono                    : [''],
      estadoCivilPaciente         : [''],
      numeroExpediente            : [''],
      tipoDeSangre                : [''],
      correoPaciente              : [''],

      colonia                     : [''],        
      cpPaciente                  : [''],   
      estadoPaciente              : [''],
      paisPaciente                : [''],
      localidad                   : [''],
      callePaciente               : [''],
      numeroIntPaciente           : [''],
      numeroPaciente              : [''],
      referenciaPaciente          : [''],
      referenciaPaciente2         : [''],

      razoncocial1RFC             : [''],
      cpRazonSocial               : [''],
      razonSocial1                : [''],
      razonSocial1Municipio       : [''],
      razonSocial1Estado          : [''],
      razonSocial1Calle           : [''],
      razonsocial1Telefono        : [''],
      razonSocial1colonia         : [''],
      correoRazonSocial1          : [''],
      razonSocial1NumInt          : [''],
      razonSocial1NumExt          : [''],
      razonSocialPais             : [''],

      contactoEmergencia1Nombre   : [''],
      contactoEmergencia1ApellidoPaterno: [''],
      contactoEmergencia1ApellidoMaterno: [''],
      contactoEmergencia1Edad     : [''],
      contactoEmergencia1Telefono : [''],
      contactoEmergencia1EstadoCivil: [''],
      contactoEmergencia1Genero   : [''],
      contactoEmergencia1Correo   : [''],
      contactoEmergencia1Parentesco: [''],
      disponente                  : ['RECEPTOR']
    })
  }
  edad(event){
    var fecha = new Date(event)
    this.fechaNacimientoNgForm = fecha.toISOString().slice(0,10)
    var hoy = new Date()
    var edad = (hoy.getTime() - fecha.getTime())
    var edadfinal = Number((edad / (31536000000 )))
    this.edadNgForm = Math.floor(edadfinal)
    this.registroForm.patchValue({
      edad: this.edadNgForm,
    });
  }
  genero(event){
    var genero = event
    this.registroForm.patchValue({
      genero: genero,
    });
  }
  estado(event){
    var estado = event
    this.registroForm.patchValue({
      entidadNacimiento: estado,
    })
    this.curp()
    //console.log(this.registroForm.value);
  }
  curp(){
   var nombre = this.registroForm.get('nombrePaciente').value
   var ape1 =  this.registroForm.get('apellidoPaterno').value
   var ape2 = this.registroForm.get('apellidoMaterno').value
   var genero = this.registroForm.get('genero').value
   var fecha = this.fechaNacimientoNgForm
   var estado = this.registroForm.get('entidadNacimiento').value
   var curp = fnCalculaCURP( nombre.toUpperCase(), ape1.toUpperCase(), ape2.toUpperCase(),fecha ,genero.toUpperCase(), estado );
   this.registroForm.patchValue({
    curp: curp,
  })
   
  }
  estadoCivil(event){
    var estadoCivil = event
    this.registroForm.patchValue({
      estadoCivilPaciente: estadoCivil,
    })
  }
  pais(event){
    var pais = event
    this.registroForm.patchValue({
      paisPaciente: pais,
    })
  }
  cogidoPostal(event,event2){
    this.estadocp = event2
    this.codigoPostal = event
    this.municipios = []
    this.asentamiento = []
    this.estadocp = ''

    if (event2 == false) {
      let data = {
        estado: '',
        codigoPostal: event
      }
      this._pacienteService.setCodigoPostal(data)
      .subscribe((resp:any) => { 
        this.estadocp = resp.data[0].cpEstado

        this.registroForm.patchValue({
          estadoPaciente: this.estadocp,
        })

        var result = resp.data.reduce(function (r, a) {
          r[a.cpMunicipio] = r[a.cpMunicipio] || [];
          r[a.cpMunicipio].push(a); 
          return r;
        },Object.create(null))
        for (const key in result) {
          if (result[key] != undefined) {
            this.municipios.push(key)
          }
        }

        resp.data.forEach(element => {
          this.asentamiento.push(element.cpAsentamiento)
        });

      })

    }

    if (event == false) {
      this.municipios = []
      this.asentamiento = []
      let data = {
        estado: event2,
        codigoPostal: ''
      }
      this._pacienteService.setCodigoPostal(data)
      .subscribe((resp:any) => { 
        this.registroForm.patchValue({
          estadoPaciente: this.estadocp,
        })
        this.zipCode = resp.data
        var result = resp.data.reduce(function (r, a) {
          r[a.cpMunicipio] = r[a.cpMunicipio] || [];
          r[a.cpMunicipio].push(a); 
          return r;
        },Object.create(null))
        for (const key in result) {
          if (result[key] != undefined) {
            this.municipios.push(key)
          }
        }
      })
    }
  }
  municipio(event){
    this.registroForm.patchValue({
      referenciaPaciente2: event,
    })
  }
  municipio2(event){
    this.zipCode.forEach(element => {
      if (element.cpMunicipio == event) {
        this.asentamiento.push(element.cpAsentamiento)
      }
    });
    this.registroForm.patchValue({
      referenciaPaciente2: event,
    })
  }
  ciudad(event){
    this.registroForm.patchValue({
      colonia: event,
    })
  }
  ciudad2(event){
  this.zipCode.forEach(element => {
      if (element.cpAsentamiento == event) {
        this.registroForm.patchValue({
          cpPaciente: element.codigoPostal
        })
      }
    });
    this.registroForm.patchValue({
      colonia: event,
    })
  }
  domicilioFiscal(event){
    this.datosfiscales = event
  }
  generoContacto(event){
    this.registroForm.patchValue({
      contactoEmergencia1Genero: event,
    })
  }
  estadoCivilContacto(event){
    this.registroForm.patchValue({
      contactoEmergencia1EstadoCivil: event,
    })
  }
  parentesco(event){
    this.registroForm.patchValue({
      contactoEmergencia1Parentesco: event,
    })
  }
  nuevoDisponente(){
    this.spinner.show();
    let sede = localStorage.getItem('cede');
    this._pacienteService.setPacientes(Object.assign(this.registroForm.value,{nomenclaturaRegistro:sede},{sede:sede}) ,sede).subscribe((data) => {
        if(  data['ok'] ){
          this.spinner.hide();
          Swal.fire({
              icon: 'success',
              title: '',
              text: 'DISPONENTE REGISTRADO',
            })
          this._router.navigateByUrl('/banco/sangre')
        }
      });
  }

}
