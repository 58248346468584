import { Component, OnInit } from '@angular/core';

import { Personal } from '../../interfaces/personal';
import {  ModulesService } from '../../services/modules/modules.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  public usuario: Personal;
  public modules:any[]=[];

  constructor(
    public router: Router,
    public modulesService: ModulesService
  ) { }

  ngOnInit(): void {
    this.getRoleLocalStorage();
    // this.getModuleByRole();
  }

  getRoleLocalStorage(){

    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    if( this.usuario == null || this.usuario == undefined){
      return;
    }else{
      this.getModuleByRole();
    }
  }

   getModuleByRole(){
    this.modulesService.getModules( this.usuario._id )
    .subscribe( (data:any)  => {
      console.log( data )
      this.modules = data.data;
    });

  }

}
