import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PaquetesService } from 'src/app/services/paquetes/paquetes.service';
import { FichaInfo } from '../../../clases/ficha-info-paciente';
@Component({
  selector: 'ficha-info-user',
  templateUrl: './ficha-info-user.component.html',
  styleUrls: ['./ficha-info-user.component.css']
})
export class FichaInfoUserComponent implements OnInit {
  @Input() pacienteInfo: FichaInfo;
  @Input() title: true;
  @Output() usuarioSeleccionado = new EventEmitter();
  public id = ""
  public fecha:Date;

  public riesgo={
    valores:""
  }

  constructor(public _paquete:PaquetesService){}

  ngOnInit(){
  }

  habilitarRiesgo(){
    this.usuarioSeleccionado.emit(this.riesgo);
  }

}
