import { Component, OnInit } from '@angular/core';
import { Personal } from '../../interfaces/personal';
import {  ModulesService } from '../../services/modules/modules.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-bar-censur',
  templateUrl: './nav-bar-censur.component.html',
  styleUrls: ['./nav-bar-censur.component.css']
})
export class NavBarCensurComponent implements OnInit {
  public usuario: Personal;
  public modules:any[]=[];

  constructor(public router: Router,
              public modulesService: ModulesService) { }

  ngOnInit(): void {
    this.getRoleLocalStorage();
    // this.getModuleByRole();
  }

  getRoleLocalStorage(){

    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    if( this.usuario == null || this.usuario == undefined){
      return;
    }else{
      this.getModuleByRole();
    }
  }

   getModuleByRole(){
    this.modulesService.getModules( this.usuario._id )
    .subscribe( (data:any)  => {
      console.log( data )
      this.modules = data.data;
    });

  }
}
