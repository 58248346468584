import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { buscarReceptor } from 'src/app/clases/helpers/filtroNameReceptores';
import { BancodesangreService } from 'src/app/services/bancodeSangre/bancodesangre.service';

@Component({
  selector: 'app-bitacora-reacciones-adversas',
  templateUrl: './bitacora-reacciones-adversas.component.html',
  styleUrls: ['./bitacora-reacciones-adversas.component.css']
})
export class BitacoraReaccionesAdversasComponent implements OnInit {
  public  filtropacientes = '';
  public pedidos:any[]=[];
  public fecha;
  public pagina = 0;
  public resultado:any;
  public totalPedidos:string;

  constructor(private _banco: BancodesangreService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getOrdenes();
  }

  typeSearchPatients(){
    if(this.filtropacientes.length != 0){
      if(this.filtropacientes.length > 3){
        this.pedidos = buscarReceptor(this.pedidos, this.filtropacientes);
      }
    }else{
      this.getOrdenes();
    }
  }

  getOrdenes(){
    this.pedidos = [];
    this.spinner.show();
    this._banco.getOrdenPedido().subscribe((resp:any) =>{
      this.setOrdenes(resp.data);
      this.totalPedidos = resp.data.results;
    })
  }

  setOrdenes(resp){
    console.log(resp);
    
    resp.forEach(element => {
      if(element.pedido != undefined){
        if(element.paciente != null){
          if(element.estatus == 'RECEPTOR'){
            this.pedidos.push(element);
            this.spinner.hide();
          }
        }
      }      
    });
    this.spinner.hide();
  }
}
