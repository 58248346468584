import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';
import swal from 'sweetalert'

@Component({
  selector: 'app-pacientes',
  templateUrl: './pacientes.component.html',
  styleUrls: ['./pacientes.component.css']
})
export class PacientesComponent implements OnInit {

  public pacientes2:any[] = [];
  public totalpaciente:string;
  public pagina = 0;
  public  filtropacientes = '';
  public filterPost = '';

  private unsubcribe$ = new Subject<void>();

  constructor(public _pacienteService: PacientesService) { }

  ngOnInit(): void {
    this.obtenerPacientes();
    this.focusSearchPatients();
  }

  obtenerPacientes() {

    this._pacienteService.getPacientesAll()
    .subscribe( (data: any) => {
      // console.log( data );
      if( data['message']   === 'No hay pacientes' ) {
        swal("Aun no hay pacientes",{icon:"warning"})
        /* alert('Aun no hay pacientes') */
        return;
      }
       this.pacientes2 = data.data;
       this.totalpaciente = data.data.results;

    });

  }

  typeSearchPatients(){
    
    if( this.filtropacientes.length > 3 ){
      this._pacienteService.getPacientePorNombre( this.filtropacientes )
      .subscribe(  data => {
        this.pacientes2 = data['data'];
        // console.log(this.pacientes2);
        
      });
    }else if(this.filtropacientes == ''){
      this.obtenerPacientes();
      this.focusSearchPatients();
    }

    /* if( this.filtropacientes.length == 0 ){
      this.pacientes2 = this.totalpaciente;
    } */
  }



  focusSearchPatients(){
    const input: HTMLInputElement = document.querySelector('#busquedaPaciente');
    input.select();
  }


  paginaAnterior(){
    this.pagina = this.pagina - 8;
    this._pacienteService.getPacientes( this.pagina)
    .subscribe( (data: any) => {
      // console.log( data.users );
      this.pacientes2 = data.users;
    } )
  }


  siguentePagina(){
    this.pagina += 8;
    this._pacienteService.getPacientes( this.pagina)
    .subscribe( (data: any) => {
      // console.log( data.users );
      this.pacientes2= data.users;
    } )
  }

    ngOnDestroy(){
      this.unsubcribe$.next();
      this.unsubcribe$.complete();
    }


}
