<div class="container-fluid"></div>
<h1 h1 class="centerxd"> BITACORA DE ENFERMERÍA CONSULTA EXTERNA</h1>
<div class="center">
    <section>
        <div class="card" style="border-radius: 30px;">
            <div class="card-header header">
                <div class="row">
                    <div class="col-1 text_header">
                        <p>#</p>
                    </div>
                    <div class="col text_header">
                        <p>Nombre</p>
                    </div>
                    <div class="col text_header">
                        <p>Curp</p>
                    </div>
                    <div class="col text_header">
                        <p>Edad</p>
                    </div>
                    <div class="col text_header">
                        <p>Servicio médico</p>
                    </div>
                    <div class="col text_header">
                        <p>Médico</p>
                    </div>
                    <div class="col text_header">
                        <p>Hora de cita</p>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div *ngFor="let listaEsperaP of listaEspera; let i = index">
                    <div class="row clickss" [routerLink]="['/ficha-enfermeria-01', listaEsperaP._id]" (click)="cambioDeEstadoConsulta(listaEsperaP._id)">
                        <div class="col-1">
                            <p class="nombre">{{i+1}}</p>
                        </div>
                        <div class="col ">
                            <p class="nombre">{{ listaEsperaP.paciente.nombrePaciente }} {{ listaEsperaP.paciente.apellidoMaterno }} {{ listaEsperaP.paciente.apellidoPaterno }}</p>
                        </div>
                        <div class="col ">
                            <p class="nombre">{{listaEsperaP.paciente.curp}}</p>
                        </div>
                        <div class="col ">
                            <p class="nombre">{{listaEsperaP.paciente.edad}}</p>
                        </div>
                        <div class="col ">
                            <p class="nombre">{{listaEsperaP.tipoDeServicio}}</p>
                        </div>
                        <div class="col ">
                            <p class="nombre">{{listaEsperaP.doctorAPasar}}</p>
                        </div>
                        <div class="col ">
                            <p class="nombre">{{listaEsperaP.horaIngreso}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer"></div>
        </div>
    </section>
    <div class="btn">

        <button class="btn btn-primary" (click)="imprimirBitacora()">Imprimir Bitácora</button>

    </div>

</div>