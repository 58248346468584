import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-servicio-individual',
  templateUrl: './servicio-individual.component.html',
  styleUrls: ['./servicio-individual.component.css']
})
export class ServicioIndividualComponent implements OnInit {

   // data de los servicios
   public servicios:string;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.servicios = this.activatedRoute.snapshot.paramMap.get('servicio');
  }

}
