<!-- CREACIÓN DE REPORTES -->


<div class="container-fluid mt-3">

    <div class="row">
        <div class="col-md-4">
            <input type="date" class="form-control" name="fecha1" id="fecha1" [(ngModel)]="fechas.fecha1">
        </div>

        <div class="col-md-4">
            <input type="date" name="fecha2" class="form-control" id="fecha2" [(ngModel)]="fechas.fecha2">
        </div>
        <div class="col-md-2">
            <button class="principalButton" (click)="buscarPorFechas()"> Buscar </button>
        </div>
    </div>
</div>

<!-- TODO: AGREGAR LAS VALIDACIONES DE FECHAS A LOS INPUTS -->

<div style="margin-top: 2%;" class="col-xs-12" id="tabla">
    <div class="cardd" style="border-radius: 30px;">
        <div style="background-color: white;" class="card-heder header">
            <div class="row">
                <div class="col text_header">
                    <p>TOTAL</p>
                </div>

                <div class="col text_header">
                    <p>GANANCIAS</p>
                </div>
            </div>
            <div class="row mt-2 ">
                <div class="col">
                    <p class="text-primary center"><b>  {{ total | currency }}  </b></p>
                </div>

                <div class="col">
                    <p class="text-primary center">
                        <b>{{ganancias | currency}}</b>
                    </p>
                </div>
            </div>
        </div>

    </div>

</div>

<div style="margin-top: 2%;" class="col-xs-12" id="tabla">
    <div class="card" style="border-radius: 30px;">
        <div class="card-heder header">
            <div class="row">
                <div class="col-1 text_header white">
                    <p> # </p>
                </div>
                <div class="col text_header white">
                    <p>FECHA</p>
                </div>
                <div class="col text_header white">
                    <p>PACIENTE</p>
                </div>


                <div class="col text_header white">
                    <p>TOTAL</p>
                </div>
                <div class="col text_header white">
                    <p>UTILIDAD</p>
                </div>
                <div class="col text_header white">
                    <p>MONTO</p>
                </div>
            </div>
        </div>


        <div class="card-body">
            <div class="row" *ngFor="let pago of pagos; let i = index">
                <div class="col-1">
                    <p class="card-text"> {{ i +1 }} </p>
                </div>
                <div class="col">
                    <p class="card-text">
                        {{ pago.fecha | date }}
                    </p>
                </div>

                <div class="col" [routerLink]="['/pago', pago._id]">

                    <p class="card-text">
                        {{ pago.paciente.nombrePaciente | uppercase }} {{ pago.paciente.apellidoPaterno | uppercase }} {{ pago.paciente.apellidoMaterno | uppercase }}
                    </p>
                </div>


                <div class="col">
                    <p class="card-text"> {{ pago.totalCompra | currency }} </p>
                </div>
                <div class="col">
                     <p class="card-text"> {{ utilidad2[i] }}</p> 
                </div>
                <div class="col">
                    <p class="card-text">{{ util[i]  | currency }}</p>
                </div>
            </div>

        </div>

    </div>
</div>
