import { Component, OnInit, OnDestroy } from '@angular/core';

import { WsLoginService } from 'src/app/services/sockets/ws-login.service';
import { LoginService  }  from  '../../services/login.service';
import  PacienteStorage from '../../clases/pacientes/pacientes.class';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {
  public usuario;
  public chatModal = false;

  constructor(public wsLogin:WsLoginService,
              public _loginService: LoginService) { }


  ngOnInit() {
    this.getRole()
    this.wsLogin.mostarUsuario();
  }
  
  removePacienteStorage(){
    const eliminarPacienteStorage  = new PacienteStorage();
    eliminarPacienteStorage.removePacienteStorage();
  }

  getRole(){
    this.usuario =  JSON.parse (localStorage.getItem('usuario'));
    if( this.usuario == null || this.usuario == undefined ){
      this._loginService.logout();
      this.wsLogin.adios();
      return;
    }else {
      this.wsLogin.login( this.usuario );
    }
  }

  estadoChat(event ){
    this.chatModal = true;
  }

  abrirVentanaChat(event){
    this.chatModal = true;
  }

  cerrarVentanaChat(event){
    this.chatModal = false;
  }

}
