<section class="row">
    <div class="col-md-12">
        <ficha-info-user [pacienteInfo]="paciente"></ficha-info-user>

    </div>
</section>

<div class="col-md-12 col-lg-12">
    <div class="card" style="border-radius: 30px; margin: 0px 25px; font-weight: 800;">
        <div class="card-heder header">
            <div class="row">
                <div class="col text_header">
                    <p>No</p>
                </div>
                <div class="col text_header">
                    <p>Nombre de Estudio</p>
                </div>
                <div class="col text_header">
                    <p>Estado</p>
                </div>

            </div>
        </div>
        <div class="card-body">
            <div *ngFor="let item of pedido.estudios | paginate:{id:'lista',
                itemsPerPage: 5 ,
                currentPage:pagina,
                totalItems:total}; let i=index">
                <div class="row" [routerLink]="['/final/obtenidos',item.idEstudio]" (click)="grabarLocalStorage()">
                    <div class="col" style="text-align: center;">
                        <p>{{ i +1 }}</p>
                    </div>
                    <div class="col" style="text-align: center;">
                        <p>{{item.nombreEstudio}}</p>
                    </div>
                    <div class="col" style="text-align: center;">
                        <p>{{item.estado | uppercase}}</p>
                    </div>

                </div>
            </div>
        </div>
        <div class="offset-md-4">
            <pagination-controls class="paginador" id="lista" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
        </div>
    </div>
</div>







<!-- <div style="overflow-x: scroll; height: 13rem;">



    <div class="row">
        <div class="col-md-12">

            <table class="table table-hover colors izq" id="bitacora">

                <thead class="center" style="background-color: #03318C;">

                    <tr style="color: white;">

                        <th scope="col"> No.</th>

                        <th scope="col"> Nombre de Estudio </th>
                        <th scope="col"> Estado </th>
                        <th scope="col"> Resultados </th>

                    </tr>


                </thead>

                <tbody class="center" style="background-color: white;color: black;">

                    <tr *ngFor="let item of pedido.estudios; let i = index">


                        <th> {{ i +1 }}</th>

                        <th [routerLink]="['/final/obtenidos',item.idEstudio]" (click)="grabarLocalStorage()">{{item.nombreEstudio}} </th>
                        <th>
                            {{pedido.estadoPedido}}</th>
                        <th>

                        </th>

                    </tr>



                </tbody>

            </table>
        </div>







    </div>



</div> -->