<h1 class="centerxd">BITACORA DE CONSULTA EXTERNA</h1>


<div class="container">
    <div class="row">
        <div class="col-md-12">

            <table class="table" id="bitacora" style="margin-left: -3rem;">
                <thead style="background-color: #03318c;">
                    <tr style="color: white; font-size: 13px;">
                        <th> N° </th>
                        <th> FECHA </th>
                        <th> HORA </th>
                        <th> NOMBRE DEL PACIENTE </th>
                        <th> EDAD </th>
                        <th> SEXO </th>
                        <th> DIANOSTICO </th>
                        <th> CURP </th>
                        <th> ENFERMERÍA </th>
                        <th> MÉDICO </th>
                        <th> TURNO </th>
                        <th> OBSERVACIONES </th>
                    </tr>
                </thead>
                <tbody>
                    <tr style="background-color: white; color: black;" *ngFor="let item of consultas; let i = index">
                        <td>
                            {{ i +1}}
                        </td>
                        <td>
                            {{ item.fechaIngreso }}
                        </td>
                        <td>
                            {{ item.horaIngreso }}
                        </td>
                        <td *ngIf="item.paciente.consultas === 1 || item.paciente.consultas == 0" [routerLink]="['/historia/clinica', item._id]" (click)="cambiarEstado(item._id)">
                            {{ item.paciente.nombrePaciente }} {{ item.paciente.apellidoPaterno }} {{ item.paciente.apellidoMaterno }}
                        </td>
                        <td *ngIf="item.paciente.consultas > 1" [routerLink]="['/hoja/evolucion', item._id]" (click)="cambiarEstado(item._id)">
                            {{ item.paciente.nombrePaciente }} {{ item.paciente.apellidoPaterno }} {{ item.paciente.apellidoMaterno }}
                        </td>
                        <td>
                            {{ item.paciente.edad }}
                        </td>
                        <td>
                            {{ item.paciente.genero | titlecase }}
                        </td>
                        <td>
                            {{ item.diagnosticoActual }}
                        </td>
                        <td>
                            {{ item.paciente.curp }}
                        </td>
                        <td>
                            {{ item.enfermeraAtendio }}
                        </td>

                        <td> Médico </td>
                        <td> Turno </td>

                        <td>

                            {{ item.observaciones }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3 offset-md-5 mb-3">

            <button class="principalButton" (click)="imprimirBitacora()"> Imprimir </button>
        </div>
    </div>
</div>