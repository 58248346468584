import { gaurdarCotizacion, getDataCarrito } from 'src/app/functions/storage/storage.functions';
import * as swal from 'sweetAlert';
import { getCarritoStorage } from '../../../app/functions/storage/pacienteIntegrados';

export default class Carrito {

  // CARRITO SIN MEBRESIA

    public carrito = {
        totalSin: 0,
        totalCon: 0,
        items: []
      };

      public carritoMembresia = {
        items:[],
        total:0
      }

    constructor() {
      this.obtenerSotorageCarrito();
    }

    public obtenerSotorageCarrito(){

      this.carrito = getCarritoStorage();

      if ( this.carrito == null ){
          this.carrito = {
            totalSin: 0,
            totalCon: 0,
            items: []
          };
      }
      return this.carrito;
    }

    public agregarItem(event, item){
      const estudio = {
        nombreEstudio: item.DESTINO,
        precioSin:"",
        precioCon:"",
        name: item.name,
        idEstudio: item._id
      }

        if(  estudio.nombreEstudio == null || estudio.nombreEstudio == "" ){
            estudio.nombreEstudio = item.ESTUDIO;
        }
        if( event.path[1].classList.contains('precioPublico')  ){
            // en esta parte pasamos el precio de día con y sin


            if( item.ESTUDIO == 'MEMBRESIA'  ){
                estudio.precioSin = "0";

              }else {

              estudio.precioSin = item.PRECIO_PUBLICO_DIA;
              estudio.precioCon = item.PRECIO_MEMBRESIA_DIA;

              }

            // pasamos el precio redondo día con y sin
            this.sumarTotal(  estudio.precioSin , estudio.precioCon );
            this.carrito.items.push( estudio );

          }else if (  event.path[1].classList.contains('precioRedondoDia') )  {

              estudio.precioSin = item.PRECIO_PUBLICO_REDONDO_DIA;
              estudio.precioCon = item.PRECIO_MEMBRESIA_REDONDO_DIA;

              this.sumarTotal( estudio.precioSin, estudio.precioCon );
              this.carrito.items.push( estudio );

          }else if( event.path[1].classList.contains('precioNoche') ) {



              estudio.precioSin = item.PRECIO_PUBLICO_NOCHE;
              estudio.precioCon = item.PRECIO_MEMBRESIA_NOCHE;

              this.sumarTotal( estudio.precioSin, estudio.precioCon  );
              this.carrito.items.push( estudio );

          }else if( event.path[1].classList.contains('precioRedondoNoche')  ){


            estudio.precioSin = item.PRECIO_PUBLICO_REDONDO_NOCHE;
            estudio.precioCon = item.PRECIO_MEMBRESIA_REDONDO_NOCHE;

              this.sumarTotal(  estudio.precioSin, estudio.precioCon );
              this.carrito.items.push( estudio );

          }else  if( event.path[1].classList.contains('precioPublicoOtros')  ){


            if( item.ESTUDIO === 'MEMBRESIA' ){
              estudio.precioSin = "0";
              estudio.precioCon = item.PRECIO_PUBLICO;

            }else{
              estudio.precioCon = item.PRECIO_MEMBRESIA;
              estudio.precioSin = item.PRECIO_PUBLICO;
            }



            this.sumarTotal( estudio.precioSin, estudio.precioCon );
            this.carrito.items.push( estudio );


          }else  if( event.path[1].classList.contains('precioPublicoUrgencia')  ){


            if( item.ESTUDIO === 'MEMBRESIA' ){
              estudio.precioSin = "0";
              estudio.precioCon = item.PRECIO_PUBLICO;

            }else{
              estudio.precioCon = item.PRECIO_MEMBRESIA_URGENCIA;
              estudio.precioSin = item.PRECIO_PUBLICO_URGENCIA;
            }



            this.sumarTotal( estudio.precioSin, estudio.precioCon );
            this.carrito.items.push( estudio );


          }else  if( event.path[1].classList.contains('precioPublicoHopi')  ){


            if( item.ESTUDIO === 'MEMBRESIA' ){
              estudio.precioSin = "0";
              estudio.precioCon = item.PRECIO_PUBLICO;

            }else{
              estudio.precioCon = item.PRECIO_MEMBRESIA_HOSPITALIZACION;
              estudio.precioSin = item.PRECIO_PUBLICO_HOSPITALIZACION;
            }



            this.sumarTotal( estudio.precioSin, estudio.precioCon );
            this.carrito.items.push( estudio );


          }else  if( event.path[1].classList.contains('precioHospiUrgen')  ){


            if( item.ESTUDIO === 'MEMBRESIA' ){
              estudio.precioSin = "0";
              estudio.precioCon = item.PRECIO_PUBLICO;

            }else{
              estudio.precioCon = item.PRECIO_MEMBRESIA_HOSPITALIZACION_URGENCIA;
              estudio.precioSin = item.PRECIO_PUBLICO_HOSPITALIZACIO_URGENCIA;
            }



            this.sumarTotal( estudio.precioSin, estudio.precioCon );
            this.carrito.items.push( estudio );


          }

          let carritoString = JSON.stringify( this.carrito );

            gaurdarCotizacion( carritoString );
            this.carrito = getDataCarrito();
            return this.carrito;

    }


   public  sumarPreciosMembresia( precio  ){
        // console.log( precio);
        let precioTrim  =  precio.replace('$', '');
        let costoSinComa = precioTrim.replace(',', '');

        let precioNumber = parseFloat(  costoSinComa );
        this.carritoMembresia.total =   this.carritoMembresia.total + precioNumber;
        // console.log( this.carritoMembresia );
      }

   public  restarTotal( precioSin, precioCon  ) {


        let precioSinTrim  =  precioSin.replace('$', '');
        let precioSinComa = precioSinTrim.replace(',', '');
        // aca le quito la coma si es que trae
        let precioSinMembresiaNumber = parseFloat( precioSinComa );

        let precioConTirm = precioCon.replace('$', '');
        let precioConMembresiaSinComa = precioConTirm.replace(',', '');
          // aca le quito la coma si es que la trae
        let precioConMembresiaNumber = parseFloat( precioConMembresiaSinComa );

        this.carrito.totalCon = this.carrito.totalCon - precioConMembresiaNumber;
        this.carrito.totalSin = this.carrito.totalSin - precioSinMembresiaNumber;

      }

     public  sumarTotal(  precioSin, precioCon  ){
        // se le quitan los caracteres $ y , al precio con membresia
        let precioConMembresia  = precioCon.replace('$', '');
        let precioConSinComa  = precioConMembresia.replace(',', '');
        let precioConMembresiaNumber = parseFloat( precioConSinComa );

        // se le quitan los caracteres $ y , al precio sin membresia
        let costoSin = precioSin.replace('$', '');
        let costoSinComa = costoSin.replace(',', '');
        let costoSinNumber = parseFloat( costoSinComa );

        this.carrito.totalSin = this.carrito.totalSin + costoSinNumber;
        this.carrito.totalCon = this.carrito.totalCon + precioConMembresiaNumber;


      }


      public  eliminar( id ) {


        this.carrito.items.forEach(  (item, index) => {
          // Agregar algun otro caso que se pueda dar
          if (index  === id ) {
            this.carrito.items.splice( index, 1 )
            if( item.precioSin && item.precioCon ){
              this.restarTotal( item.precioSin, item.precioCon );
            }else if( item.precioNoche ){
              this.restarTotal( item.precioNoche, item.precioNoche );
            }
          }
        });

          let  carritoString = JSON.stringify( this.carrito  );
           gaurdarCotizacion(  carritoString );
        //   swal("El servicio se ha eliminado del carrito",{icon:"success"});
      }

      public messageCompracion( ev, precioPublico, precioMembresia, item2:any ){
        let precioSinTrim  =  precioPublico.replace('$', '');
        let precioSinComaPublico = precioSinTrim.replace(',', '');
        let precioMemTrim  =  precioMembresia.replace('$', '');
        let precioMemComaMembresia = precioMemTrim.replace(',', '');

        // swal("Con membrecia ahorras:"+(precioSinComaPublico - precioMemComaMembresia),{icon:"success"});
        alert("Con membrecia ahorras:"+(precioSinComaPublico - precioMemComaMembresia));
      }


}



 // if( event.path[1].classList.contains('precioMembresia')  ){

            // let estudio = {
            //   nombreEstudio: item.DESTINO,
            //   precio: item.PRECIO_MEMBRESIA_DIA,
            //   name: item.name,
            //   idEstudio: item._id
            // }

          //   estudio.precioSin = item.PRECIO_MEMBRESIA_DIA;
          //   // estudio.precioCon = item.

          //   this.carritoMembresia.items.push( estudio );
          //   this.sumarPreciosMembresia( estudio.precio  );

          // }else



