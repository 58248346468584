import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';
import swal from 'sweetalert';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-receptores',
  templateUrl: './receptores.component.html',
  styleUrls: ['./receptores.component.css']
})
export class ReceptoresComponent implements OnInit {
  public pacientes2:any[] = [];
  public totalpaciente:string;
  public pagina = 0;
  public  filtropacientes = '';
  public filterPost = '';

  private unsubcribe$ = new Subject<void>();

  constructor(public _pacienteService: PacientesService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(){
    this.spinner.show();
    this.obtenerPacientes();
    this.focusSearchPatients();
  }

  obtenerPacientes() {
    let sede = localStorage.getItem('cede')
    this._pacienteService.getPacientesSede(sede)
    .subscribe( (data: any) => {
      this.pacientes2 = data.data;
      this.totalpaciente = data.data.results;
      this.spinner.hide();
    });
  }

  typeSearchPatients(){
    if( this.filtropacientes.length > 3 ){
      this._pacienteService.getPacientePorNombre( this.filtropacientes )
      .subscribe(  data => {
        this.pacientes2 = data['data'];
      });
    }else if(this.filtropacientes == ''){
      this.obtenerPacientes();
      this.focusSearchPatients();
    }
  }

  focusSearchPatients(){
    const input: HTMLInputElement = document.querySelector('#busquedaPaciente');
    input.select();
  }

  paginaAnterior(){
    this.pagina = this.pagina - 8;
    this._pacienteService.getPacientes( this.pagina)
    .subscribe( (data: any) => {
      this.pacientes2 = data.users;
    })
  }

  siguentePagina(){
    this.pagina += 8;
    this._pacienteService.getPacientes( this.pagina)
    .subscribe( (data: any) => {
      this.pacientes2= data.users;
    })
  }

  ngOnDestroy(){
    this.unsubcribe$.next();
    this.unsubcribe$.complete();
  }

}
