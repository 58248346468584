import { Component, OnInit } from '@angular/core';
import { ConsultaService } from 'src/app/services/consultas/consulta.service';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';


@Component({
  selector: 'app-regresos-lab',
  templateUrl: './regresos-lab.component.html',
  styleUrls: ['./regresos-lab.component.css']
})
export class RegresosLabComponent implements OnInit {

  public pagina = 0;
  public consultas:any=[];
  public totalAmbulancia: string;

  constructor(  public _consultaService: ConsultaService,public _consultaPaciente: PacientesService) { }

  ngOnInit(): void {

    this.obtenerCosnultaLab();

  }


  obtenerCosnultaLab(){
    this._consultaService.verListaLaboratorio()
    .subscribe( (data) =>   {
        this.consultas = data['data']
        this.totalAmbulancia = this.consultas.results;  
    });
  }
 
}
