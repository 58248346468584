import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { WsLoginService } from 'src/app/services/sockets/ws-login.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  @Input() nombreUsuario: string;
  public usuario = {
    role: ""
  };

  constructor(
    private wsLoginService: WsLoginService,
    public _loginService: LoginService,
    public router: Router
  ) { }

  ngOnInit(): void {
  }


  cerrarSesion(){


    this._loginService.logout();
    this.wsLoginService.adios();
    window.location.reload();
    // this.wsLoginService.desconectarUsuario(  this.usuario.role )
  }

}
