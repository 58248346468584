export default class Dates {
    
    constructor() {
    }

 

    getDateRest( fechaRegistro  ):number{

        let fecha1 = new Date( fechaRegistro );
        
        let anio, month, day;

          anio = `${fecha1.getFullYear()+1 }`;  
          month = `${fecha1.getMonth()+1}`;
          day = `${fecha1.getDate()}`;
          
        let fechaBien = `${anio}-${month}-${day}`;
    
        let anio2, mes2, dia2;
        anio2 = new Date().getFullYear();
        mes2 = new Date().getMonth()+1;
        dia2 = new Date().getDate();
                
        if(  mes2 < 9 ){
          mes2 =  `0${mes2}`;
        }
        if(  dia2 < 9 ){
          dia2 = `0${dia2}`;
        }

        let fechaHoy = `${anio2}-${mes2}-${dia2}`;
        
         let fechaHoyDate = Date.parse( fechaHoy ); 
        //  console.log(  fechaHoyDate );
        let fechaAVencerDate = Date.parse( fechaBien );
        let diferencia = Math.abs(  fechaHoyDate  -  fechaAVencerDate  ) ;
        
        diferencia = (diferencia/(1000*60*60*24));
        let diasRestantes =  Math.round( diferencia ) ;
        return diasRestantes;
    }

    getDate(){

      let day, month, year;

      day = new Date().getDate();
      month = new Date().getMonth()+1;
      year = new Date().getFullYear();
      
      if( day < 9  ){
        day = `0${day}`;
      }

      if( month  < 9){
        month = `0${month}`; 
      }

      // console.log( day, month, year );
      let today = `${year}-${month}-${day}`;
      return today;
    }

}