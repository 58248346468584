// IMPORTACIÓN DE LOS COMPONENTES PARA PAGOS
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';

import Carrito from 'src/app/clases/carrito/carrito';
import {SEDE} from 'src/app/config/conf';
//import {} from '../../../services/'
import Dates from 'src/app/clases/dates/dates';
import PacienteStorage from 'src/app/clases/pacientes/pacientes.class';
import { PagosService } from 'src/app/services/pagos/pagos.service';
import { getDataStorage } from 'src/app/functions/storage/storage.functions';
import Tickets from 'src/app/clases/tickets/tickets.class';
import swal from 'sweetalert';
import { eliminarStorage } from 'src/app/functions/storage/pacienteIntegrados';
// import {CarritoMembresiaStorage} from 'src/app/clases/carritoMembresía/storageCarritoMembresia.class';
// import { eliminarTodoPedido, getDataStorage } from 'src/app/functions/storage.funcion';
import { USGService } from '../../../services/usg/usg.service';
import { XrayService } from '../../../services/Rayos-x/xray.service'

@Component({
  selector: 'app-pago-servicios',
  templateUrl: './pago-servicios.component.html',
  styleUrls: ['./pago-servicios.component.css']
})
export class PagoServiciosComponent implements OnInit {




  public fecha = "";

  public paciente = {

    nombrePaciente:'',
    apellidoPaterno:'',
    apellidoMaterno: '',
    edad:0,
    genero:'',
    direccion:{
      callePaciente:""
    },
    id:'',
    membresiaActiva:false,
    _id:""
  }
  public IVaDEl16 = 0;
  public positionYPDF = 100;

  public infoVenta = {

    paciente:"",
    nombrePaciente:"",
    vendedor:"",
    fecha:"",
    hora:"",
    estudios:[],
    efectivo:false,
    doctorQueSolicito:"",
    anticipo: false,
    montoEfectivo:0,
    montoAnticipo: 0,
    sede:"",
    totalCompra:0,
    prioridad:"",
    compraConMembresia:true,
    status: ""

  }

  public btnValidacion=true;

  public totalSinMembresia = 0;

  public carrito = {
    totalSin: 0,
    totalCon: 0,
    items: []
  };

  public totalConIva=0;
  public totalCarritoMembresia = 0;

  public pedidosLaboratorios = {

    estudios:[],
    idPaciente:"",
    fecha:"",
    hora:"",
    medicoQueSolicita:"",
    sede:"",
    prioridad:"Programado"

  }

  public pedidosUltrasonido = {
    idPaciente:"",
    estudios:[],
    fecha:"",
    hora:"",
    medicoQueSolicita:"",
    sede:"",
    prioridad:"Programado"
  }

  public pedidosRayox = {
    idPaciente:"",
    estudios:[],
    fecha:"",
    hora:"",
    medicoQueSolicita:"",
    sede:"",
    prioridad:"Programado"
  }

  constructor(
    private _xrayService : XrayService,
    private _ultrasonidoService: USGService,
    private _pagoServicios:PagosService,
    private _router: Router,
    //private _ultrasonidoService: USGService
  ) { }

  ngOnInit(): void {


    this.obtenerPacienteStorage();
    this.obtenerCarrito();
    this.obtenerTotalCarrito();

  }

  obtenerTotalCarrito(){
    this.totalSinMembresia = this.carrito.totalSin;
    this.totalCarritoMembresia= this.carrito.totalCon;
  }

  setDatesVenta(){
    /* const dates = new Dates(); */
    //this.infoVenta.totalCompra = this.carrito.totalSin;

    this.fecha = this.infoVenta.fecha;

    this.infoVenta.hora = moment().format('LT');
    this.infoVenta.vendedor = getDataStorage()._id;
    this.infoVenta.paciente = this.paciente._id;
    if(this.infoVenta.paciente == undefined){
      this.infoVenta.paciente = this.paciente.id;
    }
    this.infoVenta.sede = SEDE;
    this.infoVenta.prioridad = "Programado"
    /* this.infoVenta.fecha = dates.getDate(); */
    /* if(this.paciente.membresiaActiva){
      this.carrito.items.push();
    } */
    this.infoVenta.estudios = this.carrito.items;
    if(this.paciente.membresiaActiva){
      this.infoVenta.totalCompra = this.carrito.totalCon;
    }else{
      this.infoVenta.totalCompra = this.carrito.totalSin;
      this.infoVenta.compraConMembresia= false
    }
    if(this.infoVenta.efectivo){
      this.infoVenta.status="Pagado";
    }else{
      this.infoVenta.status="Pendiente"
    }

  }

  setDatesPedidos (){
    // this.pedidosLaboratorios.fecha = moment().format('l');
    const datesPedidoLab = new Dates();
        // configuracion de los pedidos de laboratorio
    this.pedidosLaboratorios.fecha = this.infoVenta.fecha;
    this.pedidosLaboratorios.hora = moment().format('LT');
    this.pedidosLaboratorios.medicoQueSolicita = this.infoVenta.doctorQueSolicito;
    this.pedidosLaboratorios.sede = SEDE;
    this.pedidosLaboratorios.idPaciente = this.paciente._id;
    if(this.pedidosLaboratorios.idPaciente == undefined){
      this.pedidosLaboratorios.idPaciente = this.paciente.id;
    }
    // configuracion de los pedidos de ultrasonido
    this.pedidosUltrasonido.hora = moment().format('LT');
    this.pedidosUltrasonido.medicoQueSolicita = this.infoVenta.doctorQueSolicito;
    this.pedidosUltrasonido.idPaciente = this.paciente._id;
    if(this.pedidosUltrasonido.idPaciente == undefined){
      this.pedidosUltrasonido.idPaciente = this.paciente.id;
    }
    this.pedidosUltrasonido.fecha = this.infoVenta.fecha;
    this.pedidosUltrasonido.sede = SEDE;

    this.pedidosRayox.idPaciente = this.paciente._id;
    if(this.pedidosRayox.idPaciente == undefined){
      this.pedidosRayox.idPaciente = this.paciente.id;
    }
    this.pedidosRayox.fecha = this.infoVenta.fecha;
    this.pedidosRayox.medicoQueSolicita = this.infoVenta.doctorQueSolicito;
    this.pedidosRayox.sede = SEDE;
  }

  obtenerPacienteStorage(){

    const pacienteStorage = new PacienteStorage();
    this.paciente = pacienteStorage.verPacienteConMembresia();
  }

  obtenerCarrito(){
    let carritoSinMembresia = new Carrito();
    this.carrito = carritoSinMembresia.obtenerSotorageCarrito();
    this.totalSinMembresia = this.carrito.totalSin;
    this.totalCarritoMembresia= this.carrito.totalCon;
  }



  validarBoton(valor){
    if( valor == 0  ){
      this.btnValidacion = false;
    }
  }


  eliminarCarritoSinMembresia( item  ){
    // console.log(item );
    let carritoMembresia = new Carrito();
    carritoMembresia.eliminar( item );
    this.obtenerCarrito();

  }


  calcularNuevoTotalEfectivo(){


    if(this.paciente.membresiaActiva){
      if(this.carrito.totalCon < this.infoVenta.montoEfectivo){
        /* alert("monto mayor") */
        swal("Monto mayor", {
          icon: "error",
        });
      }else{
        if(this.infoVenta.anticipo){
          this.infoVenta.anticipo = false
          this.obtenerCarrito();
          this.infoVenta.montoAnticipo=0
        }
        this.totalCarritoMembresia = this.carrito.totalCon - this.infoVenta.montoEfectivo;
        this.infoVenta.compraConMembresia = true;
        this.validarBoton(this.totalCarritoMembresia);
      }
    }else{
      if(this.carrito.totalSin < this.infoVenta.montoEfectivo){
        /* alert("monto mayor") */
        swal("Monto mayor", {
          icon: "error",
        });
      }else{
        if(this.infoVenta.anticipo){
          this.infoVenta.anticipo = false
          this.obtenerCarrito();
          this.infoVenta.montoAnticipo=0
        }
        this.totalSinMembresia = this.carrito.totalSin - this.infoVenta.montoEfectivo;
        this.infoVenta.compraConMembresia = false;
        this.validarBoton(this.totalSinMembresia);
      }
    }
    
  }

  /* calcuarMontoTarjetaDebito(){
    console.log(this.infoVenta.montoTarjetaDebito);
    console.log(this.totalSinMembresia);
    if(this.paciente.membresiaHabilitada){
    if(this.infoVenta.montoTarjetaDebito > this.totalCarritoMembresia){
      alert("monto mayor")
    }else{
      this.totalCarritoMembresia = this.totalCarritoMembresia  - this.infoVenta.montoTarjetaDebito;
      this.validarBoton(this.totalCarritoMembresia);
    }
    }else{
    if(this.infoVenta.montoTarjetaDebito > this.totalSinMembresia){
      alert("monto mayor")
    }else{
      this.totalSinMembresia = this.totalSinMembresia  - this.infoVenta.montoTarjetaDebito;
      this.validarBoton(this.totalSinMembresia);
    }
    }
  } */

/*   calcularMontoTarjetaCredito(){
    if(this.paciente.membresiaHabilitada){
      if(this.infoVenta.montoTarjteCredito > this.totalCarritoMembresia){
        alert("monto mayor")
      }else{
        this.totalCarritoMembresia = this.totalCarritoMembresia  - this.infoVenta.montoTarjteCredito;
        this.validarBoton(this.totalCarritoMembresia);
      }
    }else{
      if(this.infoVenta.montoTarjteCredito > this.totalSinMembresia){
        alert("monto mayor")
      }else{
        this.totalSinMembresia = this.totalSinMembresia  - this.infoVenta.montoTarjteCredito;
        this.validarBoton(this.totalSinMembresia);
      }
    }
  } */


  calcularMontoAnticipo(){
    this.btnValidacion = false;
    if(this.paciente.membresiaActiva){
      if(this.infoVenta.montoAnticipo > this.totalCarritoMembresia){
        alert("monto mayor")
      }else{
        if(this.infoVenta.efectivo){
          this.infoVenta.efectivo = false
          this.obtenerCarrito();
          this.infoVenta.montoEfectivo= 0
        }
        this.totalCarritoMembresia = this.totalCarritoMembresia - this.infoVenta.montoAnticipo;
        this.validarBoton(this.totalCarritoMembresia);
        this.calcularAnticipo(this.totalCarritoMembresia);
      }
    }else{
      if(this.infoVenta.montoAnticipo > this.totalSinMembresia){
        alert("monto mayor")
      }else{
        if(this.infoVenta.efectivo){
          this.infoVenta.efectivo = false
          this.obtenerCarrito();
          this.infoVenta.montoEfectivo= 0
        }
        this.totalSinMembresia = this.totalSinMembresia - this.infoVenta.montoAnticipo;
        this.validarBoton(this.totalSinMembresia);
        this.calcularAnticipo(this.totalSinMembresia);
      }
    }
  }

  calcularAnticipo(monto){
    this.infoVenta.montoAnticipo= (2 * monto)/100
  }


  generarTicket(folio){

    const tickets = new Tickets();
    tickets.printTicketSinMembresia( this.paciente, this.carrito ,  this.infoVenta, folio );

  }


/* calcularMontoTrajetaDebito(){

  if(  this.infoVenta.tarjetaDebito ){

      this.totalCarritoMembresia = this.totalCarritoMembresia - this.infoVenta.montoTarjteCredito;
    this.validarBoton(  this.totalCarritoMembresia );
  }
} */



/* agregarIva(){
  // agregamos la comision
   if(this.paciente.membresiaHabilitada){
     let iva = 0.0;

     if( this.infoVenta.tarjetCredito  ){

       iva = 1.9;

     }else if( this.infoVenta.tarjetaDebito ){

       iva = 2.5;
     }


       let totalIva = (( this.totalCarritoMembresia * iva ) / 100);
       this.totalConIva =  this.totalCarritoMembresia + totalIva;
       this.totalCarritoMembresia =  Math.round(this.totalConIva);
   }else{
     let iva = 0.0;

     if( this.infoVenta.tarjetCredito  ){

       iva = 1.9;

     }else if( this.infoVenta.tarjetaDebito ){

       iva = 2.5;
     }


       let totalIva = (( this.totalSinMembresia * iva ) / 100);
       this.totalConIva =  this.totalSinMembresia + totalIva;
       this.totalSinMembresia =  Math.round(this.totalConIva);
   }
} */


enviarPedido(){
  //this.infoVenta.estudios = this.carrito.items;
  //this.infoVenta.paciente = this.paciente._id;
  this.setDatesVenta();
  /* this.setDatesPedidos();
  console.log(this.pedidosUltrasonido);
  console.log(this.pedidosLaboratorios); */



  this._pagoServicios.venta( this.infoVenta)
  .subscribe( (data) => {
    if(  data['ok'] ){

      this.generarTicket(data['folio']);

        // se crea la tabla de las ventas
        swal("Venta exitosa", {
          icon: "success",
        });
        // ESTA INFORMCAIÓN SE ENVIA A LOS DIFERENTES SERVICIOS
        // seteamos las fechas
        this.setDatesPedidos();

          if (this.infoVenta.efectivo) {

            this.carrito.items.forEach((elemets: items) => {
                if( elemets.name  === 'laboratorio' ){
                  this.pedidosLaboratorios.estudios.push( elemets )
                }
                if(  elemets.name == "MEMBRESIA"  ){
                     this._router.navigateByUrl('/pacientes');
                }
                if( elemets.name == "ultrasonido"  ){
                  this.pedidosUltrasonido.estudios.push( elemets )
                }
                if (elemets.name == "xray"){
                  this.pedidosRayox.estudios.push(elemets)
                }
              });


            if( this.pedidosLaboratorios.estudios.length > 0 ){
              // this.setDatesPedidos();
              this._pagoServicios.pedidosLaboratorio( this.pedidosLaboratorios )
              .subscribe( data => console.log( data ));

            }
            if( this.pedidosUltrasonido.estudios.length > 0   ){
              this._ultrasonidoService.postPedidosUltra( this.pedidosUltrasonido )
              .subscribe( data => console.log( data ));
            }
            if ( this.pedidosRayox.estudios.length > 0){
              this._xrayService.postPedidosXray(this.pedidosRayox)
                                .subscribe(data => console.log(data));
            }
          }


          eliminarStorage();

          const eliminarPaciente = new PacienteStorage();
          eliminarPaciente.removePacienteStorage();

          this._router.navigateByUrl('/');


      }
  });

}



eliminar(i){
   const eliminarItem = new Carrito;
  eliminarItem.eliminar(i);
  this.obtenerCarrito();
  this.obtenerTotalCarrito();
  this.calcularMontoAnticipo();
}


/* calcularIva(){
  this.IVaDEl16 = ((this.carrito.totalSin  * 16 ) / 100);
  return this.IVaDEl16;

} */



}



interface items {

  nombreEstudio : string
  precioCon: string
  precioSin: string
  idEstudio: string
  name: string
}


