import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'generos'
})
export class GenerosPipe implements PipeTransform {

  transform(value: string, sexo=true): string {
    value= value.toLocaleLowerCase();
    let Genero= value;
     if (sexo){
       if(Genero == 'masculino'){
           Genero='H'
       }
       else if (Genero == 'femenino'){
         Genero='M'

       }
     }// fin if sexo
    return Genero;
  }

}



