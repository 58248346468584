import { numeroALetras  } from '../../functions/storage/generalFunctions';
import jsPDF from 'jspdf';
import { getDataStorage, getrolefunction } from 'src/app/functions/storage/storage.functions';


//dejale mejor el emiexsec, hay que ser mejores
export default class Tickets {
    constructor() {
    }
    public IVaCDEl16=0;
    public IVaDEl16 = 0;
    public positionYPDF = 55;

    public totalLetra = "";
    public totalLetraAnticipo="";
    public atendio = "";



  calcularIva( carrito, membresia  ){

    if(membresia){
      this.IVaDEl16 = ((carrito.totalCon  * 16 ) / 100);

    }else {
      this.IVaDEl16 = ((carrito.totalSin  * 16 ) / 100);

    }

    }



    printTicketSinMembresia( paciente, carrito:any, infoVenta:any, folio ){
      this.atendio = getDataStorage().nombre;
      if( paciente.membresiaActiva ){
        this.totalLetra = numeroALetras( carrito.totalCon, 'Pesos mexicanos' );
      }else{
        this.totalLetra = numeroALetras( carrito.totalSin, 'Pesos mexicanos' );
      }
      this.totalLetraAnticipo= numeroALetras(infoVenta.montoAnticipo, 'Pesos mexicanos');
     const doc: any = new jsPDF();

    doc.setFontSize(8);

     // REvisar estas funciones
     doc.text( 2 , 15,`No ticke: ${folio}         Fecha: ${infoVenta.fecha}`);
     doc.text(2, 20,`RFC: HGS111116J76                  Teléfono: 735-35-77-564`);
    //  doc.text(2, 25,`Atendió: ${infoVenta.vendedor} `);
     doc.text( 20, 25, `Gracias ${ paciente.nombrePaciente } ${paciente.apellidoPaterno} ${paciente.apellidoMaterno}` );
     doc.text(2, 30, `------------------------------------------------------------------------------------------------------`)
     doc.text(2,35,`           Estudio                                   Costo                `);
     doc.text(2, 40, `------------------------------------------------------------------------------------------------------`)

     carrito['items'].forEach(  item => {

      if( paciente.membresiaActiva ){
        
        doc.text(3, this.positionYPDF,`${item.nombreEstudio}`);
        doc.text(5,  this.positionYPDF+5,`$ ${item.precioCon}.00 MX` );
        doc.text(3, this.positionYPDF += 15, ``)

      }else {
        doc.text(3, this.positionYPDF+3,`${item.nombreEstudio}`);
        doc.text(5,  this.positionYPDF+6,`$ ${item.precioSin}.00 MX` );
        doc.text(3, this.positionYPDF += 15, ``)
        this.positionYPDF += 5;

      }

     });

     this.calcularIva(  carrito, paciente.membresiaActiva );
     if(infoVenta.anticipo){   
        if( paciente.membresiaActiva  ){
          doc.text( 15, this.positionYPDF+=5,  `El total:                                  $ ${carrito.totalCon}.00 MX`  );
          doc.text(15, this.positionYPDF+=5, `El anticipo:                              $ ${infoVenta.montoAnticipo}.00 MX`);
          doc.text(15, this.positionYPDF+=5, `El restante:                              $ ${carrito.totalCon-infoVenta.montoAnticipo}.00 MX`);

        }else {
          doc.text( 15, this.positionYPDF+=5,  `El total:                                  $ ${carrito.totalSin}.00 MX`  );
          doc.text(15, this.positionYPDF+=5, `El anticipo:                              $ ${infoVenta.montoAnticipo}.00 MX`);
          doc.text(15, this.positionYPDF+=5, `El restante:                              $ ${carrito.totalSin-infoVenta.montoAnticipo}.00 MX`);
        }
        doc.text(5, this.positionYPDF+=6, `Total con letra : `);
        doc.text(2, this.positionYPDF+=5, `${this.totalLetraAnticipo}` );
     }else{
        if( paciente.membresiaActiva ){
          doc.text(15, this.positionYPDF+=10, `El total sin I.V.A es:                 $ ${carrito.totalCon - this.IVaDEl16}.00 MX` );

        }else {
        doc.text(15, this.positionYPDF+=10, `El total sin I.V.A es:                   $ ${carrito.totalSin - this.IVaDEl16}.00 MX` );

        }

        doc.text( 15, this.positionYPDF+=5,  `El I.V.A es de:                         $ ${this.IVaDEl16}.00 MX`  );
    
        if( paciente.membresiaActiva  ){
          doc.text( 15, this.positionYPDF+=5,  `El total con I.V.A:                   $ ${carrito.totalCon}.00 MX`  );

        }else {
        doc.text( 15, this.positionYPDF+=5,  `El total con I.V.A:                     $ ${carrito.totalSin}.00 MX`  );
        }
        doc.text(5, this.positionYPDF+=6, `Total con letra : `);
        doc.text(2, this.positionYPDF+=5, `${this.totalLetra}` );
     }
     doc.text(25, this.positionYPDF+=10,  `Atendío: ${this.atendio}`);
     doc.text( 25, this.positionYPDF+=9, "Gracias por su Compra");
     doc.text( 8, this.positionYPDF +=5, "Recuerda usar cubrebocas   #QuédateEnCasa ");
     this.positionYPDF +=15;


     doc.save('TICKET');

     this.positionYPDF = 100;
    }
    calcularIvaCotizacion( pedido){
      this.IVaCDEl16 = ((pedido  * 16 ) / 100);
    }

    // sin membresia
    imprimirCotizacion( carrito ){
      this.atendio = getDataStorage().nombre;
      const totalLetra = numeroALetras( carrito.totalSin, 'Pesos mexicanos' );
    
      const doc: any = new jsPDF('p', 'mm', [ 300, 500]);
      doc.setFontSize(8);
        // REvisar estas funciones
        doc.text(2, 20,`RFC: HGS111116J76                  Teléfono: 735-35-77-564`);
        //  doc.text(2, 25,`Atendió: ${infoVenta.vendedor} `);
        doc.text( 20, 25, `Cotización horizonte` );
        doc.text(2, 30, `------------------------------------------------------------------------------------------------------`)
        doc.text(2,35,`           Estudio                                   Costo                `);
        doc.text(2, 40, `------------------------------------------------------------------------------------------------------`)
      
        this.calcularIvaCotizacion(carrito.totalSin);
    
      //  doc.addImage( ImagenTicketHorizonter, 'JPEG', 30, 5, 70, 50 );
      doc.setFontSize(10);
      carrito.items.forEach(  item => {
        doc.text(3, this.positionYPDF,`${item.nombreEstudio}`);
        doc.text(5,  this.positionYPDF+5,`$ ${item.precioSin}.00 MX` );
        doc.text(3, this.positionYPDF += 15, ``)
        this.positionYPDF += 5;
      });
      // this.calcularIva();
      doc.text(15, this.positionYPDF+=10, `El total sin I.V.A es:               $ ${carrito.totalSin - this.IVaCDEl16}.00 MX` );
      doc.text( 15, this.positionYPDF+=5,  `El I.V.A es de:                       $ ${this.IVaCDEl16}.00 MX`  );
      doc.text( 15, this.positionYPDF+=5,  `El total con I.V.A:                   $ ${carrito.totalSin}.00 MX`  );
      doc.text(5, this.positionYPDF+=6, `Total con letra : `);
      doc.text(2, this.positionYPDF+=5, `${totalLetra}` );
      doc.text(50, this.positionYPDF+=10,  `Atendío: ${this.atendio}`);
      doc.text(50, this.positionYPDF+=12, "Gracias por su Compra");
      doc.save('COTIZACION');
      this.positionYPDF = 100;

     }


}
