import { Component, OnInit } from '@angular/core';
import { XrayService } from 'src/app/services/Rayos-x/xray.service';


@Component({
  selector: 'app-bitacora-rayos-x',
  templateUrl: './bitacora-rayos-x.component.html',
  styleUrls: ['./bitacora-rayos-x.component.css']
})
export class BitacoraRayosXComponent implements OnInit {

  public consultas: any =[]
  public pagina = 0;
  public totalAmbulancia: string;
  constructor(  private _xrayService :XrayService) { }

  ngOnInit(): void {
    this.obtenerCosnultaXray();
  }

  obtenerCosnultaXray(){
    this._xrayService.getXray()
    .subscribe( (data) =>   {
      
        this.consultas = data['data'].reverse();
    });
  }

}
