import { Component, OnInit } from '@angular/core';
import { PagosService } from 'src/app/services/pagos/pagos.service';
import { SedesService } from 'src/app/services/ventas/sedes.service';
// import Swal from 'sweetalert2'

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent implements OnInit {

  public pagos = [];
  public total=0;
  public totalUtilidad=0;
  public util=[];
  public utilidad2=[];
  public monto=[];
  public utilidadesCompra =[];

  public fechas = {
    fecha1:"",
    fecha2:"",
    sede: ""
  }
  ganancias: number = 0;


  constructor(
    private _sedesServices: SedesService,
    private _pagosService: PagosService
  ) { }

  ngOnInit(): void {
    this.obtenerSede();
    this.obetenerPagos();
  }


  obtenerSede(){
    this.fechas.sede =  localStorage.getItem('cede');
    //  console.log(  this.fechas )
    // console.log( this.fechas.sede );
  }

  obetenerPagos(){
    this._sedesServices.verPedidosPagados(this.fechas.sede)
    .subscribe(  (data) => {
      // console.log(data)
      this.setPagos( data['data'] );
    });
  }

  setPagos( pagos ){


    this.pagos = pagos;
    // console.log(pagos);

    this.total = 0;

     this.pagos.forEach(  pago => {
       this.total += pago.totalCompra;
       this.monto.push(pago.totalCompra)
       //this.obtenerPorcentaje( pago.estudios )
        // console.log(this.total);
    });

    for (let i = 0; i < pagos.length; i++) {
      if (pagos[i].estudios.length != 1) {
        let x =0;

          for (let j = 0; j < pagos[i].estudios.length; j++) {
            const element = pagos[i].estudios[j].utilidad;
            x += element
          }
        this.utilidad2.push(x);

      } else {
        const element = pagos[i].estudios[0].utilidad;
        this.utilidad2.push(element)
      }

  }

  // console.log("utilidad "+ this.utilidad2);

  this.obtenerMontos(this.monto,this.utilidad2);
  /* console.log("con iteracion "+this.utilidad2);
    console.log("utilidad" + this.utilidad2.slice(2,6)); */

  }

  /*obtenerPorcentaje( items ){

    // this.utilidadesCompra = [];

    let utilidad = 0;

     items.forEach(element => {

      utilidad = element.utilidad + utilidad;

    });
    this.utilidadesCompra.push(utilidad );
    this.obtenerMontos(this.total,this.utilidad2);
    console.log(this.utilidadesCompra);
  }*/

  obtenerMontos(monto, utilidad){
    for (let i = 0; i < utilidad.length; i++) {
      const element = utilidad[i];
      // console.log("monto"+element);
      this.util.push((monto[i]*utilidad[i])/100);
    }

    for (let x = 0; x < this.util.length; x++) {
      const element = this.util[x];
      this.ganancias += element;
    }

    return this.util;
  }

  buscarPorFechas(){
    // console.log(  this.fechas );

    if( this.fechas.fecha1 == "" && this.fechas.fecha2 == ""  ){
      alert('Ingresa las fechas');
      return
    }

      this._pagosService.buscarPorFechasPagos(this.fechas)
      .subscribe(  data => {
        // console.log(data)
        this.setPagos( data['data'] );
      })
  }

}
