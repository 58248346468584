import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { URL } from '../../../config/conf';

@Injectable({
  providedIn: 'root'
})
export class Cie10Service {

  public url = URL;

  constructor(

    private _http: HttpClient

  ) { }

  getCiePorNombre ( diagnostico: String) {
    let uri = `${this.url}/cie10/${diagnostico}`;

    return this._http.get( uri )
    .pipe( map( (data) => {
      return data;
    }));
  }

}
