import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { LoginModule } from './login/login.module';
import { APP_ROUTES } from './app.routes';
import { ComponentsModule } from './components/components.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { PagesModule } from './pages/pages.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { ChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSpinnerModule } from "ngx-spinner";

//import { NgStepperComponent } from 'angular-ng-stepper';

// import { Generos } from './pipes/generos.pipe.pipe';
// import { GenerosPipe } from './pipes/generos.pipe';


// import { PagoServiciosConComponent } from './components/pago-pacientes/pago-pacientes.component';


@NgModule({
  declarations: [
    AppComponent,
    // Generos.PipePipe,
    // GenerosPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LoginModule,
    APP_ROUTES,
    ComponentsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    PagesModule,
    ChartsModule,
    BrowserAnimationsModule,
    NgxDropzoneModule,
    NgxSpinnerModule,
  ],
  exports: [
   //NgStepperComponent
   NgxSpinnerModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
