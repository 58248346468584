import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SEDE } from 'src/app/config/conf';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  public pacientes = {
    nombrePaciente:"",
    apellidoPaterno:"",
    apellidoMaterno:"",
    edad:0,
    genero:"",
    correoPaciente:"",
    cpPaciente:"",
    curp:"",
    paquetes:[],
  }

  public sede = SEDE;
  public id = "";
  public paquetes: any [] = [];

  constructor(
    private _pacienteService: PacientesService,
    private activatedRoute: ActivatedRoute,
    public _router: Router,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');
    this.obtenerPaciente();
    this.paquete(this.id);
  }

  obtenerPaciente(){
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    // console.log( this.id );
    this._pacienteService.getPacienteSede( this.sede ,  this.id )
      .subscribe( (data) => {
        this.setPaciente( data['data'] );
      });

  }


  setPaciente( paciente  ) {
    localStorage.setItem('paciente', JSON.stringify(paciente))
    this.pacientes = paciente;
  }

  irAUnServicio(  servicio ){
    this.setRouteService( servicio );
  }

  setRouteService(servicio){
    this._router.navigate([ `/serviciosInt/${servicio}`]);
  }

  paquete(id){
    this._pacienteService.getPaquetePaciente(id).subscribe(
      (data: any) => {
          this.paquetes = data['data'];
      },
      err => {
        console.log(<any>err);
    });  
  }
  ver_info_paquetes(ev,nombre){
    /* swal(nombre,{icon:"success"}); */
    alert(nombre)
  }

}
