<!-- Content Wrapper -->

<div id="content-wrapper" class="d-flex flex-column">

    <!-- Main Content -->
    <div id="content">

        <!-- Topbar -->
        <nav class="navbar navbar-expand navbar-light bg-white topbar static-top shadow mr-auto">
            <!--div class="col-2"></div-->
            <div class="col-8">
                <a class="nav-link" [routerLink]="['/']">
                    <img src="../../../../assets/assets/images/bs-1-hero.png" width="25%" height="25%">
                </a>
            </div>
            <div class="col-4" style="text-align: end;">
                <!--a (click)="cerrarSesion()"-->
                <!-- <i class="fas fa-sign-out-alt fa-sm fa-fw"></i> -->
                <button type="button"  class="boton" (click)="cerrarSesion()">CERRAR SESIÓN </button>
                <!--/a-->
            </div>
            <!--div class="col-2"></div>
           Topbar Navbar -->
        </nav>
        <!-- End of Topbar -->