import { Component, OnInit } from '@angular/core';
import { USGService } from 'src/app/services/usg/usg.service';

@Component({
  selector: 'app-bitacora-ultrasonido',
  templateUrl: './bitacora-ultrasonido.component.html',
  styleUrls: ['./bitacora-ultrasonido.component.css']
})
export class BitacoraUltrasonidoComponent implements OnInit {

  public consultas:any=[]
  public pagina = 0;
  public totalAmbulancia: string;
  constructor(  public _usgservicio : USGService) { }

  ngOnInit(): void {
    this.obtenerCosnultaUltra()
  }



  
  
  obtenerCosnultaUltra(){
    this._usgservicio.getUltrsonidoUSG()
    .subscribe( (data) =>   {
        this.consultas = data['data'].reverse();
    });
  }
}
