<div class="head-container row ">

    <div class="col-md-4">
        <p>Nombre: <span>   {{paciente.nombre}} {{paciente.apellidoPaterno}} {{paciente.apellidoMaterno}}</span> </p>
        <p> CURP: {{ paciente.curp }} </p>
        <p>Dirección: {{paciente.callePaciente}} {{ paciente.municipio }} </p>
    </div>


    <div class="col-md-4">
        <p>Edad: <span>  {{paciente.edad}}  </span> </p>
        <p>Fecha: {{fecha}} {{hora}} </p>
        <p>Registro: {{paciente.registro}} </p>
    </div>

    <div class="col-md-4">
        <p>Sexo: <span> {{paciente.sexo | titlecase}} </span> </p>
        <p> ID: {{paciente.idPaciente }} </p>
    </div>

</div>