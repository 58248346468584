<h1 class="centerxd">LISTADO DE REPORTES</h1>

<br>

<section class="row">
    <div style="margin-left: 5%;" class="col-md-6">
        <input type="text" placeholder="Buscar" class="form-control" id="buscador" style="border-radius: 20px;">
    </div>

    <div class="col-md ">
        <button class="principalButton " style="color: aliceblue; ">NUEVO</button>
    </div>

</section>



<div style="margin-top: 2%;" class="col-xs-12" id="tabla">
    <div class="card" style="border-radius: 30px;">
        <div class="card-heder header">
            <div class="row">
                <div class="col text_header">
                    <p>No</p>
                </div>
                <div class="col text_header">
                    <p>FECHA</p>
                </div>
                <div class="col text_header">
                    <p>TIPO DE REPORTE</p>
                </div>
                <div class="col text_header">
                    <p>TOTAL</p>
                </div>
                <div class="col text_header">
                    <p>SUCURSAL</p>
                </div>
                <div class="col text_header">
                    <p></p>
                </div>
            </div>
        </div>


        <div class="card-body">
            <div class="row">
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">01</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">01/02/2021</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">Reporte General</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">$20,000.00</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">Cuernavaca</p>
                </div>
                <div class="col">
                    <p style="color: rgb(86, 235, 72); font-size: 20px;"><b>Imprimir</b></p>
                </div>

            </div>

            <div class="row">
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">02</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">01/02/2021</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">Reporte General</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">$20,000.00</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">Cuernavaca</p>
                </div>
                <div class="col">
                    <p style="color: rgb(86, 235, 72); font-size: 20px;"><b>Imprimir</b></p>
                </div>

            </div>

            <div class="row">
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">03</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">01/02/2021</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">Reporte General</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">$20,000.00</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">Cuernavaca</p>
                </div>
                <div class="col">
                    <p style="color: rgb(86, 235, 72); font-size: 20px;"><b>Imprimir</b></p>
                </div>

            </div>

            <div class="row">
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">04</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">01/02/2021</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">Reporte General</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">$20,000.00</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">Cuernavaca</p>
                </div>
                <div class="col">
                    <p style="color: rgb(86, 235, 72); font-size: 20px;"><b>Imprimir</b></p>
                </div>

            </div>

        </div>


        <div class="offset-md-3">
            <pagination-controls style="color:#001672;" id="lista" maxSize="10"></pagination-controls>
        </div>


    </div>
</div>

<br>