import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PacientesService } from 'src/app/services/pacientes/pacientes.service';

@Component({
  selector: 'app-ver-paciente',
  templateUrl: './ver-paciente.component.html',
  styleUrls: ['./ver-paciente.component.css']
})
export class VerPacienteComponent implements OnInit {
  // public fechaHoy =  moment().format('dddd Do MMM YYYY: hh:mm');
  public id = "";
  public paciente = {
    nombrePaciente:"",
    apellidoPaterno:"",
    apellidoMaterno: "",
    fechaNacimientoPaciente:"",
    curp:"",
    telefono:0,
    consultas: 0,
    _id:"",
    fechaRegistro:Date,
    genero:"",
    estadoPaciente:"",
    callePaciente:"",
    paisPaciente:"",
    cpPaciente:"",
    contactoEmergencia1Nombre:"",
    contactoEmergencia1Edad:"",
    contactoEmergencia1Telefono:"",
    correo: "",
    edad:"",
    paquetes : [],
    membresiaActiva:false,
    numeroExpediente:'',
    sede:""
  }
  public fecha;

  constructor(private _pacienteService: PacientesService,
              private _route: ActivatedRoute,
              public _router: Router) { }
  
  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');
    this.obtenerPaciente();
  }

  obtenerPaciente(){
    this._pacienteService.getPacienteBtID(  this.id )
    .subscribe((resp:any) => {
      this.setPaciente(resp.paciente)
    });
  }

  setPaciente(data){
    for (const key in this.paciente) {
      if (data[key] == undefined) {
        this.paciente[key] = ''
      }else{
        this.paciente[key] = data[key]
      }
    }
    let fechaR;
    fechaR = this.paciente.fechaRegistro
    this.fecha=fechaR.split('T')[0]
    this.guardarLocalStorage();
  }


  guardarLocalStorage(){
    if(  localStorage.getItem('paciente') ){
      localStorage.removeItem('paciente');
    }
    if(  this.paciente.paquetes.length >= 1 || this.paciente.membresiaActiva == true ){
        this.paciente.membresiaActiva = true;
    }
    localStorage.setItem('paciente', JSON.stringify(this.paciente));
  }

  irAUnServicio(  servicio ){
    this.setRouteService( servicio );
  }

  setRouteService(servicio){
    if (servicio == 'receptor') {
      this._router.navigate([ `documentos/paciente/${this.paciente._id}`]);
    } else {
      this._router.navigate([ `/serviciosInt/${servicio}`]);
    }
    //documentos/paciente
  }

}
