import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BancodesangreService } from 'src/app/services/bancodeSangre/bancodesangre.service';

@Component({
  selector: 'app-reacciones-adversas',
  templateUrl: './reacciones-adversas.component.html',
  styleUrls: ['./reacciones-adversas.component.css']
})
export class ReaccionesAdversasComponent implements OnInit {

  public id = '';
  public huboReaccion = "";
  public pacienteInfo={
    nombrePaciente: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    curp: "",
    edad: 0,
    genero: "",
    callePaciente: "",
    fechaNacimientoPaciente:"",
    estadoPaciente: "",
    paisPaciente: "",
    telefono: "",
    receptor: "",
    _id:""
  };
  public imagenes = [];

  constructor(private _banco: BancodesangreService,
              private spinner: NgxSpinnerService,
              private activatedRoute: ActivatedRoute,
              private router:Router
              ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.spinner.show();
    this.obtenerPedido();
  }

  obtenerValor(event){
    if(event.target.value == 'on' ){
      this.huboReaccion = 'si';

    }
  }

  obtenerPedido(){
    this._banco.getOrdenPedidoReceptor(this.id)
    .subscribe((resp:any)=>{
      console.log( resp)
      if(resp.ok){
        this.spinner.hide();
        this.setPaciente(resp['data'].paciente);

      }
    })
  }

  setPaciente(paciente){
    this.pacienteInfo.nombrePaciente = paciente.nombrePaciente;
    this.pacienteInfo.apellidoPaterno = paciente.apellidoPaterno;
    this.pacienteInfo.apellidoMaterno = paciente.apellidoMaterno;
    this.pacienteInfo.curp = paciente.curp;
    this.pacienteInfo.edad = paciente.edad;
    this.pacienteInfo.genero = paciente.genero;
    this.pacienteInfo._id = paciente._id;
    this.pacienteInfo.callePaciente = paciente.callePaciente;
    this.pacienteInfo.paisPaciente = paciente.paisPaciente;
    this.pacienteInfo.estadoPaciente = paciente.estadoPaciente;
    this.pacienteInfo.fechaNacimientoPaciente = paciente.fechaNacimientoPaciente;
    this.pacienteInfo.telefono = paciente.telefono;
    this.pacienteInfo.receptor = paciente.receptor;
    
  }

  // DROPZONE
  files: File[] = [];

  onSelect(event) {
    this.files.push(...event.addedFiles);
    this.imagenes = []
    if (this.files && this.files[0]) {
      for (let i = 0; i < this.files.length; i++) {
       this.fileToBase64(this.files[i])
        .then(result=>{
          const base64String = result
          this.imagenes.push( {name:this.files[i].name, base: base64String} );
        });         
      }
    }
  }

  fileToBase64 = (file:File):Promise<string> => {
    
    return new Promise<string> ((resolve,reject)=> {
          
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => resolve(reader.result.toString());
         reader.onerror = error => reject(error);
     })
  }
  
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.imagenes.splice(this.imagenes.indexOf(event),1)
  }
  guardarDocumentos(){
    
    this._banco.cambioEstadoRecpetor( this.id, { estado: 'FINALIZADO' })
    .subscribe( (data:any) =>  {
      console.log(data);
    });

    this.spinner.show();
     this._banco.agregarDocumetosReaccionesTransfucionales( this.id, {reaccionestransfucionales: this.imagenes[0], huboReaccionAdversa: this.huboReaccion }  )
     .subscribe( (data:any) => {
      if(data.ok){
        this.spinner.hide();
        this.router.navigate(['/dashboard'])
      }
     });

  }
  
}
