<div class="container-fluid">
    <section class="center">
        <div class="row">
            <div class="col-md-8">
                <h1 class="centerxd"> {{ paciente.nombrePaciente | uppercase }} {{ paciente.apellidoPaterno | uppercase}} {{ paciente.apellidoMaterno | uppercase }} </h1>
            </div>
            <div class="col-md-4">
                <p>
                    <span>{{fecha}}</span>
                </p>
            </div>
        </div>
    </section>
    <section class="container">
        <div class="row">
            <div class="col-6">
                <select name="servicio" class="form-control" id="" #servicio (change)="irAUnServicio( servicio.value  )">
                <option   value="" selected> Selecciona un servicio  </option>
                <option   value="receptor"> Receptor</option> 
                <option   value="receptor"> Reacciones transfusionales</option> 
                <option   value="otros"> Servicios generales </option> 
              </select>
            </div>
        </div>
    </section>
    
    <ficha-info-user [pacienteInfo]="paciente" [title]="false"></ficha-info-user>
</div>