import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { URL } from 'src/app/config/conf';
@Injectable({
  providedIn: 'root'
})
export class SedesService {

  private url = URL;

  constructor(
    private _http :  HttpClient
  ) { }


  verPedidosPagados( sede ){
    const uri = ` ${this.url}/ver/pedidos/pagados/${sede}`;
    return this._http.get( uri );
  }


}
