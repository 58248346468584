import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { URL } from 'src/app/config/conf';

@Injectable({
  providedIn: 'root'
})
export class PacientesService {

  public url = URL;


  constructor(private _http: HttpClient) {
  }

  getPaquetePaciente(paciente){
    let uri = this.url+'/ver/paquetes/paciente/'+ paciente;
    //console.log(paciente);
    return this._http.get(uri);
  }

  getPacientes( intervalo: number  ) {

    let uri = this.url+'/paciente/'+ intervalo;
    return this._http.get( uri )
    .pipe(  map( (data) => {
      // console.log( data )
      return data;
    } ) )
  }


  // este servicio crea un nuevo paciente en la DB
  setPacientes( data: any, sede: string  ){
    let uri = `${this.url}/paciente/${sede}`;
    return this._http.post( uri, data  );
  }


  // este servicio obtiene pacientes por nombre

  getPacientePorNombre( nombre: String ){


    // let uri = URLDEV + '/paciente/'+nombre;
    let uri = `${this.url}/pacientes/nombre/${nombre}`;

    return this._http.get(uri)
    .pipe(  map( (data) => {
        return data;
    }))

  }

  setCodigoPostal( code: any  ){
    const uri = `${this.url}/codigo/postal`;
    return this._http.post( uri, code  );
  }



  // este servicio agrega un paquete al usuario
  addPaquete( paciente:any){
    // recibe por parametro el ID del usuario, el paquete y los nuevos valores a actualizar
  let id = paciente._id;

  // let uri = URLDEV+'/agregarPaquete/'+id;
  let uri =  `${this.url}/actualizar/paciente/${id}`;


// se obteiene la data para actulizar en el paquete


   return this._http.put( uri, paciente );

  }
  // fin de la funcion



  getPacienteBtID(id: string) {

    let uri = `${this.url}/pacientes/${id}`;

   return this._http.get( uri )
    .pipe( map( (paciente) =>  paciente  ) );
  }

  // actualizamos la informacion  del paciente

  updatePaceinte(body, id){
    let url = `${this.url}/actualizar/paciente/${id}`;

   return this._http.put( url , body);
  }

  getPacientesAll(){
    let url = `${this.url}/pacientes`;
    return this._http.get( url);
  }

  getPacientesSede( sede ) {
    const url = `${this.url}/listado/receptores/${sede}`;
    return this._http.get( url );
  }

  getPacienteSede( sede, id ){
    const url = `${this.url}/paciente/${sede}/${id}`;
    return this._http.get( url );
  }

}
