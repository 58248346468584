import { Injectable } from '@angular/core';
import { URL } from 'src/app/config/conf';
import {  HttpClient } from '@angular/common/http'

@Injectable({
    providedIn: 'root'
})
export class USGService {

    
    public  url = URL;

    constructor(  private _http:HttpClient    ) {

    }


    enviarImagenes( body  ){
        const url = `${this.url}/agregar/regresos/usg`;
        return this._http.post( url, body )
    }


    
   
    // getUltrasonidos(){
    //     return this.ultrasonido;
    // }

    getUltrsonidoUSG(){
        const url = `${this.url}/ver/pedidos/ultrasonido`;
        return this._http.get( url);
    }

    verUltrsonidoUSG (id){
        const url = `${this.url}/obtener/ultrasonido/pedido/${id}`;
        return this._http.get( url);
    }

    postPedidosUltra(pedido){
        const url = `${this.url}/nuevo/pedido/ultrasonido`;
        return this._http.post( url, pedido );

    }

    enviarUsgRegreso(img){
        let url = `${this.url}/agregar/regresos/usg`;
        return this._http.post(url,img)
    }
    
      obtenerUsgRecepcionHC(id){
        let url = `${this.url}/ver/estudio/regreso/ultrasonido/${id}`;
    return this._http.get(url);
      }

      obtenerUsgHC(idPaciente){
        //   console.log(id)
        const url = `${this.url}/ver/estudio/regreso/ultrasonido/hc/${idPaciente}`;
        return this._http.get(url);
      }


}