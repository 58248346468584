<div class="container-fluid" style="    height: 40rem; padding-left: 3.5rem;
padding-right: 3.5rem;">
    <h1 class="centerxd">LISTADO DE PACIENTES</h1>
    <div class="row">
        <div class="col-md-9">
            <div class="form-group">
                <input type="text" name="filterPost" placeholder="Buscar" [(ngModel)]="filtropacientes" class="form-control" id="busquedaPaciente" (keyup)="typeSearchPatients()" style="margin-left: 25px; max-width:50em">
            </div>
        </div>
        <div class="col-md-2">
            <a type="button" class="btn btn-secondary boton_contra2" [routerLink]="['/registro', 'pacientes']">NUEVO</a>
        </div>
    </div>

    <br>
    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card" style="border-radius: 30px; margin-left: 25px;">
                <div class="card-heder header">
                    <div class="row">
                        <div class="col-md-4 text_header">
                            <p>Nombre</p>
                        </div>

                        <div class="col-md-2 text_header">
                            <p>Género</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>Edad</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>Teléfono</p>
                        </div>
                        <div class="col-md-2 text_header">
                            <p>Servicios</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div *ngFor="let paciente of pacientes2 | paginate:{id:'listapacientes',
                  itemsPerPage: 7,
                  currentPage:pagina,
                  totalItems:totalpaciente}">
                        <div class="row" [routerLink]="['/paciente', paciente._id]">
                            <div class="col-md-4" style="text-align: center;">
                                <p>
                                    {{ paciente.nombrePaciente | uppercase }} {{ paciente.apellidoPaterno | uppercase }} {{ paciente.apellidoMaterno | uppercase }} <br>
                                </p>
                            </div>
                            <div class="col-md-2" style="text-align: center;">
                                <p>
                                    {{paciente.genero | uppercase}}
                                    <br></p>
                            </div>
                            <div class="col-md-2" style="text-align: center;">
                                <p>
                                    {{ paciente.edad }}<br>
                                </p>
                            </div>
                            <div class="col-md-2" style="text-align: center;">
                                <p>
                                    {{ paciente.telefono }} <br>
                                </p>
                            </div>
                            <div class="col-md-2" style="text-align: center;">
                                <p>
                                    <a [routerLink]="['/paciente', paciente._id]">Ver...</a> <br>
                                </p>
                            </div>
                        </div>

                    </div>

                    <div *ngIf="pacientes2 == []">
                        <p>
                            Aún no hay pacientes registrados
                        </p>
                    </div>


                </div>
                <div class="offset-md-4">
                    <pagination-controls id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                </div>
                <!-- <div class="row">
                    <div class="col-2"></div>
                    <div class="col-8">
                        <pagination-controls id="listapacientes" maxSize="5" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
                    </div>
                    <div class="col-2"></div>
                </div> -->
            </div>
        </div>


    </div>