<div id="content">

    <div class="container">
        <!-- Content Row -->

        <!-- <app-card></app-card> -->
        <img id="logoDashboard" src="../../../../assets/assets/images/bs-1-hero.png" alt="">
        <router-outlet></router-outlet>
    </div>

</div>