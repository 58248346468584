export class Personal {

  // esta es la interfaz del personal
    constructor(
      public nombre?: string,
      public password?: string,
      public rfc?: string,
      public curp?: string,
      public role?: string,
      public turno?: string,
      public img?: string,
      public _id?: string
     ) {}

}
