<!-- <div class="center">
    <h1>Resultados Laboratorio</h1>
</div>
<div class="container">
    <section>
        <div class="card">
            <div class="card-header header">
                <div class="row">
                    <div class="col-1 text_header">
                        <p>#</p>
                    </div>
                    <div class="col text_header">
                        <p>Nombre</p>
                    </div>
                    <div class="col text_header">
                        <p>Curp</p>
                    </div>
                    <div class="col text_header">
                        <p>Edad</p>
                    </div>
                    <div class="col text_header">
                        <p>Servicio médico</p>
                    </div>
                    <div class="col text_header">
                        <p>Médico</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div> -->

<h1 class="centerxd">BITACORA DE RESULTADOS DE LABORATORIO</h1>

<div class="col-md-8">
    <input type="text" placeholder="Buscar" class="form-control" id="buscador" style="border-radius: 20px;">
</div>

<div class="col-md-12 col-lg-12">
    <div class="card" style="border-radius: 30px; margin: 25px 0px 25px; font-weight: 800;">
        <div class="card-heder header">
            <div class="row">
                <div class="col-1 text_header">
                    <p>No</p>
                </div>
                <div class="col-1 text_header">
                    <p>Fecha</p>
                </div>
                <div class="col text_header">
                    <p>Nombre</p>
                </div>
                <div class="col-1 text_header">
                    <p>Edad</p>
                </div>
                <div class="col-1 text_header">
                    <p>Sexo</p>
                </div>
                <div class="col-1 text_header">
                    <p>Sede</p>
                </div>
                <div class="col text_header">
                    <p>Estudio</p>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div *ngFor="let item of consultas | paginate:{id:'listaAmbulancia',
            itemsPerPage: 5 ,
            currentPage:pagina,
            totalItems:totalAmbulancia}; let i = index">
                <div class="row" [routerLink]="['/resultado-servicios', item._id]">
                    <div class="col-1" style="text-align: center;">
                        <p>{{ i +1 }}</p>
                    </div>
                    <div class="col-1" style="text-align: center;">
                        <p>{{item.fecha}}</p>
                    </div>
                    <div class="col" style="text-align: center;">
                        <p>{{item.idPaciente.nombrePaciente}} {{item.idPaciente.apellidoPaterno}} {{item.idPaciente.apellidoMaterno}}</p>
                    </div>
                    <div class="col-1" style="text-align: center;">
                        <p>{{item.idPaciente.edad}}</p>
                    </div>
                    <div class="col-1" style="text-align: center;">
                        <p>{{item.idPaciente.genero | generos:true }}</p>
                    </div>
                    <div class="col-1" style="text-align: center;">
                        <p>{{item.sede}}</p>
                    </div>
                    <div class="col" style="text-align: center;">
                        <p>{{item.estudios[0].nombreEstudio}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="offset-md-4">
            <pagination-controls class="paginador" id="listaAmbulancia" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
        </div>
    </div>
</div>






<!-- <br>

<div class="card" style="border-radius: 30px;">
    <div class="row">
        <div class="col-md-12">
            <table class="table table-hover colors izq" id="bitacora">
                <thead class="center" style="background-color: #03318C;">
                    <tr style="color: white;">

                        <th scope="col"> No.</th>
                        <th scope="col"> Fecha </th>
                        <th scope="col"> Nombre </th>
                        <th scope="col"> Edad </th>
                        <th scope="col"> Sexo </th>
                        <th scope="col"> Sede </th>
                        <th scope="col ">Estudio</th>
                    </tr>
                </thead>
                <tbody class="center" style="background-color: white;color: black;">

                    <tr *ngFor="let item of consultas | paginate:{id:'listaAmbulancia',
                    itemsPerPage: 7 ,
                    currentPage:pagina,
                    totalItems:totalAmbulancia}; let i = index">
                        <td> {{ i +1 }} </td>
                        <th>{{item.fecha}}</th>
                        <th [routerLink]="['/resultado-servicios', item._id]">{{item.idPaciente.nombrePaciente}} {{item.idPaciente.apellidoPaterno}} {{item.idPaciente.apellidoMaterno}}</th>
                        <th>{{item.idPaciente.edad}}</th>
                        <th> {{item.idPaciente.genero | generos:true }}</th>
                        <th>{{item.sede}}</th>
                        <th>{{item.estudios[0].nombreEstudio}}</th>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="offset-md-3">
            <pagination-controls class="paginador" id="listaAmbulancia" maxSize="10" directionLinks="true" (pageChange)="pagina = $event"></pagination-controls>
        </div>  
    </div>
</div>

<br> -->