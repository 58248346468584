import { RouterModule,  Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent  } from './login/login.component';
import { PagesComponent } from './pages/pages/pages.component';



const appRoutes : Routes = [
  {
    path:'',
    component:PagesComponent,
    children:[
      { path: 'dashboard', component: DashboardComponent },
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' }
    ]
  },
  { path:'login', component: LoginComponent },
  { path: '', component:LoginComponent   }
]


export const APP_ROUTES = RouterModule.forRoot( appRoutes, { useHash: true } );
