<div style="margin-top: 15px;" class="row">
    <div class="col-md-9">
        <div class="card" style="border-radius: 30px;">
            <div class="card-heder header">
                <div class="row">

                    <div class="col-md-4 text_header">
                        <p>ESTUDIO</p>
                    </div>
                    <div class="col-md-2 text_header">
                        <p *ngIf="membresia != true">PRECIO</p>
                        <p *ngIf="membresia"> PRECIO MEMBRESÍA </p>
                    </div>
                    <div class="col-md-2 text_header">
                        <p *ngIf="membresia != true">PRECIO URGENCIA</p>
                        <p *ngIf="membresia"> PRECIO MEMBRESÍA URGENCIA </p>
                    </div>
                    <div class="col-md-2 text_header" *ngIf="servicios != 'laboratorio'">
                        <p *ngIf="membresia != true">PRECIO HOSPITALIZACION</p>
                        <p *ngIf="membresia">PRECIO MEMBRESÍA HOSPITALIZACION</p>
                    </div>
                    <div class="col-md-2 text_header" *ngIf="membresia">
                        <p>PRECIO MEMBRESÍA HOSPITALIZACION URGENCIA</p>
                    </div>
                </div>

            </div>
        </div>

        <div class="card-body" style="background-color: #ffffff;">
            <div *ngFor="let SiService of serviceSi">

                <div class="row">
                    <div class="col-md-4">
                        <label style="margin-right: 50px; margin-top: 18px;">
                            {{ SiService.idServicio.ESTUDIO}}
                        </label>
                    </div>

                    <div class="col-md-2">
                        <div (click)="alertcomparasion( $event, SiService.idServicio.PRECIO_PUBLICO, SiService.idServicio.PRECIO_MEMBRESIA, SiService )" class="cursor  btn btn-light precioPublicoOtros" style="margin-top: 3px; margin-left:-14px; width: 9.5em; height: 4em;
                                        background-color: #ffffff;
                                        border: none;">
                            AGREGAR
                            <!-- <p (click)="agregarCarrito( $event, SiService.idServicio )" *ngIf="membresia != true">
                                AGREGAR
                            </p>
                            <p (click)="agregarCarrito( $event, SiService.idServicio )" *ngIf="membresia">
                                AGREGAR
                            </p> -->
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div (click)="alertcomparasion( $event, SiService.idServicio.PRECIO_PUBLICO_URGENCIA, SiService.idServicio.PRECIO_MEMBRESIA_URGENCIA, SiService)" class="cursor btn btn-light precioPublicoUrgencia" style="margin-top: 3px; margin-left:-14px; width: 9.5em;
                                    height: 4em; background-color: #ffffff;
                                    border: none;">
                            AGREGAR
                            <!-- <h6 (click)="agregarCarrito( $event, SiService.idServicio )" *ngIf="membresia != true">
                                AGREGAR
                            </h6>
                            <h6 (click)="agregarCarrito( $event, SiService.idServicio )" *ngIf="membresia">
                                AGREGAR
                            </h6> -->
                        </div>
                    </div>

                    <div class="col-md-2" *ngIf="servicios != 'laboratorio'">
                        <div (click)="alertcomparasion( $event, SiService.idServicio.PRECIO_PUBLICO_HOSPITALIZACION, SiService.idServicio.PRECIO_MEMBRESIA_HOSPITALIZACION, SiService  )" class="cursor btn btn-light precioPublicoHopi" style="margin-top: 3px; margin-left:-14px; width: 9.5em;
                                    height: 4em; background-color: #ffffff;
                                    border: none;">
                            AGREGAR
                            <!-- <h6 (click)="agregarCarrito( $event, SiService.idServicio )" *ngIf="membresia != true">
                                AGREGAR
                            </h6>
                            <h6 (click)="agregarCarrito( $event, SiService.idServicio )" *ngIf="membresia">
                                AGREGAR
                            </h6> -->
                        </div>
                    </div>

                    <div class="col-md-2" *ngIf="membresia">
                        <div (click)="alertcomparasion( $event, SiService.idServicio.PRECIO_PUBLICO_HOSPITALIZACION, SiService.idServicio.PRECIO_MEMBRESIA_HOSPITALIZACION, SiService )" class="cursor btn btn-light precioHospiUrgen" style="margin-top: 3px; margin-left:-14px; width: 9.5em;
                                    height: 4em; background-color: white;
                                    border: none;">
                            AGREGAR
                            <!-- <h6 (click)="agregarCarrito( $event, SiService.idServicio )">
                                AGREGAR
                            </h6> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-3">
        <div class="card" style="margin: 10px; height: 33em; border-radius: 24px;">

            <div class="card-title" style="border-bottom-style: solid;">
                <h4 style="text-align: center;">COTIZACIÓN</h4>
            </div>

            <div class="card-body" style="overflow: auto;">
                <div class="row" *ngFor="let pedido of carrito.items; let i=index">
                    <div class="col-md-8">
                        <p style="font-size: 11px; font-weight: 600; margin-top: 10px; text-align: center;" *ngIf="membresia != true">
                            {{ pedido.nombreEstudio }} : {{ pedido.precioSin}}
                        </p>
                        <p style="font-size: 11px; font-weight: 600; margin-top: 10px; text-align: center;" *ngIf="membresia">
                            {{ pedido.nombreEstudio }} : {{ pedido.precioCon}}
                        </p>
                    </div>

                    <div class="col-md-2">
                        <button type="button" (click)="eliminar(i)" class="btn btn-link" style="color: red;">

                            Eliminar
                        </button>
                    </div>
                </div>
                <div *ngIf="validarCarrito()">
                    <p>No cuentas con servicios en el carrito</p>
                </div>
            </div>

            <div class="card-footer" style="border-top-style: solid;">
                <div class="row">
                    <div class="col-md-10">
                        <h5 style="font-size: 15px; font-weight: 800;" *ngIf="membresia != true">Total: &#36; {{carrito.totalSin}}</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10">
                        <h5 style="font-size: 15px; font-weight: 800;">Total membresía: &#36; {{ carrito.totalCon}}</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10">
                        <h5 style="font-size: 1.2rem; font-weight: 800;" class="ahorro"> Ahorro: &#36; {{carrito.totalSin - carrito.totalCon}}</h5>
                    </div>
                </div>
                <button type="button" class="btn btn-success btn-block btn-tarjeta" *ngIf="btnCotizacion" (click)="cotizacion(carrito)">
                    <i class="fas fa-hand-holding-usd"></i>
                    Imprimir Cotización
                </button>

                <button type="button" *ngIf="btnPago" class="btn btn-success btn-block btn-tarjeta" [routerLink]="['/pago/servicios']">
                    <i class="fas fa-hand-holding-usd"></i>
                        Pagar
                </button>

                <button class="btn btn-dark btn-tarjeta btn-block" [routerLink]="['/paciente']" routerLinkActive="router-link-active">
                    <i class="fas fa-user-plus"></i>
                        Nuevo
                </button>


                <button class="btn btn-block btn-info  btn-tarjeta">
                    <i class="fas fa-share-alt"></i>
                        Enviar
                </button>
            </div>
        </div>
    </div>
</div>