import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { URL } from 'src/app/config/conf';
import { Module } from 'src/app/interfaces/module.interfaces';

@Injectable({
  providedIn: 'root'
})
export class ModulesService {

  public url: string;

  constructor( private _http: HttpClient ) {

    this.url = URL;

  }


  getModules( id: string ) {
    let url = this.url + '/modulos/'+id;


  return  this._http.post( url, id )
    .pipe( map( (modules: Module[]) => {
       return modules;
    }) )
  }

}
