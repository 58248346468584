<h1 class="centerxd">BITACORA DE CONSULTA EXTERNA</h1>


<div style="margin-top: 2%;" class="col-xs-12" id="tabla">
    <div class="card" style="border-radius: 30px;">
        <div class="card-heder header">
            <div class="row">
                <div class="col text_header">
                    <p>No</p>
                </div>
                <div class="col text_header">
                    <p>FECHA</p>
                </div>
                <div class="col text_header">
                    <p>HORA</p>
                </div>
                <div class="col text_header">
                    <p>NOMBRE DEL PACIENTE</p>
                </div>
                <div class="col text_header">
                    <p>EDAD</p>
                </div>
                <div class="col text_header">
                    <p>SEXO</p>
                </div>
                <div class="col text_header">
                    <p>DIAGNÓSTICO</p>
                </div>
                <div class="col text_header">
                    <p>CURP</p>
                </div>
                <div class="col text_header">
                    <p>ENFERMERÍA</p>
                </div>
                <div class="col text_header">
                    <p>MÉDICO</p>
                </div>
                <div class="col text_header">
                    <p>TURNO</p>
                </div>
                <div class="col text_header">
                    <p>OBSERVACIONES</p>
                </div>
            </div>
        </div>


        <div class="card-body">
            <div class="row">
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">01</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">01/02/2021</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">19:00</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">Julián Peréz</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">30 AÑOS</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">Masculino</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">Epatitis</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">PAJ881103SPTO</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">-----</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">David Reyes</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">Nocturno</p>
                </div>
                <div class="col">
                    <p style="color:  #001672; font-size: 20px;">Muestra piel amarilla</p>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-3 offset-md-5 mb-3">

                <button class="principalButton"> Imprimir </button>
            </div>
        </div>



    </div>
</div>



<!-- <tbody>
                       <tr style="background-color: white; color: black;" *ngFor="let item of consultas; let i = index">
                        <td>
                            {{ i +1}}
                        </td>
                        <td>
                            {{ item.fechaIngreso }}
                        </td>
                        <td>
                            {{ item.horaIngreso }}
                        </td>
                        <td *ngIf="item.paciente.consultas === 1 || item.paciente.consultas == 0" [routerLink]="['/historia/clinica', item._id]" (click)="cambiarEstado(item._id)">
                            {{ item.paciente.nombrePaciente }} {{ item.paciente.apellidoPaterno }} {{ item.paciente.apellidoMaterno }}
                        </td>
                        <td *ngIf="item.paciente.consultas > 1" [routerLink]="['/hoja/evolucion', item._id]" (click)="cambiarEstado(item._id)">
                            {{ item.paciente.nombrePaciente }} {{ item.paciente.apellidoPaterno }} {{ item.paciente.apellidoMaterno }}
                        </td>
                        <td>
                            {{ item.paciente.edad }}
                        </td>
                        <td>
                            {{ item.paciente.genero | titlecase }}
                        </td>
                        <td>
                            {{ item.diagnosticoActual }}
                        </td>
                        <td>
                            {{ item.paciente.curp }}
                        </td>
                        <td>
                            {{ item.enfermeraAtendio }}
                        </td>

                        <td> Médico </td>
                        <td> Turno </td>

                        <td>

                            {{ item.observaciones }}
                        </td>
                    </tr>
                </tbody> -->