import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LocationServiceService {

  constructor(public http: HttpClient) { }

  getCountries() {
    const URLCountries = "https://restcountries.eu/rest/v2/all";

    return this.http.get(URLCountries)
      .pipe(map((countries) => {
        return countries;
      }))
  }

  getLocalidades(codigo: number) {

    const URLCODIGOPOSTAL = "https://api-sepomex.hckdrk.mx/query/info_cp/";
    // obtenemos las localidades por el Codigo postal
    let tipo = '?type=simplified';
    //TOKEN
    const token = "&token=530d89c8-109c-493c-b697-a0fb6a24c387";

    let url = `${URLCODIGOPOSTAL}${codigo}${tipo}${token}`;

    return this.http.get(url)
      .pipe(map(res => res));

  }

  getEstado() {

    const url = "https://api-sepomex.hckdrk.mx/query/get_estados";
    //TOKEN
    const token = "?token=530d89c8-109c-493c-b697-a0fb6a24c387";

    return this.http.get(url +  token);
  }


  getMunicipios(municipio) {
    const url = "https://api-sepomex.hckdrk.mx/query/get_municipio_por_estado/" + municipio;
    //TOKEN
    const token = "?token=530d89c8-109c-493c-b697-a0fb6a24c387";

    return this.http.get(url + token);

  }
}
